/* tslint:disable */
/* eslint-disable */
/**
 * ECG Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0-unknown
 * Contact:
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForbiddenError } from '../models';
// @ts-ignore
import { NotFoundError } from '../models';
// @ts-ignore
import { PaginatedRecordingCommentList } from '../models';
// @ts-ignore
import { PaginatedRecordingList } from '../models';
// @ts-ignore
import { PatchedRecordingCommentRequest } from '../models';
// @ts-ignore
import { PatchedRecordingRequest } from '../models';
// @ts-ignore
import { Recording } from '../models';
// @ts-ignore
import { RecordingComment } from '../models';
// @ts-ignore
import { RecordingCommentRequest } from '../models';
// @ts-ignore
import { RecordingRequest } from '../models';
// @ts-ignore
import { Signal } from '../models';
// @ts-ignore
import { SignalChunk } from '../models';
// @ts-ignore
import { UnauthenticatedError } from '../models';
// @ts-ignore
import { ValidationError } from '../models';
/**
 * RecordingApi - axios parameter creator
 * @export
 */
export const RecordingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} chunk
     * @param {number} id A unique integer value identifying this recording.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chunkRetrieve: async (chunk: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'chunk' is not null or undefined
      assertParamExists('chunkRetrieve', 'chunk', chunk);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('chunkRetrieve', 'id', id);
      const localVarPath = `/recording/{id}/chunk/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (chunk !== undefined) {
        localVarQueryParameter['chunk'] = chunk;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} targetId
     * @param {RecordingCommentRequest} [recordingCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentCreate: async (
      targetId: number,
      recordingCommentRequest?: RecordingCommentRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'targetId' is not null or undefined
      assertParamExists('commentCreate', 'targetId', targetId);
      const localVarPath = `/recording/{target_id}/comment/`.replace(
        `{${'target_id'}}`,
        encodeURIComponent(String(targetId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        recordingCommentRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentDestroy: async (id: number, targetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('commentDestroy', 'id', id);
      // verify required parameter 'targetId' is not null or undefined
      assertParamExists('commentDestroy', 'targetId', targetId);
      const localVarPath = `/recording/{target_id}/comment/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'target_id'}}`, encodeURIComponent(String(targetId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} targetId
     * @param {string} [cursor] The pagination cursor value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentList: async (targetId: number, cursor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'targetId' is not null or undefined
      assertParamExists('commentList', 'targetId', targetId);
      const localVarPath = `/recording/{target_id}/comment/`.replace(
        `{${'target_id'}}`,
        encodeURIComponent(String(targetId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {PatchedRecordingCommentRequest} [patchedRecordingCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentPartialUpdate: async (
      id: number,
      targetId: number,
      patchedRecordingCommentRequest?: PatchedRecordingCommentRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('commentPartialUpdate', 'id', id);
      // verify required parameter 'targetId' is not null or undefined
      assertParamExists('commentPartialUpdate', 'targetId', targetId);
      const localVarPath = `/recording/{target_id}/comment/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'target_id'}}`, encodeURIComponent(String(targetId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedRecordingCommentRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentRetrieve: async (id: number, targetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('commentRetrieve', 'id', id);
      // verify required parameter 'targetId' is not null or undefined
      assertParamExists('commentRetrieve', 'targetId', targetId);
      const localVarPath = `/recording/{target_id}/comment/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'target_id'}}`, encodeURIComponent(String(targetId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {RecordingCommentRequest} [recordingCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentUpdate: async (
      id: number,
      targetId: number,
      recordingCommentRequest?: RecordingCommentRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('commentUpdate', 'id', id);
      // verify required parameter 'targetId' is not null or undefined
      assertParamExists('commentUpdate', 'targetId', targetId);
      const localVarPath = `/recording/{target_id}/comment/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'target_id'}}`, encodeURIComponent(String(targetId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        recordingCommentRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {RecordingRequest} recordingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (recordingRequest: RecordingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'recordingRequest' is not null or undefined
      assertParamExists('create', 'recordingRequest', recordingRequest);
      const localVarPath = `/recording/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(recordingRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    destroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('destroy', 'id', id);
      const localVarPath = `/recording/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} [cursor] The pagination cursor value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list: async (cursor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/recording/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {PatchedRecordingRequest} [patchedRecordingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markAsSeenPartialUpdate: async (
      id: number,
      patchedRecordingRequest?: PatchedRecordingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('markAsSeenPartialUpdate', 'id', id);
      const localVarPath = `/recording/{id}/mark_as_seen/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedRecordingRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {PatchedRecordingRequest} [patchedRecordingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdate: async (
      id: number,
      patchedRecordingRequest?: PatchedRecordingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdate', 'id', id);
      const localVarPath = `/recording/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedRecordingRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('retrieve', 'id', id);
      const localVarPath = `/recording/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signalRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('signalRetrieve', 'id', id);
      const localVarPath = `/recording/{id}/signal/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {RecordingRequest} recordingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      id: number,
      recordingRequest: RecordingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('update', 'id', id);
      // verify required parameter 'recordingRequest' is not null or undefined
      assertParamExists('update', 'recordingRequest', recordingRequest);
      const localVarPath = `/recording/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(recordingRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {any} inputfile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadCreate: async (inputfile: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'inputfile' is not null or undefined
      assertParamExists('uploadCreate', 'inputfile', inputfile);
      const localVarPath = `/recording/upload/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (inputfile !== undefined) {
        localVarFormParams.append('inputfile', inputfile as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RecordingApi - functional programming interface
 * @export
 */
export const RecordingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RecordingApiAxiosParamCreator(configuration);
  return {
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} chunk
     * @param {number} id A unique integer value identifying this recording.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async chunkRetrieve(
      chunk: number,
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignalChunk>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.chunkRetrieve(chunk, id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} targetId
     * @param {RecordingCommentRequest} [recordingCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentCreate(
      targetId: number,
      recordingCommentRequest?: RecordingCommentRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecordingComment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentCreate(
        targetId,
        recordingCommentRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentDestroy(
      id: number,
      targetId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentDestroy(id, targetId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} targetId
     * @param {string} [cursor] The pagination cursor value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentList(
      targetId: number,
      cursor?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedRecordingCommentList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentList(targetId, cursor, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {PatchedRecordingCommentRequest} [patchedRecordingCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentPartialUpdate(
      id: number,
      targetId: number,
      patchedRecordingCommentRequest?: PatchedRecordingCommentRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecordingComment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentPartialUpdate(
        id,
        targetId,
        patchedRecordingCommentRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentRetrieve(
      id: number,
      targetId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecordingComment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentRetrieve(id, targetId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {RecordingCommentRequest} [recordingCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentUpdate(
      id: number,
      targetId: number,
      recordingCommentRequest?: RecordingCommentRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecordingComment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentUpdate(
        id,
        targetId,
        recordingCommentRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {RecordingRequest} recordingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      recordingRequest: RecordingRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recording>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(recordingRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async destroy(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.destroy(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} [cursor] The pagination cursor value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async list(
      cursor?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedRecordingList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.list(cursor, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {PatchedRecordingRequest} [patchedRecordingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markAsSeenPartialUpdate(
      id: number,
      patchedRecordingRequest?: PatchedRecordingRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recording>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markAsSeenPartialUpdate(
        id,
        patchedRecordingRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {PatchedRecordingRequest} [patchedRecordingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdate(
      id: number,
      patchedRecordingRequest?: PatchedRecordingRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recording>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdate(id, patchedRecordingRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recording>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.retrieve(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async signalRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Signal>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.signalRetrieve(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {RecordingRequest} recordingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      id: number,
      recordingRequest: RecordingRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recording>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, recordingRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {any} inputfile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadCreate(
      inputfile: any,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recording>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadCreate(inputfile, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * RecordingApi - factory interface
 * @export
 */
export const RecordingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = RecordingApiFp(configuration);
  return {
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} chunk
     * @param {number} id A unique integer value identifying this recording.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chunkRetrieve(chunk: number, id: number, options?: any): AxiosPromise<SignalChunk> {
      return localVarFp.chunkRetrieve(chunk, id, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} targetId
     * @param {RecordingCommentRequest} [recordingCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentCreate(
      targetId: number,
      recordingCommentRequest?: RecordingCommentRequest,
      options?: any,
    ): AxiosPromise<RecordingComment> {
      return localVarFp
        .commentCreate(targetId, recordingCommentRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentDestroy(id: number, targetId: number, options?: any): AxiosPromise<void> {
      return localVarFp.commentDestroy(id, targetId, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} targetId
     * @param {string} [cursor] The pagination cursor value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentList(targetId: number, cursor?: string, options?: any): AxiosPromise<PaginatedRecordingCommentList> {
      return localVarFp.commentList(targetId, cursor, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {PatchedRecordingCommentRequest} [patchedRecordingCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentPartialUpdate(
      id: number,
      targetId: number,
      patchedRecordingCommentRequest?: PatchedRecordingCommentRequest,
      options?: any,
    ): AxiosPromise<RecordingComment> {
      return localVarFp
        .commentPartialUpdate(id, targetId, patchedRecordingCommentRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentRetrieve(id: number, targetId: number, options?: any): AxiosPromise<RecordingComment> {
      return localVarFp.commentRetrieve(id, targetId, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {RecordingCommentRequest} [recordingCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentUpdate(
      id: number,
      targetId: number,
      recordingCommentRequest?: RecordingCommentRequest,
      options?: any,
    ): AxiosPromise<RecordingComment> {
      return localVarFp
        .commentUpdate(id, targetId, recordingCommentRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {RecordingRequest} recordingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(recordingRequest: RecordingRequest, options?: any): AxiosPromise<Recording> {
      return localVarFp.create(recordingRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    destroy(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.destroy(id, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} [cursor] The pagination cursor value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(cursor?: string, options?: any): AxiosPromise<PaginatedRecordingList> {
      return localVarFp.list(cursor, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {PatchedRecordingRequest} [patchedRecordingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markAsSeenPartialUpdate(
      id: number,
      patchedRecordingRequest?: PatchedRecordingRequest,
      options?: any,
    ): AxiosPromise<Recording> {
      return localVarFp
        .markAsSeenPartialUpdate(id, patchedRecordingRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {PatchedRecordingRequest} [patchedRecordingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdate(
      id: number,
      patchedRecordingRequest?: PatchedRecordingRequest,
      options?: any,
    ): AxiosPromise<Recording> {
      return localVarFp.partialUpdate(id, patchedRecordingRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieve(id: number, options?: any): AxiosPromise<Recording> {
      return localVarFp.retrieve(id, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signalRetrieve(id: number, options?: any): AxiosPromise<Signal> {
      return localVarFp.signalRetrieve(id, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {RecordingRequest} recordingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: number, recordingRequest: RecordingRequest, options?: any): AxiosPromise<Recording> {
      return localVarFp.update(id, recordingRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {any} inputfile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadCreate(inputfile: any, options?: any): AxiosPromise<Recording> {
      return localVarFp.uploadCreate(inputfile, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * RecordingApi - interface
 * @export
 * @interface RecordingApi
 */
export interface RecordingApiInterface {
  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} chunk
   * @param {number} id A unique integer value identifying this recording.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  chunkRetrieve(chunk: number, id: number, options?: AxiosRequestConfig): AxiosPromise<SignalChunk>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} targetId
   * @param {RecordingCommentRequest} [recordingCommentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  commentCreate(
    targetId: number,
    recordingCommentRequest?: RecordingCommentRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<RecordingComment>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  commentDestroy(id: number, targetId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} targetId
   * @param {string} [cursor] The pagination cursor value.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  commentList(
    targetId: number,
    cursor?: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<PaginatedRecordingCommentList>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {PatchedRecordingCommentRequest} [patchedRecordingCommentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  commentPartialUpdate(
    id: number,
    targetId: number,
    patchedRecordingCommentRequest?: PatchedRecordingCommentRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<RecordingComment>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  commentRetrieve(id: number, targetId: number, options?: AxiosRequestConfig): AxiosPromise<RecordingComment>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {RecordingCommentRequest} [recordingCommentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  commentUpdate(
    id: number,
    targetId: number,
    recordingCommentRequest?: RecordingCommentRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<RecordingComment>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {RecordingRequest} recordingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  create(recordingRequest: RecordingRequest, options?: AxiosRequestConfig): AxiosPromise<Recording>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  destroy(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} [cursor] The pagination cursor value.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  list(cursor?: string, options?: AxiosRequestConfig): AxiosPromise<PaginatedRecordingList>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {PatchedRecordingRequest} [patchedRecordingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  markAsSeenPartialUpdate(
    id: number,
    patchedRecordingRequest?: PatchedRecordingRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Recording>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {PatchedRecordingRequest} [patchedRecordingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  partialUpdate(
    id: number,
    patchedRecordingRequest?: PatchedRecordingRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Recording>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  retrieve(id: number, options?: AxiosRequestConfig): AxiosPromise<Recording>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  signalRetrieve(id: number, options?: AxiosRequestConfig): AxiosPromise<Signal>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {RecordingRequest} recordingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  update(id: number, recordingRequest: RecordingRequest, options?: AxiosRequestConfig): AxiosPromise<Recording>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {any} inputfile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApiInterface
   */
  uploadCreate(inputfile: any, options?: AxiosRequestConfig): AxiosPromise<Recording>;
}

/**
 * RecordingApi - object-oriented interface
 * @export
 * @class RecordingApi
 * @extends {BaseAPI}
 */
export class RecordingApi extends BaseAPI implements RecordingApiInterface {
  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} chunk
   * @param {number} id A unique integer value identifying this recording.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public chunkRetrieve(chunk: number, id: number, options?: AxiosRequestConfig) {
    return RecordingApiFp(this.configuration)
      .chunkRetrieve(chunk, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} targetId
   * @param {RecordingCommentRequest} [recordingCommentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public commentCreate(
    targetId: number,
    recordingCommentRequest?: RecordingCommentRequest,
    options?: AxiosRequestConfig,
  ) {
    return RecordingApiFp(this.configuration)
      .commentCreate(targetId, recordingCommentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public commentDestroy(id: number, targetId: number, options?: AxiosRequestConfig) {
    return RecordingApiFp(this.configuration)
      .commentDestroy(id, targetId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} targetId
   * @param {string} [cursor] The pagination cursor value.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public commentList(targetId: number, cursor?: string, options?: AxiosRequestConfig) {
    return RecordingApiFp(this.configuration)
      .commentList(targetId, cursor, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {PatchedRecordingCommentRequest} [patchedRecordingCommentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public commentPartialUpdate(
    id: number,
    targetId: number,
    patchedRecordingCommentRequest?: PatchedRecordingCommentRequest,
    options?: AxiosRequestConfig,
  ) {
    return RecordingApiFp(this.configuration)
      .commentPartialUpdate(id, targetId, patchedRecordingCommentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public commentRetrieve(id: number, targetId: number, options?: AxiosRequestConfig) {
    return RecordingApiFp(this.configuration)
      .commentRetrieve(id, targetId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {RecordingCommentRequest} [recordingCommentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public commentUpdate(
    id: number,
    targetId: number,
    recordingCommentRequest?: RecordingCommentRequest,
    options?: AxiosRequestConfig,
  ) {
    return RecordingApiFp(this.configuration)
      .commentUpdate(id, targetId, recordingCommentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {RecordingRequest} recordingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public create(recordingRequest: RecordingRequest, options?: AxiosRequestConfig) {
    return RecordingApiFp(this.configuration)
      .create(recordingRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public destroy(id: number, options?: AxiosRequestConfig) {
    return RecordingApiFp(this.configuration)
      .destroy(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} [cursor] The pagination cursor value.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public list(cursor?: string, options?: AxiosRequestConfig) {
    return RecordingApiFp(this.configuration)
      .list(cursor, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {PatchedRecordingRequest} [patchedRecordingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public markAsSeenPartialUpdate(
    id: number,
    patchedRecordingRequest?: PatchedRecordingRequest,
    options?: AxiosRequestConfig,
  ) {
    return RecordingApiFp(this.configuration)
      .markAsSeenPartialUpdate(id, patchedRecordingRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {PatchedRecordingRequest} [patchedRecordingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public partialUpdate(id: number, patchedRecordingRequest?: PatchedRecordingRequest, options?: AxiosRequestConfig) {
    return RecordingApiFp(this.configuration)
      .partialUpdate(id, patchedRecordingRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public retrieve(id: number, options?: AxiosRequestConfig) {
    return RecordingApiFp(this.configuration)
      .retrieve(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public signalRetrieve(id: number, options?: AxiosRequestConfig) {
    return RecordingApiFp(this.configuration)
      .signalRetrieve(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {RecordingRequest} recordingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public update(id: number, recordingRequest: RecordingRequest, options?: AxiosRequestConfig) {
    return RecordingApiFp(this.configuration)
      .update(id, recordingRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {any} inputfile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingApi
   */
  public uploadCreate(inputfile: any, options?: AxiosRequestConfig) {
    return RecordingApiFp(this.configuration)
      .uploadCreate(inputfile, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
