/* tslint:disable */
/* eslint-disable */
/**
 * ECG Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0-unknown
 * Contact:
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForbiddenError } from '../models';
// @ts-ignore
import { Invite } from '../models';
// @ts-ignore
import { InviteRequest } from '../models';
// @ts-ignore
import { NotFoundError } from '../models';
// @ts-ignore
import { PaginatedInviteList } from '../models';
// @ts-ignore
import { PatchedInviteRequest } from '../models';
// @ts-ignore
import { UnauthenticatedError } from '../models';
// @ts-ignore
import { ValidationError } from '../models';
/**
 * InviteApi - axios parameter creator
 * @export
 */
export const InviteApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accept: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists('accept', 'uuid', uuid);
      const localVarPath = `/invite/{uuid}/accept/`.replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {InviteRequest} inviteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (inviteRequest: InviteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'inviteRequest' is not null or undefined
      assertParamExists('create', 'inviteRequest', inviteRequest);
      const localVarPath = `/invite/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(inviteRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    destroy: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists('destroy', 'uuid', uuid);
      const localVarPath = `/invite/{uuid}/`.replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} [cursor] The pagination cursor value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list: async (cursor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/invite/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {PatchedInviteRequest} [patchedInviteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdate: async (
      uuid: string,
      patchedInviteRequest?: PatchedInviteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists('partialUpdate', 'uuid', uuid);
      const localVarPath = `/invite/{uuid}/`.replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(patchedInviteRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reject: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists('reject', 'uuid', uuid);
      const localVarPath = `/invite/{uuid}/reject/`.replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieve: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists('retrieve', 'uuid', uuid);
      const localVarPath = `/invite/{uuid}/`.replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {InviteRequest} inviteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      uuid: string,
      inviteRequest: InviteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists('update', 'uuid', uuid);
      // verify required parameter 'inviteRequest' is not null or undefined
      assertParamExists('update', 'inviteRequest', inviteRequest);
      const localVarPath = `/invite/{uuid}/`.replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(inviteRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InviteApi - functional programming interface
 * @export
 */
export const InviteApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InviteApiAxiosParamCreator(configuration);
  return {
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accept(
      uuid: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accept(uuid, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {InviteRequest} inviteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      inviteRequest: InviteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(inviteRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async destroy(
      uuid: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.destroy(uuid, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} [cursor] The pagination cursor value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async list(
      cursor?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInviteList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.list(cursor, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {PatchedInviteRequest} [patchedInviteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdate(
      uuid: string,
      patchedInviteRequest?: PatchedInviteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdate(uuid, patchedInviteRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reject(
      uuid: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reject(uuid, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retrieve(
      uuid: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.retrieve(uuid, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {InviteRequest} inviteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      uuid: string,
      inviteRequest: InviteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(uuid, inviteRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InviteApi - factory interface
 * @export
 */
export const InviteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = InviteApiFp(configuration);
  return {
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accept(uuid: string, options?: any): AxiosPromise<Invite> {
      return localVarFp.accept(uuid, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {InviteRequest} inviteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(inviteRequest: InviteRequest, options?: any): AxiosPromise<Invite> {
      return localVarFp.create(inviteRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    destroy(uuid: string, options?: any): AxiosPromise<void> {
      return localVarFp.destroy(uuid, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} [cursor] The pagination cursor value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(cursor?: string, options?: any): AxiosPromise<PaginatedInviteList> {
      return localVarFp.list(cursor, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {PatchedInviteRequest} [patchedInviteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdate(uuid: string, patchedInviteRequest?: PatchedInviteRequest, options?: any): AxiosPromise<Invite> {
      return localVarFp.partialUpdate(uuid, patchedInviteRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reject(uuid: string, options?: any): AxiosPromise<Invite> {
      return localVarFp.reject(uuid, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieve(uuid: string, options?: any): AxiosPromise<Invite> {
      return localVarFp.retrieve(uuid, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {string} uuid
     * @param {InviteRequest} inviteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(uuid: string, inviteRequest: InviteRequest, options?: any): AxiosPromise<Invite> {
      return localVarFp.update(uuid, inviteRequest, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * InviteApi - interface
 * @export
 * @interface InviteApi
 */
export interface InviteApiInterface {
  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApiInterface
   */
  accept(uuid: string, options?: AxiosRequestConfig): AxiosPromise<Invite>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {InviteRequest} inviteRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApiInterface
   */
  create(inviteRequest: InviteRequest, options?: AxiosRequestConfig): AxiosPromise<Invite>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApiInterface
   */
  destroy(uuid: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} [cursor] The pagination cursor value.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApiInterface
   */
  list(cursor?: string, options?: AxiosRequestConfig): AxiosPromise<PaginatedInviteList>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} uuid
   * @param {PatchedInviteRequest} [patchedInviteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApiInterface
   */
  partialUpdate(
    uuid: string,
    patchedInviteRequest?: PatchedInviteRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Invite>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApiInterface
   */
  reject(uuid: string, options?: AxiosRequestConfig): AxiosPromise<Invite>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApiInterface
   */
  retrieve(uuid: string, options?: AxiosRequestConfig): AxiosPromise<Invite>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} uuid
   * @param {InviteRequest} inviteRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApiInterface
   */
  update(uuid: string, inviteRequest: InviteRequest, options?: AxiosRequestConfig): AxiosPromise<Invite>;
}

/**
 * InviteApi - object-oriented interface
 * @export
 * @class InviteApi
 * @extends {BaseAPI}
 */
export class InviteApi extends BaseAPI implements InviteApiInterface {
  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApi
   */
  public accept(uuid: string, options?: AxiosRequestConfig) {
    return InviteApiFp(this.configuration)
      .accept(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {InviteRequest} inviteRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApi
   */
  public create(inviteRequest: InviteRequest, options?: AxiosRequestConfig) {
    return InviteApiFp(this.configuration)
      .create(inviteRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApi
   */
  public destroy(uuid: string, options?: AxiosRequestConfig) {
    return InviteApiFp(this.configuration)
      .destroy(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} [cursor] The pagination cursor value.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApi
   */
  public list(cursor?: string, options?: AxiosRequestConfig) {
    return InviteApiFp(this.configuration)
      .list(cursor, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} uuid
   * @param {PatchedInviteRequest} [patchedInviteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApi
   */
  public partialUpdate(uuid: string, patchedInviteRequest?: PatchedInviteRequest, options?: AxiosRequestConfig) {
    return InviteApiFp(this.configuration)
      .partialUpdate(uuid, patchedInviteRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApi
   */
  public reject(uuid: string, options?: AxiosRequestConfig) {
    return InviteApiFp(this.configuration)
      .reject(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApi
   */
  public retrieve(uuid: string, options?: AxiosRequestConfig) {
    return InviteApiFp(this.configuration)
      .retrieve(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {string} uuid
   * @param {InviteRequest} inviteRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InviteApi
   */
  public update(uuid: string, inviteRequest: InviteRequest, options?: AxiosRequestConfig) {
    return InviteApiFp(this.configuration)
      .update(uuid, inviteRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
