import { listInvites } from '@24sens/ecg01-invite';
import React, { useEffect } from 'react';

import { AppBar } from '../layout/AppBar';
import { Content } from '../layout/Content';
import { Toolbar } from '../layout/Toolbar';
import { fetchPatients } from '../patient/slice';
import * as selectors from '../store/selectors';
import { useAppDispatch, useAppSelector } from '../store/store';
import { PatientListDrawer } from './PatientListDrawer';
import { PhysicianRouter } from './PhysicianRouter';

export const PhysicianLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const isVerified = useAppSelector(selectors.auth.isVerified);

  useEffect(() => {
    dispatch(fetchPatients());
    dispatch(listInvites());
  }, []);
  return (
    <>
      <AppBar className={'No-Print'}>
        <Toolbar />
      </AppBar>
      {isVerified ? (
        <PatientListDrawer variant="permanent" anchor={'left'} ModalProps={{ hideBackdrop: true, keepMounted: true }} />
      ) : null}
      <Content maxWidth="lg">
        <PhysicianRouter />
      </Content>
    </>
  );
};
