import { CountryEnum, PatientProfile, PhysicianProfile } from '@24sens/ecg01-rest-client';
import {
  BirthdateInputField,
  GridTextInputField,
  LabelVarient,
  validateCountryInput,
  validateRequiredInput,
} from '@24sens/ui';
import { formatDateForBackend } from '@24sens/utils';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import React, { CSSProperties, FormEvent, forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { isPatientProfile, isPhysicianProfile } from './slice';

interface ProfileFormErrors {
  countryError?: string;
  line1Error?: string;
  postalCodeError?: string;
  cityError?: string;
  firstNameError?: string;
  lastNameError?: string;
  socialSecError?: string;
  registrationNrError?: string;
}

type ProfileFormState = (PatientProfile | PhysicianProfile) & ProfileFormErrors;

interface ProfileDialogProps {
  open: boolean;
  closeForm: () => void;
  onSubmit: (data: PatientProfile | PhysicianProfile) => void;
  profile: PatientProfile | PhysicianProfile;
  fieldErrors?: { [field: string]: string };
  variant?: LabelVarient;
  style?: CSSProperties;
}

export const ProfileDialog: React.FC<ProfileDialogProps> = ({
  open,
  closeForm,
  onSubmit,
  profile,
  fieldErrors,
  variant,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <DialogContent>
        <ProfileForm
          profile={profile}
          onSubmit={onSubmit}
          style={{ margin: '0.2em' }}
          setFormChanged={() => console.log('changed')}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => console.log('pressed')}>{t('buttons.save')}</Button>
      </DialogActions>
    </Dialog>
  );
};

type Size = 'small' | 'medium';

interface ProfileFormProps {
  onSubmit: (data: PatientProfile | PhysicianProfile) => void;
  setFormChanged?: (value: boolean) => void;
  profile: PatientProfile | PhysicianProfile;
  fieldErrors?: { [field: string]: string };
  variant?: LabelVarient;
  style?: CSSProperties;
  size?: Size;
  skipAddress?: boolean;
}
export const ProfileForm = forwardRef<{ submit: () => void }, ProfileFormProps>(
  ({ onSubmit, profile, fieldErrors, variant, size = 'small', setFormChanged, skipAddress = false, ...props }, ref) => {
    const { t } = useTranslation();
    const formRef = useRef<HTMLFormElement | null>(null);
    const [formState, setFormState] = React.useState<ProfileFormState>(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      !skipAddress
        ? {
            ...profile,
            countryError: undefined,
            date_of_birth: '1970-01-01',
          }
        : { ...profile, countryError: undefined, address: undefined, date_of_birth: '1970-01-01' },
    );
    const [initialFormState] = React.useState(formState);

    useImperativeHandle(ref, () => ({
      submit: () => validateAndSubmit(),
    }));

    function updateFormState(update: Partial<ProfileFormState>) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const newFormState: ProfileFormState = { ...formState, ...update };
      setFormState(newFormState);

      if (setFormChanged) {
        setFormChanged(!shallowEqual(initialFormState, newFormState));
      }
    }
    function eventValidateAndSubmit(event: FormEvent<HTMLFormElement>) {
      event.preventDefault();
      validateAndSubmit();
    }
    function validateAndSubmit() {
      let countryError = undefined;
      let line1Error = undefined;
      let postalCodeError = undefined;
      let cityError = undefined;
      if (formState.address && isPhysicianProfile(formState)) {
        countryError = validateCountryInput(formState.address?.country, t) ?? undefined;
        line1Error = !skipAddress ? validateRequiredInput(formState?.address.line1, t) ?? undefined : undefined;
        postalCodeError = !skipAddress
          ? validateRequiredInput(formState.address?.postal_code, t) ?? undefined
          : undefined;
        cityError = !skipAddress ? validateRequiredInput(formState.address?.city, t) ?? undefined : undefined;
      }
      const firstNameError = validateRequiredInput(formState.first_name, t) ?? undefined;
      const lastNameError = validateRequiredInput(formState.last_name, t) ?? undefined;
      const socialSecError =
        (isPatientProfile(formState) ? validateRequiredInput(formState.social_security_number, t) : undefined) ??
        undefined;
      const registrationNrError =
        (isPhysicianProfile(formState) ? validateRequiredInput(formState.registration_number, t) : undefined) ??
        undefined;
      const errors = {
        countryError,
        line1Error,
        postalCodeError,
        cityError,
        firstNameError,
        lastNameError,
        socialSecError,
        registrationNrError,
      };
      updateFormState(errors);
      if (Object.values(errors).every((v) => !v)) {
        onSubmit(formState);
        if (setFormChanged) {
          setFormChanged(false);
        }
      }
    }

    return (
      <form ref={formRef} onSubmit={eventValidateAndSubmit}>
        <Grid container spacing={2} style={{ justifyContent: 'center' }}>
          <GridTextInputField
            data-testid={'profile-title'}
            value={formState?.title ?? ''}
            onChange={(e) => updateFormState({ title: e.target.value })}
            label={t('profile.title')}
            style={props.style}
            variant={variant}
            size={size}
          />
          <GridTextInputField
            data-testid={'profile-firstName'}
            value={formState.first_name}
            onChange={(e) => updateFormState({ first_name: e.target.value })}
            label={t('profile.firstName')}
            style={props.style}
            variant={variant}
            errorMessage={formState?.firstNameError}
            size={size}
          />
          <GridTextInputField
            data-testid={'profile-lastName'}
            value={formState.last_name}
            onChange={(e) => updateFormState({ last_name: e.target.value })}
            label={t('profile.lastName')}
            style={props.style}
            variant={variant}
            errorMessage={formState?.lastNameError}
            size={size}
          />
          {isPatientProfile(formState) && (
            <GridTextInputField
              data-testid={'profile-SSN'}
              value={formState.social_security_number}
              onChange={(e) => updateFormState({ social_security_number: e.target.value })}
              label={t('profile.socialSecurityNumber')}
              style={props.style}
              variant={variant}
              errorMessage={formState?.socialSecError}
              size={size}
            />
          )}
          {isPhysicianProfile(formState) && (
            <GridTextInputField
              data-testid={'profile-registrationNr'}
              value={formState.registration_number}
              onChange={(e) => updateFormState({ registration_number: e.target.value })}
              label={t('profile.registrationNumber')}
              style={props.style}
              variant={variant}
              errorMessage={formState?.registrationNrError}
              size={size}
            />
          )}
          <BirthdateInputField
            label={t('profile.birthdate')}
            value={formState.date_of_birth ?? undefined} //? new Date(formState.date_of_birth) : undefined}
            onChange={(value) => {
              updateFormState({ date_of_birth: formatDateForBackend(value, true) });
              if (setFormChanged) {
                setFormChanged(true);
              }
            }}
            size={size}
            style={props.style}
          />
          {isPhysicianProfile(formState) && (
            <>
              <GridTextInputField
                data-testid={'profile-street'}
                value={formState.address?.line1}
                onChange={(e) => updateFormState({ address: { ...formState.address, line1: e.target.value } })}
                label={t('profile.street')}
                style={props.style}
                variant={variant}
                errorMessage={formState?.line1Error}
                size={size}
              />
              <GridTextInputField
                data-testid={'profile-streetNr'}
                value={formState.address?.line2}
                onChange={(e) => updateFormState({ address: { ...formState.address, line2: e.target.value } })}
                label={t('profile.streetNumber')}
                style={props.style}
                variant={variant}
                size={size}
              />
              <GridTextInputField
                data-testid={'profile-postalCode'}
                value={formState.address?.postal_code}
                onChange={(e) => updateFormState({ address: { ...formState.address, postal_code: e.target.value } })}
                label={t('profile.postalCode')}
                style={props.style}
                variant={variant}
                errorMessage={formState?.postalCodeError}
                size={size}
              />
              <GridTextInputField
                data-testid={'profile-city'}
                value={formState.address?.city}
                onChange={(e) => updateFormState({ address: { ...formState.address, city: e.target.value } })}
                label={t('profile.city')}
                style={props.style}
                variant={variant}
                errorMessage={formState?.cityError}
                size={size}
              />
              <GridTextInputField
                data-testid={'profile-country'}
                value={formState.address?.country}
                onChange={(e) =>
                  updateFormState({
                    address: {
                      ...formState.address,
                      country: e.target.value as CountryEnum,
                    },
                    countryError: undefined,
                  })
                }
                errorMessage={formState?.countryError}
                label={t('profile.country')}
                style={props.style}
                variant={variant}
                size={size}
              />
            </>
          )}
        </Grid>
      </form>
    );
  },
);
ProfileForm.displayName = 'forwardRef(ProfileForm)';
