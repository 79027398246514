import {
  ApiError,
  deleteToken,
  Patient,
  PatientApi,
  RejectedPayloadAction,
  serializeError,
} from '@24sens/ecg01-rest-client';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

export interface IPatient extends Patient {
  id: number;
}

export interface Services {
  patient: PatientApi;
}

export const adapter = createEntityAdapter<IPatient>({
  selectId: (patient) => patient.id,
  sortComparer: (a, b) => a.id - b.id,
});

export interface PatientState extends EntityState<IPatient> {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: ApiError | null;
  filterText: string;
}

export const initialPatientState: PatientState = adapter.getInitialState({
  loading: 'idle',
  error: null,
  filterText: '',
});

export const fetchPatients = createAsyncThunk<IPatient[], void, { rejectValue: ApiError; extra: Services }>(
  'ecg01-patient/list',
  async (_ = undefined, thunkApi) => {
    const response = await thunkApi.extra.patient.list();
    return response.data.results ?? [];
  },
  { serializeError },
);

export const fetchPatient = createAsyncThunk<IPatient, { id: number }, { rejectValue: ApiError; extra: Services }>(
  'ecg01-patient/fetchPatient',
  async ({ id }, thunkApi) => {
    const response = await thunkApi.extra.patient.retrieve(id);
    return response.data;
  },
  { serializeError },
);

export const buildSlice = <R>(
  sliceSelector: (state: R) => PatientState,
  initialState: PatientState = initialPatientState,
) => {
  const setPending = (state: PatientState) => {
    state.loading = 'pending';
  };

  const onRejected = (state: PatientState, action: RejectedPayloadAction<ApiError, ApiError>) => {
    state.loading = 'failed';
    const error = action.payload ?? action.error;
    if (error.code === '401') {
      deleteToken();
    }
    state.error = {
      ...error,
      options: { text: error.options?.text ?? '', callback: slice.actions.resetError },
    };
  };

  const setFulfilled = (state: PatientState, action: PayloadAction<IPatient[]>) => {
    state.loading = 'succeeded';
    adapter.upsertMany(state, action.payload);
  };

  const setFulfilledSingle = (state: PatientState, action: PayloadAction<IPatient>) => {
    state.loading = 'succeeded';
    adapter.upsertOne(state, action.payload);
  };

  const slice = createSlice({
    name: 'patient',
    initialState: initialState,
    reducers: {
      filter: (state, action: PayloadAction<string>) => {
        state.filterText = action.payload;
      },
      resetError: (state) => {
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchPatients.pending, setPending);
      builder.addCase(fetchPatients.fulfilled, setFulfilled);
      builder.addCase(fetchPatients.rejected, onRejected);

      builder.addCase(fetchPatient.pending, setPending);
      builder.addCase(fetchPatient.fulfilled, setFulfilledSingle);
      builder.addCase(fetchPatient.rejected, onRejected);
    },
  });

  const patientSelectors = adapter.getSelectors(sliceSelector);
  // const getId = (state: R, id: number) => id;
  const filterText = (state: R) => sliceSelector(state).filterText;

  const patients = patientSelectors.selectAll;
  const filteredPatients = createSelector([patients, filterText], (patients, filterText) =>
    (filterText ? patients.filter((p) => p.email?.includes(filterText)) : patients).map((p) => p.id),
  );

  return {
    ...slice,
    selectors: {
      patients,
      filteredPatients,
      filterText,
      ...patientSelectors,
    },
  };
};
