import { TabContext, TabPanel } from '@mui/lab';
import { Slider, Stack, Tab, Tabs, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as selectors from '../store/selectors';
import { useAppSelector } from '../store/store';
import { HeartRateTable } from './HeartRateTable';
import { SignalChunkPlot } from './SignalChunkPlot';
import { useSignal } from './useSignalChunk';

const markerStyle = {
  borderStyle: 'solid',
  borderWidth: '5px',
  borderRadius: '5px',
};

const afMarkerStyle = {
  borderColor: 'red',
  ...markerStyle,
};
const noiseMarkerStyle = {
  borderColor: 'grey.500',
  ...markerStyle,
};

export interface SignalDetailsProps {
  recordingId: number;
}

export const SignalDetails: FC<SignalDetailsProps> = ({ recordingId, ...props }) => {
  const [selectedTab, setSelectedTab] = useState('0');
  const [chunkIdx, setChunkIdx] = useState(0);
  const signal = useSignal(recordingId);
  const { noisy, af } = useAppSelector((state) => selectors.signal.labeledChunks(state, recordingId));
  const { t } = useTranslation();

  if (!signal || !signal.start_time || !signal.end_time) {
    return <div>No recording</div>;
  }

  return (
    <TabContext value={selectedTab}>
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => {
          setSelectedTab(newValue);
          setChunkIdx(Number(newValue));
        }}
      >
        {Object.entries<[number, number]>(signal.metadata.hourly_chunks).map(([hour, [start, end]], i) => (
          <Tab key={hour} label={t('timeline.plotCard.tabName', { hour: i })} value={start.toString()} wrapped />
        ))}
      </Tabs>

      {Object.entries<[number, number]>(signal.metadata.hourly_chunks).map(([hour, [start, end]]) => {
        const style = Object();
        const noisySelectors =
          noisy?.filter((i) => i > start && i < end).map((i) => `.MuiSlider-mark[data-index="${i - start}"]`) ?? [];
        if (noisySelectors.length > 0) {
          style[`& ${noisySelectors.join(',')}`] = noiseMarkerStyle;
        }
        const afSelectors =
          af?.filter((i) => i > start && i < end).map((i) => `.MuiSlider-mark[data-index="${i - start}"]`) ?? [];
        if (afSelectors.length > 0) {
          style[`& ${afSelectors.join(',')}`] = afMarkerStyle;
        }

        return (
          <TabPanel key={hour} value={start.toString()}>
            <Stack alignItems={'center'}>
              <SignalChunkPlot recordingId={recordingId} chunkIdx={chunkIdx} />
              <HeartRateTable recordingId={recordingId} chunkIdx={chunkIdx} />
              <Slider
                defaultValue={0}
                valueLabelDisplay="auto"
                value={chunkIdx}
                onChange={(event, newValue) => setChunkIdx(newValue as number)}
                step={1}
                marks
                min={start}
                max={end}
                sx={style}
              />
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <div style={{ color: 'grey', width: 3, height: 3, ...noiseMarkerStyle }}></div>
                {t('timeline.plotCard.sliderNoise')}
                <div style={{ color: 'red', width: 3, height: 3, ...afMarkerStyle }}></div>{' '}
                {t('timeline.plotCard.sliderAnomaly')}
              </Stack>
            </Stack>
          </TabPanel>
        );
      })}
      <Typography align={'center'} variant={'h5'}>
        Gain on plot: 6mm/mV (for ECG-amplitude -5mV - +5mV)
      </Typography>
    </TabContext>
  );
};
