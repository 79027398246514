import { selectors as invite } from '@24sens/ecg01-invite';
import { adapter, RecordingState } from '@24sens/ecg01-recordings';
import { InviteStatusEnum } from '@24sens/ecg01-rest-client';
import { createSelector } from 'reselect';

import { selectors as auth } from '../auth';
import { selectors as comment } from '../comment';
import { selectors as notification } from '../notifications';
import { selectors as patient } from '../patient';
import { selectors as physician } from '../physician';
import { selectors as recording } from '../recording';
import { selectors as signal } from '../signal';
import { RootState, TimelineEntry } from './store';

const selectId = (state: unknown, id: number) => id;

const recordingSelector = adapter.getSelectors((state: { recording: RecordingState }) => state.recording);
const recordings = recordingSelector.selectAll;

const timelineRecordingsForPatient = createSelector([recordings, selectId], (recordings, id) => {
  return recordings.filter((r) => r.patient_id === id);
});

const timelineCommentsForPatient = createSelector([comment.selectAll, selectId], (comments, id) =>
  comments.filter((c) => c.target_id === id),
);

export const timelineEntries = createSelector(
  timelineRecordingsForPatient,
  timelineCommentsForPatient,
  (recordings, comments): TimelineEntry[] =>
    [...comments, ...recordings].sort((d, d1) =>
      d.created && d1.created ? new Date(d1.created).getTime() - new Date(d.created).getTime() : 0,
    ),
);

const timeline = {
  timelineRecordingsForPatient,
  timelineCommentsForPatient,
  forPatient: timelineEntries,
};

const modals = {
  invite: {
    modalState: (state: RootState) => {
      if (auth.isPatient(state)) {
        if (invite.waiting(state)) {
          return 'ACCEPT_REJECT_DIALOG';
        }
        if (invite.invite(state)?.status === InviteStatusEnum.Accepted) {
          return 'ACCEPTED_DIALOG';
        }
        if (invite.invite(state)?.status === InviteStatusEnum.Rejected) {
          return 'REJECTED_DIALOG';
        }
      }
      return null;
    },
    acceptOrReject: (state: RootState) => auth.isPatient(state) && invite.waiting(state),
    success: (state: RootState) => auth.isPatient(state) && invite.waiting(state),
  },
};

/**
 * Generic Selectors
 */
export const apiError = (state: RootState) =>
  state.auth.error || state.recording.error || state.comment.error || state.invite.error || state.patient.error;

export { auth, comment, invite, modals, patient, physician, recording, signal, timeline };

/**
 * Notifications Selectors
 */

export const notifications = notification.selectAll;

export const notificationsFiltered = createSelector([notifications], (notifications) =>
  notifications.sort((n1, n2) => new Date(n2.timestamp).getTime() - new Date(n1.timestamp).getTime()).filter((notification) => notification.unread && !!notification.target ),
);

export const notifications_last = createSelector(
  [notifications, notificationsFiltered],
  (notifications, notificationsFiltered) => {
    if (notificationsFiltered.length > 0) {
      return notificationsFiltered;
    }

    return notifications.slice(0,5).filter(n => !!n.target);
  },
);
