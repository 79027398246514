import { Notification, VerbEnum } from '@24sens/ecg01-rest-client';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import * as selectors from '../store/selectors';

const eqIgnoreCase = (s1: string, s2: string): boolean => s1.toLowerCase() === s2.toLowerCase();

export interface TranslatedNotification extends Notification {
  text: string;
  link?: string;
}

export function useNotifications() {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  const notifications: Notification[] = useSelector(selectors.notifications_last);

  const patients = useSelector(selectors.patient.selectEntities);
  const notificationObjects: TranslatedNotification[] = notifications
    .filter((n) => !!n.target)
    .map((n) => {
      const notificationWithText: TranslatedNotification = { ...n, text: '', link: 'patients/' };

      const actorCt = n.actor?.content_type;
      const verb = n.verb;
      const targetCt = n.target?.content_type;
      const actionObject = n.action_object?.content_type;

      if (actorCt === 'patient') {
        const patient = patients[n.actor.id];

        if (eqIgnoreCase(verb, VerbEnum.Created) && targetCt == 'comment' && actionObject == 'recording') {
          notificationWithText.text = t('notifications.patientCreatedCommentRecording', patient);
          notificationWithText.link = generatePath(`${path}/patients/:patientId/recordings/:recordingId`, {
            patientId: n.actor.id,
            recordingId: n.action_object.id,
          });
        } else if (eqIgnoreCase(verb, VerbEnum.Accepted) && targetCt == 'invite') {
          notificationWithText.text = t('notifications.patientAcceptedInvitation', patient);
          notificationWithText.link = generatePath(`${path}/patients/:patientId`, {
            patientId: n.actor.id,
          });
        } else if (eqIgnoreCase(verb, VerbEnum.Rejected) && targetCt == 'invite') {
          notificationWithText.text = t('notifications.patientDeclinedInvitation', patient);
          notificationWithText.link = generatePath(`${path}`);
        } else if (eqIgnoreCase(verb, VerbEnum.Started) && targetCt == 'recording') {
          notificationWithText.text = t('notifications.patientStartedRecording', patient);
          notificationWithText.link = generatePath(`${path}/patients/:patientId/recordings/:recordingId`, {
            patientId: n.actor.id,
            recordingId: n.target.id,
          });
        } else if (eqIgnoreCase(verb, VerbEnum.Stopped) && targetCt == 'recording') {
          notificationWithText.text = t('notifications.patientStoppedRecording', patient);
          notificationWithText.link = generatePath(`${path}/patients/:patientId/recordings/:recordingId`, {
            patientId: n.actor.id,
            recordingId: n.target.id,
          });
        } else if (eqIgnoreCase(verb, VerbEnum.Uploaded) && targetCt == 'recording') {
          notificationWithText.text = t('notifications.patientUploadedRecording', patient);
          notificationWithText.link = generatePath(`${path}/patients/:patientId/recordings/:recordingId`, {
            patientId: n.actor.id,
            recordingId: n.target.id,
          });
        } else if (eqIgnoreCase(verb, VerbEnum.Viewed) && targetCt == 'recording') {
          notificationWithText.text = t('notifications.physicianViewedRecording', patient);
          notificationWithText.link = generatePath(`${path}/patients/:patientId/recordings/:recordingId`, {
            patientId: n.action_object.id,
            recordingId: n.target.id,
          });
        }
      } else if (actorCt === 'physician') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (eqIgnoreCase(verb, VerbEnum.Created) && targetCt == 'comment' && actionObject === 'patient') {
          notificationWithText.text = t('notifications.physicianSharedComment');
          notificationWithText.link = generatePath(`${path}/patients/:patientId`, {
            patientId: n.action_object.id,
          });
        } else if (eqIgnoreCase(verb, VerbEnum.Created) && targetCt == 'comment' && actionObject === 'recording') {
          notificationWithText.text = t('notifications.physicianSharedCommentRecording');
          notificationWithText.link = generatePath(`${path}/patients/:patientId/recordings/:recordingId`, {
            patientId: n.action_object.id,
            recordingId: n.action_object.id,
          });
        }
      }
      return notificationWithText;
    })

  return notificationObjects;
}
