import {
  ApiError,
  HeartrateStats,
  Label,
  RecordingApi,
  RejectedPayloadAction,
  Signal,
  SignalSeries,
} from '@24sens/ecg01-rest-client';
import { wrap } from '@24sens/utils';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Draft,
  EntityState,
} from '@reduxjs/toolkit';

export interface Sig {
  mv: number[];
  timestamp: Date[];
  idx: number[];
}
export interface TimeSeriesChunk {
  idxoffset: number;
  startTime: Date;
  samplerate: number;
  mv: TimeSeries;
  r_peaks: TimeSeries;
  heartrate: TimeSeries;
  labels: Label;
}

export interface Chunk {
  [index: number]: TimeSeriesChunk;
}

export interface TimeSeries {
  index: number[];
  time: Date[];
  value: number[];
}

export interface ChartSignal extends Signal {
  id: number;
  chunks: Chunk;
}

export interface Services {
  recording: RecordingApi;
}

export const fetchSignal = createAsyncThunk<Signal, number, { rejectValue: ApiError; extra: Services }>(
  'signal/fetch',
  async (recordingId, { extra }) => {
    console.log(`Fetching signal of recording ${recordingId}`);
    const response = await extra.recording.signalRetrieve(recordingId);
    console.log(`Recieved signal for recording ${recordingId}`);
    return response.data;
  },
);

const idxToDate = (arr: number[], startTime: Date | string, samplerateHz: number, offset = 0): Date[] => {
  const start = new Date(startTime).getTime();
  const periodMs = (1 / samplerateHz) * 1000;
  return arr.map((i) => new Date(start + periodMs * (i + offset)));
};

const seriesToTimeSeries = (
  arr: SignalSeries | null | undefined,
  startTime: Date | string,
  sampleRateHz: number,
  offset = 0,
): TimeSeries => {
  return arr
    ? {
        index: arr.index,
        time: idxToDate(arr.index, startTime, sampleRateHz, offset),
        value: arr.values,
      }
    : {
        index: [],
        time: [],
        value: [],
      };
};

export const fetchSignalChunk = createAsyncThunk<
  [number, TimeSeriesChunk],
  { recordingId: number; chunkIdx: number },
  { rejectValue: ApiError; extra: Services }
>('signal/fetchChunk', async ({ recordingId, chunkIdx }, { extra, getState }) => {
  console.log(`Fetching chunk ${chunkIdx} of recording ${recordingId}`);
  const response = await extra.recording.chunkRetrieve(chunkIdx, recordingId);
  const mv: TimeSeries = seriesToTimeSeries(
    response.data.mv,
    response.data.timeoffset,
    response.data.samplerate,
    response.data.idxoffset * -1,
  );
  const heartrate: TimeSeries = seriesToTimeSeries(
    response.data.heartrate,
    response.data.timeoffset,
    response.data.samplerate,
    response.data.idxoffset * -1,
  );
  console.log(`Recieved chunk ${chunkIdx} of recording ${recordingId}`);

  const chunk: TimeSeriesChunk = {
    idxoffset: response.data.idxoffset,
    startTime: new Date(response.data.timeoffset),
    samplerate: response.data.samplerate,
    labels: response.data.labels,
    mv: mv,
    heartrate: heartrate,
    r_peaks: {
      index: response.data.r_peaks ?? [],
      time: response.data.r_peaks
        ? idxToDate(
            response.data.r_peaks,
            response.data.timeoffset,
            response.data.samplerate,
            response.data.idxoffset * -1,
          )
        : [],
      value: response.data.r_peaks ? response.data.r_peaks.map((i) => mv.value[i - response.data.idxoffset]) : [],
    },
  };
  return [chunkIdx, chunk];
});

const adapter = createEntityAdapter<ChartSignal>({
  selectId: (data) => data.id,
  sortComparer: (a, b) => (b.id < a.id ? -1 : 1),
});

export interface SignalChartState extends EntityState<ChartSignal> {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: ApiError | null;
  start_time: Date | null;
  end_time: Date | null;
  labels: {
    [chunkIdx: number]: Label;
  };
}

export const initialSignalState: SignalChartState = adapter.getInitialState({
  loading: 'idle',
  error: null,
  start_time: null,
  end_time: null,
  labels: {},
});

export const buildSlice = <R>(
  sliceSelector: (state: R) => SignalChartState,
  initialState: SignalChartState = initialSignalState,
) => {
  const setPending = (state: Draft<SignalChartState>) => {
    state.loading = 'pending';
  };

  const onRejected = (state: SignalChartState, action: RejectedPayloadAction<ApiError, ApiError>) => {
    state.loading = 'failed';
    const error = action.payload ?? action.error;
    if (action.payload) {
      state.error = action.payload;
    } else {
      state.error = {
        ...error,
        code: error.code ?? 'UNKNOWN',
        message: action.error.message ?? action.error.name ?? 'Unknown Error',
        options: { text: error.options?.text ?? '', callback: slice.actions.resetError },
      };
    }
  };

  const slice = createSlice({
    name: 'chart',
    initialState: initialState,
    reducers: {
      resetError: (state) => {
        state.error = null;
      },
    },

    extraReducers: (builder) => {
      builder.addCase(fetchSignal.pending, setPending);
      builder.addCase(fetchSignal.rejected, onRejected);

      builder.addCase(fetchSignal.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        adapter.upsertOne(state, {
          id: action.meta.arg,
          chunks: state.entities[action.meta.arg]?.chunks ?? {},
          ...action.payload,
        });
      });
      builder.addCase(fetchSignalChunk.pending, setPending);
      builder.addCase(fetchSignalChunk.rejected, onRejected);
      builder.addCase(fetchSignalChunk.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        const sig: ChartSignal | undefined = state.entities[action.meta.arg.recordingId];
        if (!sig) {
          return;
        }
        adapter.upsertOne(state, {
          ...sig,
          chunks: {
            ...(sig?.chunks ?? {}),
            [action.payload[0]]: action.payload[1],
          },
        });
      });
    },
  });

  const selectors = adapter.getSelectors(sliceSelector);
  const selectChunkIdx = (state: R, idx: { recordingId: number; chunkIdx: number }) => idx;
  const selectId = (state: R, id: number) => id;
  const selectEntities = selectors.selectEntities;
  const isBusy = (state: SignalChartState) => state.loading === 'pending';
  const chunk = createSelector(
    [selectEntities, selectChunkIdx],
    (entities, idx) => entities[idx.recordingId]?.chunks[idx.chunkIdx],
  );

  const labeledChunks = createSelector([selectors.selectById], (signal) => ({
    noisy: signal?.label_df.index?.filter((idx) => signal?.label_df.noise_combined[idx] < 1),
    af: signal?.label_df.index?.filter((idx) => signal?.label_df.detection_class[idx] === 2),
  }));
  const heartrateStatsForChunk = createSelector([selectEntities, selectChunkIdx], (entities, idx) => {
    const df = entities[idx.recordingId]?.heartrate_stats_df;
    const x: HeartrateStats | null = df
      ? {
          hr_count: df.hr_count[idx.chunkIdx],
          hr_mean: df.hr_mean[idx.chunkIdx],
          hr_std: df.hr_std[idx.chunkIdx],
          hr_min: df.hr_min[idx.chunkIdx],
          hr_perc25: df.hr_perc25[idx.chunkIdx],
          hr_perc50: df.hr_perc50[idx.chunkIdx],
          hr_perc75: df.hr_perc75[idx.chunkIdx],
          hr_max: df.hr_max[idx.chunkIdx],
        }
      : null;
    return x;
  });
  const heartrateStatsForSignal = createSelector(
    [selectors.selectById, selectId],
    (entity, id) => entity?.heartrate_stats,
  );

  return {
    ...slice,
    selectors: {
      ...selectors,
      isBusy: wrap(sliceSelector, isBusy),
      heartrateStatsForChunk: heartrateStatsForChunk,
      heartrateStatsForSignal: heartrateStatsForSignal,
      labeledChunks: labeledChunks,
      chunk: chunk,
    },
  } as const;
};
