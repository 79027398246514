import List from '@mui/material/List';
import { EntityId } from '@reduxjs/toolkit';
import React from 'react';
import { generatePath } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import { InviteListItem, PatientListItem } from './PatientListItem';

function isString(x: string | number): x is string {
  return typeof x === 'string';
}

export interface PatientListProps {
  patientIds: EntityId[];
  emptyPlaceholder: React.ReactElement;
}

export const PatientList: React.FC<PatientListProps> = (props) => {
  const { path } = useRouteMatch();

  console.log('Patient and Invite IDs: ', props.patientIds);

  return (
    <List sx={{ overflow: 'auto' }}>
      {props.patientIds.map((patientId) =>
        isString(patientId) ? (
          <InviteListItem key={patientId} inviteUuid={patientId} />
        ) : (
          <PatientListItem
            key={patientId}
            patientId={patientId}
            to={generatePath(`${path}/patients/:patientId`, { patientId })}
          />
        ),
      )}

      {props.patientIds.length === 0 && props.emptyPlaceholder}
    </List>
  );
};
