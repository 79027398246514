/* tslint:disable */
/* eslint-disable */
/**
 * ECG Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0-unknown
 * Contact:
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForbiddenError } from '../models';
// @ts-ignore
import { NotFoundError } from '../models';
// @ts-ignore
import { PaginatedPatientCommentList } from '../models';
// @ts-ignore
import { PaginatedPatientList } from '../models';
// @ts-ignore
import { PaginatedRecordingList } from '../models';
// @ts-ignore
import { PatchedPatientCommentRequest } from '../models';
// @ts-ignore
import { PatchedRecordingRequest } from '../models';
// @ts-ignore
import { Patient } from '../models';
// @ts-ignore
import { PatientComment } from '../models';
// @ts-ignore
import { PatientCommentRequest } from '../models';
// @ts-ignore
import { Recording } from '../models';
// @ts-ignore
import { RecordingRequest } from '../models';
// @ts-ignore
import { Signal } from '../models';
// @ts-ignore
import { SignalChunk } from '../models';
// @ts-ignore
import { UnauthenticatedError } from '../models';
// @ts-ignore
import { ValidationError } from '../models';
/**
 * PatientApi - axios parameter creator
 * @export
 */
export const PatientApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} targetId
     * @param {PatientCommentRequest} [patientCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentCreate: async (
      targetId: number,
      patientCommentRequest?: PatientCommentRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'targetId' is not null or undefined
      assertParamExists('commentCreate', 'targetId', targetId);
      const localVarPath = `/patient/{target_id}/comment/`.replace(
        `{${'target_id'}}`,
        encodeURIComponent(String(targetId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(patientCommentRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentDestroy: async (id: number, targetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('commentDestroy', 'id', id);
      // verify required parameter 'targetId' is not null or undefined
      assertParamExists('commentDestroy', 'targetId', targetId);
      const localVarPath = `/patient/{target_id}/comment/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'target_id'}}`, encodeURIComponent(String(targetId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} targetId
     * @param {string} [cursor] The pagination cursor value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentList: async (targetId: number, cursor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'targetId' is not null or undefined
      assertParamExists('commentList', 'targetId', targetId);
      const localVarPath = `/patient/{target_id}/comment/`.replace(
        `{${'target_id'}}`,
        encodeURIComponent(String(targetId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {PatchedPatientCommentRequest} [patchedPatientCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentPartialUpdate: async (
      id: number,
      targetId: number,
      patchedPatientCommentRequest?: PatchedPatientCommentRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('commentPartialUpdate', 'id', id);
      // verify required parameter 'targetId' is not null or undefined
      assertParamExists('commentPartialUpdate', 'targetId', targetId);
      const localVarPath = `/patient/{target_id}/comment/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'target_id'}}`, encodeURIComponent(String(targetId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedPatientCommentRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentRetrieve: async (id: number, targetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('commentRetrieve', 'id', id);
      // verify required parameter 'targetId' is not null or undefined
      assertParamExists('commentRetrieve', 'targetId', targetId);
      const localVarPath = `/patient/{target_id}/comment/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'target_id'}}`, encodeURIComponent(String(targetId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {PatientCommentRequest} [patientCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentUpdate: async (
      id: number,
      targetId: number,
      patientCommentRequest?: PatientCommentRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('commentUpdate', 'id', id);
      // verify required parameter 'targetId' is not null or undefined
      assertParamExists('commentUpdate', 'targetId', targetId);
      const localVarPath = `/patient/{target_id}/comment/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'target_id'}}`, encodeURIComponent(String(targetId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(patientCommentRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/patient/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} chunk
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingChunkRetrieve: async (
      chunk: number,
      id: number,
      patient: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'chunk' is not null or undefined
      assertParamExists('recordingChunkRetrieve', 'chunk', chunk);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('recordingChunkRetrieve', 'id', id);
      // verify required parameter 'patient' is not null or undefined
      assertParamExists('recordingChunkRetrieve', 'patient', patient);
      const localVarPath = `/patient/{patient}/recording/{id}/chunk/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'patient'}}`, encodeURIComponent(String(patient)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (chunk !== undefined) {
        localVarQueryParameter['chunk'] = chunk;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} patient
     * @param {RecordingRequest} recordingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingCreate: async (
      patient: number,
      recordingRequest: RecordingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patient' is not null or undefined
      assertParamExists('recordingCreate', 'patient', patient);
      // verify required parameter 'recordingRequest' is not null or undefined
      assertParamExists('recordingCreate', 'recordingRequest', recordingRequest);
      const localVarPath = `/patient/{patient}/recording/`.replace(
        `{${'patient'}}`,
        encodeURIComponent(String(patient)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(recordingRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingDestroy: async (id: number, patient: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('recordingDestroy', 'id', id);
      // verify required parameter 'patient' is not null or undefined
      assertParamExists('recordingDestroy', 'patient', patient);
      const localVarPath = `/patient/{patient}/recording/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'patient'}}`, encodeURIComponent(String(patient)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} patient
     * @param {string} [cursor] The pagination cursor value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingList: async (patient: number, cursor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'patient' is not null or undefined
      assertParamExists('recordingList', 'patient', patient);
      const localVarPath = `/patient/{patient}/recording/`.replace(
        `{${'patient'}}`,
        encodeURIComponent(String(patient)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {PatchedRecordingRequest} [patchedRecordingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingMarkAsSeenPartialUpdate: async (
      id: number,
      patient: number,
      patchedRecordingRequest?: PatchedRecordingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('recordingMarkAsSeenPartialUpdate', 'id', id);
      // verify required parameter 'patient' is not null or undefined
      assertParamExists('recordingMarkAsSeenPartialUpdate', 'patient', patient);
      const localVarPath = `/patient/{patient}/recording/{id}/mark_as_seen/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'patient'}}`, encodeURIComponent(String(patient)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedRecordingRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {PatchedRecordingRequest} [patchedRecordingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingPartialUpdate: async (
      id: number,
      patient: number,
      patchedRecordingRequest?: PatchedRecordingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('recordingPartialUpdate', 'id', id);
      // verify required parameter 'patient' is not null or undefined
      assertParamExists('recordingPartialUpdate', 'patient', patient);
      const localVarPath = `/patient/{patient}/recording/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'patient'}}`, encodeURIComponent(String(patient)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedRecordingRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingRetrieve: async (id: number, patient: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('recordingRetrieve', 'id', id);
      // verify required parameter 'patient' is not null or undefined
      assertParamExists('recordingRetrieve', 'patient', patient);
      const localVarPath = `/patient/{patient}/recording/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'patient'}}`, encodeURIComponent(String(patient)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingSignalRetrieve: async (
      id: number,
      patient: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('recordingSignalRetrieve', 'id', id);
      // verify required parameter 'patient' is not null or undefined
      assertParamExists('recordingSignalRetrieve', 'patient', patient);
      const localVarPath = `/patient/{patient}/recording/{id}/signal/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'patient'}}`, encodeURIComponent(String(patient)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {RecordingRequest} recordingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingUpdate: async (
      id: number,
      patient: number,
      recordingRequest: RecordingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('recordingUpdate', 'id', id);
      // verify required parameter 'patient' is not null or undefined
      assertParamExists('recordingUpdate', 'patient', patient);
      // verify required parameter 'recordingRequest' is not null or undefined
      assertParamExists('recordingUpdate', 'recordingRequest', recordingRequest);
      const localVarPath = `/patient/{patient}/recording/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'patient'}}`, encodeURIComponent(String(patient)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(recordingRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} patient
     * @param {any} inputfile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingUploadCreate: async (
      patient: number,
      inputfile: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patient' is not null or undefined
      assertParamExists('recordingUploadCreate', 'patient', patient);
      // verify required parameter 'inputfile' is not null or undefined
      assertParamExists('recordingUploadCreate', 'inputfile', inputfile);
      const localVarPath = `/patient/{patient}/recording/upload/`.replace(
        `{${'patient'}}`,
        encodeURIComponent(String(patient)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (inputfile !== undefined) {
        localVarFormParams.append('inputfile', inputfile as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this patient.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('retrieve', 'id', id);
      const localVarPath = `/patient/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PatientApi - functional programming interface
 * @export
 */
export const PatientApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PatientApiAxiosParamCreator(configuration);
  return {
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} targetId
     * @param {PatientCommentRequest} [patientCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentCreate(
      targetId: number,
      patientCommentRequest?: PatientCommentRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientComment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentCreate(targetId, patientCommentRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentDestroy(
      id: number,
      targetId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentDestroy(id, targetId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} targetId
     * @param {string} [cursor] The pagination cursor value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentList(
      targetId: number,
      cursor?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPatientCommentList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentList(targetId, cursor, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {PatchedPatientCommentRequest} [patchedPatientCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentPartialUpdate(
      id: number,
      targetId: number,
      patchedPatientCommentRequest?: PatchedPatientCommentRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientComment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentPartialUpdate(
        id,
        targetId,
        patchedPatientCommentRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentRetrieve(
      id: number,
      targetId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientComment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentRetrieve(id, targetId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {PatientCommentRequest} [patientCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentUpdate(
      id: number,
      targetId: number,
      patientCommentRequest?: PatientCommentRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientComment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentUpdate(
        id,
        targetId,
        patientCommentRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async list(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPatientList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.list(limit, offset, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} chunk
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recordingChunkRetrieve(
      chunk: number,
      id: number,
      patient: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignalChunk>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recordingChunkRetrieve(chunk, id, patient, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} patient
     * @param {RecordingRequest} recordingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recordingCreate(
      patient: number,
      recordingRequest: RecordingRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recording>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recordingCreate(patient, recordingRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recordingDestroy(
      id: number,
      patient: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recordingDestroy(id, patient, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} patient
     * @param {string} [cursor] The pagination cursor value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recordingList(
      patient: number,
      cursor?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedRecordingList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recordingList(patient, cursor, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {PatchedRecordingRequest} [patchedRecordingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recordingMarkAsSeenPartialUpdate(
      id: number,
      patient: number,
      patchedRecordingRequest?: PatchedRecordingRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recording>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recordingMarkAsSeenPartialUpdate(
        id,
        patient,
        patchedRecordingRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {PatchedRecordingRequest} [patchedRecordingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recordingPartialUpdate(
      id: number,
      patient: number,
      patchedRecordingRequest?: PatchedRecordingRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recording>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recordingPartialUpdate(
        id,
        patient,
        patchedRecordingRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recordingRetrieve(
      id: number,
      patient: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recording>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recordingRetrieve(id, patient, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recordingSignalRetrieve(
      id: number,
      patient: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Signal>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recordingSignalRetrieve(id, patient, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {RecordingRequest} recordingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recordingUpdate(
      id: number,
      patient: number,
      recordingRequest: RecordingRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recording>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recordingUpdate(id, patient, recordingRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} patient
     * @param {any} inputfile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recordingUploadCreate(
      patient: number,
      inputfile: any,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recording>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recordingUploadCreate(patient, inputfile, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this patient.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Patient>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.retrieve(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PatientApi - factory interface
 * @export
 */
export const PatientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PatientApiFp(configuration);
  return {
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} targetId
     * @param {PatientCommentRequest} [patientCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentCreate(
      targetId: number,
      patientCommentRequest?: PatientCommentRequest,
      options?: any,
    ): AxiosPromise<PatientComment> {
      return localVarFp
        .commentCreate(targetId, patientCommentRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentDestroy(id: number, targetId: number, options?: any): AxiosPromise<void> {
      return localVarFp.commentDestroy(id, targetId, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} targetId
     * @param {string} [cursor] The pagination cursor value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentList(targetId: number, cursor?: string, options?: any): AxiosPromise<PaginatedPatientCommentList> {
      return localVarFp.commentList(targetId, cursor, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {PatchedPatientCommentRequest} [patchedPatientCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentPartialUpdate(
      id: number,
      targetId: number,
      patchedPatientCommentRequest?: PatchedPatientCommentRequest,
      options?: any,
    ): AxiosPromise<PatientComment> {
      return localVarFp
        .commentPartialUpdate(id, targetId, patchedPatientCommentRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentRetrieve(id: number, targetId: number, options?: any): AxiosPromise<PatientComment> {
      return localVarFp.commentRetrieve(id, targetId, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this comment.
     * @param {number} targetId
     * @param {PatientCommentRequest} [patientCommentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentUpdate(
      id: number,
      targetId: number,
      patientCommentRequest?: PatientCommentRequest,
      options?: any,
    ): AxiosPromise<PatientComment> {
      return localVarFp
        .commentUpdate(id, targetId, patientCommentRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(limit?: number, offset?: number, options?: any): AxiosPromise<PaginatedPatientList> {
      return localVarFp.list(limit, offset, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} chunk
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingChunkRetrieve(chunk: number, id: number, patient: number, options?: any): AxiosPromise<SignalChunk> {
      return localVarFp.recordingChunkRetrieve(chunk, id, patient, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} patient
     * @param {RecordingRequest} recordingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingCreate(patient: number, recordingRequest: RecordingRequest, options?: any): AxiosPromise<Recording> {
      return localVarFp.recordingCreate(patient, recordingRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingDestroy(id: number, patient: number, options?: any): AxiosPromise<void> {
      return localVarFp.recordingDestroy(id, patient, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} patient
     * @param {string} [cursor] The pagination cursor value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingList(patient: number, cursor?: string, options?: any): AxiosPromise<PaginatedRecordingList> {
      return localVarFp.recordingList(patient, cursor, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {PatchedRecordingRequest} [patchedRecordingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingMarkAsSeenPartialUpdate(
      id: number,
      patient: number,
      patchedRecordingRequest?: PatchedRecordingRequest,
      options?: any,
    ): AxiosPromise<Recording> {
      return localVarFp
        .recordingMarkAsSeenPartialUpdate(id, patient, patchedRecordingRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {PatchedRecordingRequest} [patchedRecordingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingPartialUpdate(
      id: number,
      patient: number,
      patchedRecordingRequest?: PatchedRecordingRequest,
      options?: any,
    ): AxiosPromise<Recording> {
      return localVarFp
        .recordingPartialUpdate(id, patient, patchedRecordingRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingRetrieve(id: number, patient: number, options?: any): AxiosPromise<Recording> {
      return localVarFp.recordingRetrieve(id, patient, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingSignalRetrieve(id: number, patient: number, options?: any): AxiosPromise<Signal> {
      return localVarFp.recordingSignalRetrieve(id, patient, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this recording.
     * @param {number} patient
     * @param {RecordingRequest} recordingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingUpdate(
      id: number,
      patient: number,
      recordingRequest: RecordingRequest,
      options?: any,
    ): AxiosPromise<Recording> {
      return localVarFp
        .recordingUpdate(id, patient, recordingRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} patient
     * @param {any} inputfile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordingUploadCreate(patient: number, inputfile: any, options?: any): AxiosPromise<Recording> {
      return localVarFp.recordingUploadCreate(patient, inputfile, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this patient.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieve(id: number, options?: any): AxiosPromise<Patient> {
      return localVarFp.retrieve(id, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * PatientApi - interface
 * @export
 * @interface PatientApi
 */
export interface PatientApiInterface {
  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} targetId
   * @param {PatientCommentRequest} [patientCommentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  commentCreate(
    targetId: number,
    patientCommentRequest?: PatientCommentRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<PatientComment>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  commentDestroy(id: number, targetId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} targetId
   * @param {string} [cursor] The pagination cursor value.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  commentList(
    targetId: number,
    cursor?: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<PaginatedPatientCommentList>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {PatchedPatientCommentRequest} [patchedPatientCommentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  commentPartialUpdate(
    id: number,
    targetId: number,
    patchedPatientCommentRequest?: PatchedPatientCommentRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<PatientComment>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  commentRetrieve(id: number, targetId: number, options?: AxiosRequestConfig): AxiosPromise<PatientComment>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {PatientCommentRequest} [patientCommentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  commentUpdate(
    id: number,
    targetId: number,
    patientCommentRequest?: PatientCommentRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<PatientComment>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} [limit] Number of results to return per page.
   * @param {number} [offset] The initial index from which to return the results.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  list(limit?: number, offset?: number, options?: AxiosRequestConfig): AxiosPromise<PaginatedPatientList>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} chunk
   * @param {number} id A unique integer value identifying this recording.
   * @param {number} patient
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  recordingChunkRetrieve(
    chunk: number,
    id: number,
    patient: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<SignalChunk>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} patient
   * @param {RecordingRequest} recordingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  recordingCreate(
    patient: number,
    recordingRequest: RecordingRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Recording>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {number} patient
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  recordingDestroy(id: number, patient: number, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} patient
   * @param {string} [cursor] The pagination cursor value.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  recordingList(patient: number, cursor?: string, options?: AxiosRequestConfig): AxiosPromise<PaginatedRecordingList>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {number} patient
   * @param {PatchedRecordingRequest} [patchedRecordingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  recordingMarkAsSeenPartialUpdate(
    id: number,
    patient: number,
    patchedRecordingRequest?: PatchedRecordingRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Recording>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {number} patient
   * @param {PatchedRecordingRequest} [patchedRecordingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  recordingPartialUpdate(
    id: number,
    patient: number,
    patchedRecordingRequest?: PatchedRecordingRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Recording>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {number} patient
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  recordingRetrieve(id: number, patient: number, options?: AxiosRequestConfig): AxiosPromise<Recording>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {number} patient
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  recordingSignalRetrieve(id: number, patient: number, options?: AxiosRequestConfig): AxiosPromise<Signal>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {number} patient
   * @param {RecordingRequest} recordingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  recordingUpdate(
    id: number,
    patient: number,
    recordingRequest: RecordingRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Recording>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} patient
   * @param {any} inputfile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  recordingUploadCreate(patient: number, inputfile: any, options?: AxiosRequestConfig): AxiosPromise<Recording>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this patient.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApiInterface
   */
  retrieve(id: number, options?: AxiosRequestConfig): AxiosPromise<Patient>;
}

/**
 * PatientApi - object-oriented interface
 * @export
 * @class PatientApi
 * @extends {BaseAPI}
 */
export class PatientApi extends BaseAPI implements PatientApiInterface {
  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} targetId
   * @param {PatientCommentRequest} [patientCommentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public commentCreate(targetId: number, patientCommentRequest?: PatientCommentRequest, options?: AxiosRequestConfig) {
    return PatientApiFp(this.configuration)
      .commentCreate(targetId, patientCommentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public commentDestroy(id: number, targetId: number, options?: AxiosRequestConfig) {
    return PatientApiFp(this.configuration)
      .commentDestroy(id, targetId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} targetId
   * @param {string} [cursor] The pagination cursor value.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public commentList(targetId: number, cursor?: string, options?: AxiosRequestConfig) {
    return PatientApiFp(this.configuration)
      .commentList(targetId, cursor, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {PatchedPatientCommentRequest} [patchedPatientCommentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public commentPartialUpdate(
    id: number,
    targetId: number,
    patchedPatientCommentRequest?: PatchedPatientCommentRequest,
    options?: AxiosRequestConfig,
  ) {
    return PatientApiFp(this.configuration)
      .commentPartialUpdate(id, targetId, patchedPatientCommentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public commentRetrieve(id: number, targetId: number, options?: AxiosRequestConfig) {
    return PatientApiFp(this.configuration)
      .commentRetrieve(id, targetId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this comment.
   * @param {number} targetId
   * @param {PatientCommentRequest} [patientCommentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public commentUpdate(
    id: number,
    targetId: number,
    patientCommentRequest?: PatientCommentRequest,
    options?: AxiosRequestConfig,
  ) {
    return PatientApiFp(this.configuration)
      .commentUpdate(id, targetId, patientCommentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} [limit] Number of results to return per page.
   * @param {number} [offset] The initial index from which to return the results.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public list(limit?: number, offset?: number, options?: AxiosRequestConfig) {
    return PatientApiFp(this.configuration)
      .list(limit, offset, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} chunk
   * @param {number} id A unique integer value identifying this recording.
   * @param {number} patient
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public recordingChunkRetrieve(chunk: number, id: number, patient: number, options?: AxiosRequestConfig) {
    return PatientApiFp(this.configuration)
      .recordingChunkRetrieve(chunk, id, patient, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} patient
   * @param {RecordingRequest} recordingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public recordingCreate(patient: number, recordingRequest: RecordingRequest, options?: AxiosRequestConfig) {
    return PatientApiFp(this.configuration)
      .recordingCreate(patient, recordingRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {number} patient
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public recordingDestroy(id: number, patient: number, options?: AxiosRequestConfig) {
    return PatientApiFp(this.configuration)
      .recordingDestroy(id, patient, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} patient
   * @param {string} [cursor] The pagination cursor value.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public recordingList(patient: number, cursor?: string, options?: AxiosRequestConfig) {
    return PatientApiFp(this.configuration)
      .recordingList(patient, cursor, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {number} patient
   * @param {PatchedRecordingRequest} [patchedRecordingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public recordingMarkAsSeenPartialUpdate(
    id: number,
    patient: number,
    patchedRecordingRequest?: PatchedRecordingRequest,
    options?: AxiosRequestConfig,
  ) {
    return PatientApiFp(this.configuration)
      .recordingMarkAsSeenPartialUpdate(id, patient, patchedRecordingRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {number} patient
   * @param {PatchedRecordingRequest} [patchedRecordingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public recordingPartialUpdate(
    id: number,
    patient: number,
    patchedRecordingRequest?: PatchedRecordingRequest,
    options?: AxiosRequestConfig,
  ) {
    return PatientApiFp(this.configuration)
      .recordingPartialUpdate(id, patient, patchedRecordingRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {number} patient
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public recordingRetrieve(id: number, patient: number, options?: AxiosRequestConfig) {
    return PatientApiFp(this.configuration)
      .recordingRetrieve(id, patient, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {number} patient
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public recordingSignalRetrieve(id: number, patient: number, options?: AxiosRequestConfig) {
    return PatientApiFp(this.configuration)
      .recordingSignalRetrieve(id, patient, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this recording.
   * @param {number} patient
   * @param {RecordingRequest} recordingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public recordingUpdate(
    id: number,
    patient: number,
    recordingRequest: RecordingRequest,
    options?: AxiosRequestConfig,
  ) {
    return PatientApiFp(this.configuration)
      .recordingUpdate(id, patient, recordingRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} patient
   * @param {any} inputfile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public recordingUploadCreate(patient: number, inputfile: any, options?: AxiosRequestConfig) {
    return PatientApiFp(this.configuration)
      .recordingUploadCreate(patient, inputfile, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this patient.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public retrieve(id: number, options?: AxiosRequestConfig) {
    return PatientApiFp(this.configuration)
      .retrieve(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
