import { AnyAction, miniSerializeError, PayloadAction, SerializedError } from '@reduxjs/toolkit';

import { ValidationError } from './client';

/**
 * TODO: Model shall be exported from backend
 * */
export interface GenericErrorErrors {
  /**
   * Error details
   * @type {string}
   * @memberof GenericErrorErrors
   */
  detail?: string;
  /**
   * Error code
   * @type {string}
   * @memberof GenericErrorErrors
   */
  code?: string;
}

/**
 * TODO: Model shall be exported from backend
 */
interface GenericError {
  errors?: GenericErrorErrors;
}

/**
 * TODO: Model shall be exported from backend
 */
interface APIException {
  errors?: GenericErrorErrors;
}

export interface ErrorOption {
  text: string;
  icon?: string;
  callback?(): AnyAction;
}
export type DrfError = ValidationError | GenericError | APIException;
export interface ApiError extends SerializedError {
  detail?: DrfError;
  options?: ErrorOption;
}

export const isGenericError = (error?: DrfError): error is GenericError => {
  return !!(error && 'errors' in error);
};
export const isValidationError = (error?: DrfError): error is ValidationError => {
  return !isGenericError(error);
};

export type RejectedPayloadAction<P = void, E = SerializedError> = PayloadAction<P | undefined, string, never, E> & {
  error: E;
};

/**
 * Serializes Exceptions raised in Thunks.
 * Axios throws Errors for non 2XX error codes by default, so DRF Error responses will arrive here.
 * @param value raised error
 */
// eslint-disable-next-line
export const serializeError = (value: any): ApiError => {
  const error: Partial<ApiError> = miniSerializeError(value);
  if (value.response) {
    if (value.response.status) {
      error.code = value.response.status.toString();
    }
    if (value.response.data) {
      const keys = Object.keys(value.response.data);
      if (keys && keys.length > 0 && value.response.data[keys[0]].length > 0) {
        error.detail = value.response.data[keys[0]];
      } else if (keys && keys.length > 0 && value.response.data[keys[0]]) {
        error.detail = value.response.data[keys[0]];
        const innerKeys = Object.keys(value.response.data[keys[0]]);
        if (Object.keys(value.response.data[keys[0]][innerKeys[0]])) {
          error.detail = value.response.data[keys[0]][innerKeys[0]];
        }
      }
    }
  }
  return error;
};
