import { Services as AuthServices } from '@24sens/ecg01-auth';
import { Services as CommentsServices } from '@24sens/ecg01-comments';
import { Services as InviteServices } from '@24sens/ecg01-invite';
import { Services as RecordingsServices } from '@24sens/ecg01-recordings';
import * as rest from '@24sens/ecg01-rest-client';
import { PatientApi, PhysicianApi } from '@24sens/ecg01-rest-client';

import { Services as ActionServices } from '../../../../packages/ecg01-notifications';

export interface Services extends ActionServices, InviteServices, AuthServices, RecordingsServices, CommentsServices {
  physician: PhysicianApi;
  patient: PatientApi;
}

export const createApi = (getApiKey: () => string = rest.getToken): Services => {
  const apiConfig = new rest.Configuration({
    basePath: process.env.REACT_APP_BACKEND_URL,
    apiKey: getApiKey,
  });

  const auth = new rest.AuthApi(apiConfig);
  const patient = new rest.PatientApi(apiConfig);
  const physician = new rest.PhysicianApi(apiConfig);
  const recording = new rest.RecordingApi(apiConfig);
  const invite = new rest.InviteApi(apiConfig);
  const profile = new rest.ProfileApi(apiConfig);
  const notification = new rest.NotificationApi(apiConfig);

  return { auth, patient, recording, invite, physician, profile, notification };
};
