/* tslint:disable */
/* eslint-disable */
/**
 * ECG Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0-unknown
 * Contact:
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const InviteStatusEnum = {
  Queued: 'queued',
  DeliveryFailed: 'delivery_failed',
  Accepted: 'accepted',
  Retracted: 'retracted',
  Rejected: 'rejected',
} as const;

export type InviteStatusEnum = typeof InviteStatusEnum[keyof typeof InviteStatusEnum];
