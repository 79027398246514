export function millisToTime(milliseconds: number | null): string {
  milliseconds = (milliseconds ?? 0) * 1000;
  return new Date(milliseconds * 1000).toISOString().substr(11, 8);
}

export function secondsToTime(seconds: number | null): string {
  if (!seconds) return '0';
  const date = new Date(seconds * 1000);
  const days = Math.floor(seconds / 3600 / 24);
  return days > 0 ? days + ' ' + date.toISOString().substr(11, 8) : date.toISOString().substr(11, 8);
}

export function dateStringToFormattedDateString(dateString?: string | null): string {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleDateString('de-AT', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

export function dateStringToFormattedDateTimeString(dateString?: string | null): string {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleDateString('de-AT', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export function dateStringToFormattedTimeString(dateString?: string): string {
  if (!dateString) return '';
  const date = new Date(dateString);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);
  return hours + ':' + minutes + ':' + seconds;
}

export function formatDateForBackend(date: Date | null, noTime = false): string {
  if (noTime) return (date ?? new Date()).toISOString().substr(0, 10);
  else return (date ?? new Date()).toISOString();
}
