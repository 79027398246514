import React, { useEffect, useState } from 'react';

import * as selectors from '../store/selectors';
import { useAppSelector } from '../store/store';

interface ITitleContext {
  title: string;
  setTitle: (title: string) => void;
  isDrawerOpen: boolean | null;
  setDrawerOpen: (isOpen: boolean | null) => void;
  toggleDrawerOpen: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const LayoutContext = React.createContext<ITitleContext>({
  title: 'corCONTROL-Web-App',
  setTitle: (title) => {
    /**/
  },
  isDrawerOpen: true,
  setDrawerOpen: (open) => {
    /**/
  },
  toggleDrawerOpen: () => {
    /**/
  },
});
export const useLayout = () => React.useContext(LayoutContext);

export const LayoutProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const [title, setTitle] = useState('corCONTROL-Web-App');
  const [isDrawerOpen, setDrawerOpen] = useState<boolean | null>(true);
  const isPhysician = useAppSelector(selectors.auth.isPhysician);
  const isVerified = useAppSelector(selectors.auth.isVerified);
  const isAuthenticated = useAppSelector(selectors.auth.isAuthenticated);

  useEffect(() => {
    setDrawerOpen((isPhysician && isVerified) || null);
  }, [isPhysician, isVerified]);

  useEffect(() => {
    setTitle('corCONTROL-Web-App');
  }, [isAuthenticated]);

  return (
    <LayoutContext.Provider
      value={{ title, setTitle, isDrawerOpen, setDrawerOpen, toggleDrawerOpen: () => setDrawerOpen(!isDrawerOpen) }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
