import {
  ApiError,
  deleteToken,
  Invite,
  InviteApi,
  InviteRequest,
  InviteStatusEnum,
  RejectedPayloadAction,
  serializeError,
} from '@24sens/ecg01-rest-client';
import { wrap } from '@24sens/utils';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

export interface Services {
  invite: InviteApi;
}

export interface InviteState extends EntityState<Invite> {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: ApiError | null;
  uuid: string | null;
  invite: Invite | null;
  filterText: string;
}

const storeInviteUUid = (uuid: string | null): string | null => {
  try {
    if (uuid) {
      localStorage.setItem('invite_uuid', uuid);
      console.log('Stored invite ', uuid);
    }
    return uuid;
  } catch (err) {
    console.error('Failed to store invite uuid');
  }
  return null;
};

const clearInviteUuid = () => {
  try {
    localStorage.removeItem('invite_uuid');
  } catch (err) {
    console.error('Failed to remove invite uuid');
  }
};

export const readInviteUUid = (): string | null => {
  try {
    return localStorage.getItem('invite_uuid');
  } catch (err) {
    console.error('Failed to store invite uuid');
  }
  return null;
};

export const adapter = createEntityAdapter<Invite>({
  selectId: (invite) => invite.uuid,
  sortComparer: (a, b) => a.created?.localeCompare(b.created ?? '') ?? -1,
});

export const initialInviteState: InviteState = adapter.getInitialState({
  loading: 'idle',
  error: null,
  uuid: storeInviteUUid(new URLSearchParams(window.location.search).get('invite')),
  invite: null,
  filterText: '',
});

export const acceptInvite = createAsyncThunk<Invite, string, { rejectValue: ApiError; extra: Services }>(
  'ecg01-invite/accept',
  async (uuid, thunkApi) => {
    const response = await thunkApi.extra.invite.accept(uuid);
    return response.data;
  },
  { serializeError },
);

export const rejectInvite = createAsyncThunk<Invite, string, { rejectValue: ApiError; extra: Services }>(
  'ecg01-invite/reject',
  async (uuid, thunkApi) => {
    const response = await thunkApi.extra.invite.reject(uuid);
    return response.data;
  },
  { serializeError },
);

export const createInvite = createAsyncThunk<Invite, InviteRequest, { rejectValue: ApiError; extra: Services }>(
  'ecg01-invite/create',
  async (invite, thunkApi) => {
    const response = await thunkApi.extra.invite.create(invite);
    return response.data;
  },
  { serializeError },
);

export const fetchInvite = createAsyncThunk<Invite, string, { rejectValue: ApiError; extra: Services }>(
  'ecg01-invite/read',
  async (uuid, thunkApi) => {
    const response = await thunkApi.extra.invite.retrieve(uuid);
    return response.data;
  },
  { serializeError },
);

export const listInvites = createAsyncThunk<Invite[], void, { rejectValue: ApiError; extra: Services }>(
  'ecg01-invite/list',
  async (_, thunkApi) => {
    const response = await thunkApi.extra.invite.list();
    return response.data.results ?? [];
  },
  { serializeError },
);

export const buildSlice = <R>(
  sliceSelector: (state: R) => InviteState,
  initialState: InviteState = initialInviteState,
) => {
  const setPending = (state: InviteState) => {
    state.loading = 'pending';
  };

  const onRejected = (state: InviteState, action: RejectedPayloadAction<ApiError, ApiError>) => {
    state.loading = 'failed';
    const error = action.payload ?? action.error;
    if (error.code === '401') {
      deleteToken();
    }
    state.error = {
      ...error,
      options: { text: error.options?.text ?? '', callback: slice.actions.resetError },
    };
  };

  const setFulfilled = (state: InviteState, action: PayloadAction<Invite>) => {
    state.loading = 'succeeded';
    state.invite = action.payload;
    adapter.upsertOne(state, action.payload);
  };

  const setFulfilledMany = (state: InviteState, action: PayloadAction<Invite[]>) => {
    state.loading = 'succeeded';
    adapter.upsertMany(state, action.payload);
  };

  const setFulfilledClearInvite = (state: InviteState, action: PayloadAction<Invite>) => {
    state.loading = 'succeeded';
    state.uuid = null;
    state.invite = null;
  };

  const slice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
      resetError: (state) => {
        state.error = null;
      },
      setUuid: (state, action: PayloadAction<InviteState['uuid']>) => {
        state.uuid = action.payload;
      },
      clearInvite: (state, action: PayloadAction<void>) => {
        state.invite = null;
        clearInviteUuid();
      },
      filter: (state, action: PayloadAction<string>) => {
        state.filterText = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(acceptInvite.pending, setPending);
      builder.addCase(acceptInvite.fulfilled, setFulfilledClearInvite);
      builder.addCase(acceptInvite.rejected, onRejected);

      builder.addCase(rejectInvite.pending, setPending);
      builder.addCase(rejectInvite.fulfilled, setFulfilledClearInvite);
      builder.addCase(rejectInvite.rejected, onRejected);

      builder.addCase(createInvite.pending, setPending);
      builder.addCase(createInvite.fulfilled, setFulfilled);
      builder.addCase(createInvite.rejected, onRejected);

      builder.addCase(fetchInvite.pending, setPending);
      builder.addCase(fetchInvite.fulfilled, setFulfilled);
      builder.addCase(fetchInvite.rejected, onRejected);

      builder.addCase(listInvites.pending, setPending);
      builder.addCase(listInvites.fulfilled, setFulfilledMany);
      builder.addCase(listInvites.rejected, onRejected);
    },
  });

  const selectors = adapter.getSelectors(sliceSelector);

  const localSelectors = {
    invite: (state: InviteState) => state.invite,
    status: (state: InviteState) => state.invite?.status,
    waiting: (state: InviteState) => state.invite?.status === InviteStatusEnum.Queued,
    loading: (state: InviteState) => state.loading,
    uuid: (state: InviteState) => state.uuid,
  };

  const pendingInvites = createSelector([selectors.selectAll], (invites) =>
    invites.filter((i) => i.status !== InviteStatusEnum.Accepted),
  );

  const filterText = (state: R) => sliceSelector(state).filterText;

  const filteredInvites = createSelector([pendingInvites, filterText], (invites, filterText) =>
    (filterText ? invites.filter((i) => i?.recipient_email.includes(filterText)) : invites).map((i) => i.uuid),
  );

  const globalSelectors = {
    invite: wrap(sliceSelector, localSelectors.invite),
    status: wrap(sliceSelector, localSelectors.status),
    waiting: wrap(sliceSelector, localSelectors.waiting),
    loading: wrap(sliceSelector, localSelectors.loading),
    uuid: wrap(sliceSelector, localSelectors.uuid),
  };

  return {
    ...slice,
    selectors: { ...selectors, ...globalSelectors, pendingInvites, filteredInvites },
  } as const;
};
