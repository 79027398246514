import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { DialogProps } from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import packageJson from '../../package.json';
import CE from '../resources/images/CE_sign.png';
import Manufacturer from '../resources/images/Hersteller.png';
import MedicalDevice from '../resources/images/MedicalDevice.png';

interface AboutDialogProps extends DialogProps {
  handleClose(): void;
}

export const AboutDialog: React.FC<AboutDialogProps> = ({ handleClose, ...props }) => {
  const { t } = useTranslation();
  const [versionBackend, setVersionBackend] = useState('');
  const [udiBackend, setUdiBackend] = useState('');

  async function fetchBackendVersion() {
    const response = await fetch('https://app.production.24sens.at/__version__');
    const jsonResponse = await response.json();
    return jsonResponse;
  }

  useEffect(() => {
    fetchBackendVersion().then((value) => {
      setVersionBackend(value.version);
      setUdiBackend(value.udi);
    });
  }, []);

  return (
    <Dialog {...props}>
      <DialogTitle>{t('about.title')}</DialogTitle>
      <DialogContent>
        <Stack direction={'row'} alignItems="center">
          <img src={Manufacturer} alt={'manufacturer'} style={{ width: '60px' }} />
          <span style={{ fontWeight: 'bold', whiteSpace: 'pre-line' }}>
            {'© 24sens GmbH\n Millennium Park 15\n 6890 Lustenau, Austria'}
          </span>
        </Stack>
        <Divider />
        <Stack direction={'row'} alignItems="center">
          <img src={MedicalDevice} alt={'medical device icon'} style={{ width: '60px' }} />
          <img src={CE} alt={'CE marking'} style={{ width: '60px' }} />
          <div style={{ fontSize: '22px', fontWeight: 'bold' }}>0297</div>
        </Stack>
        <Divider />
        <DialogContentText>
          <p>corCONTROL-WebApp</p>
          <p>{t('about.version') + ': v' + packageJson.version}</p>
          <p>UDI: {process.env.REACT_APP_UDI}</p>
        </DialogContentText>
        <Divider />
        <DialogContentText>
          <p>corCONTROL-Backend</p>
          <p>{t('about.version') + ': ' + versionBackend}</p>
          <p>UDI: {udiBackend}</p>
        </DialogContentText>
        <Divider />
        <DialogContentText component="a" href={t('links.homepage24sens')} target="_blank" style={{ color: '#0000EE' }}>
          <p>{t('about.manufacturer.name')}</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('buttons.ok')}</Button>
      </DialogActions>
    </Dialog>
  );
};
