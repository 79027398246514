import {
  ApiError,
  PatientApi,
  Recording,
  RecordingApi,
  RecordingRequest,
  RejectedPayloadAction,
  serializeError,
} from '@24sens/ecg01-rest-client';
import { HasId } from '@24sens/utils';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Draft,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

export type IRecording = HasId<Recording> & { patient_id: number };

export const hasId = (recording: Recording | IRecording) => (recording as IRecording).id !== undefined;

export interface Services {
  patient: PatientApi;
  recording: RecordingApi;
}

export const adapter = createEntityAdapter<IRecording>({
  selectId: (recording) => recording.id,
  sortComparer: (a, b) => b.start_time.localeCompare(a.start_time),
});

export interface RecordingState extends EntityState<IRecording> {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: ApiError | null;
  pending: number[];
}

export const initialRecordingState: RecordingState = adapter.getInitialState({
  loading: 'idle',
  error: null,
  pending: [],
});

export const createRecording = createAsyncThunk<
  IRecording,
  { userId: number; recording: RecordingRequest },
  { rejectValue: ApiError }
>(
  '/recording/',
  async ({ userId, recording }, thunkApi) => {
    const response = await (thunkApi.extra as Services).patient.recordingCreate(userId, recording);
    return response.data as IRecording;
  },
  { serializeError },
);

export const updateRecording = createAsyncThunk<IRecording, IRecording, { rejectValue: ApiError; extra: Services }>(
  '/recording/update',
  async (recording, thunkApi) => {
    const response = await (thunkApi.extra as Services).recording.update(recording.id, recording);
    return response.data as IRecording;
  },
  { serializeError },
);

export const fetchRecordingsOfUser = createAsyncThunk<IRecording[], number, { rejectValue: ApiError; extra: Services }>(
  'recording',
  async (userId, thunkApi) => {
    const response = await thunkApi.extra.patient.recordingList(userId, undefined);
    return response.data.results as IRecording[];
  },
  { serializeError },
);

export const deleteRecordingById = createAsyncThunk<
  number,
  { userId: number; recordingId: number },
  { rejectValue: ApiError; extra: Services }
>(
  '/recording/delete',
  async ({ userId, recordingId }, thunkApi) => {
    await thunkApi.extra.patient.recordingDestroy(recordingId, userId);
    return Number(recordingId);
  },
  { serializeError },
);

export const fetchRecording = createAsyncThunk<IRecording, { id: number }, { rejectValue: ApiError; extra: Services }>(
  '/recording/fetchRecording',
  async ({ id }, thunkApi) => {
    const response = await thunkApi.extra.recording.retrieve(id);
    return response.data;
  },
  { serializeError },
);

export const markRecordingAsSeen = createAsyncThunk<IRecording, IRecording, { rejectValue: ApiError; extra: Services }>(
  '/recording/markAsSeen',
  async (recording, thunkApi) => {
    const response = await thunkApi.extra.recording.markAsSeenPartialUpdate(recording.id, recording);
    return response.data as IRecording;
  },
  { serializeError },
);

export const upload = createAsyncThunk<
  IRecording,
  { userId: number; file: File; progressCallback(value: number): void },
  { rejectValue: ApiError; extra: Services }
>(
  '/recording/upload',
  async ({ userId, file, progressCallback }, thunkApi) => {
    const config = {
      onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
        progressCallback(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      },
    };
    const response = await thunkApi.extra.patient.recordingUploadCreate(userId, file, config);
    return response.data as IRecording;
  },
  { serializeError },
);

export const buildSlice = <R>(
  sliceSelector: (state: R) => RecordingState,
  initialState: RecordingState = initialRecordingState,
) => {
  const setPending = (state: Draft<RecordingState>) => {
    state.loading = 'pending';
  };
  const setFetchPending = (state: Draft<RecordingState>, action: PayloadAction<number>) => {
    state.loading = 'pending';
  };

  const onRecordingsFulfilled = (state: RecordingState, action: PayloadAction<IRecording[]>) => {
    state.loading = 'succeeded';
    adapter.upsertMany(state, action.payload);
  };

  const onRecordingFulfilled = (state: RecordingState, action: PayloadAction<IRecording>) => {
    state.loading = 'succeeded';
    adapter.upsertOne(state, action.payload);
  };

  const onRecordingDeleted = (state: RecordingState, action: PayloadAction<number>) => {
    state.loading = 'succeeded';
    adapter.removeOne(state, action.payload);
  };

  const onGpxUploaded = (state: RecordingState, action: PayloadAction<IRecording>) => {
    state.loading = 'succeeded';
    adapter.upsertOne(state, action.payload);
  };

  const onRejected = (state: RecordingState, action: RejectedPayloadAction<ApiError, ApiError>) => {
    state.loading = 'failed';
    const error = action.payload ?? action.error;
    if (action.payload) {
      state.error = action.payload;
    } else {
      state.error = {
        ...error,
        code: error.code ?? 'UNKNOWN',
        message: action.error.message ?? action.error.name ?? 'Unknown Error',
        options: { text: error.options?.text ?? '', callback: slice.actions.resetError },
      };
    }
  };

  const slice = createSlice({
    name: 'recording',
    initialState: initialState,
    reducers: {
      resetError: (state) => {
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchRecordingsOfUser.pending, setPending);
      builder.addCase(fetchRecordingsOfUser.fulfilled, onRecordingsFulfilled);
      builder.addCase(fetchRecordingsOfUser.rejected, onRejected);

      builder.addCase(createRecording.pending, setPending);
      builder.addCase(createRecording.fulfilled, onRecordingFulfilled);
      builder.addCase(createRecording.rejected, onRejected);

      builder.addCase(updateRecording.pending, setPending);
      builder.addCase(updateRecording.fulfilled, onRecordingFulfilled);
      builder.addCase(updateRecording.rejected, onRejected);

      builder.addCase(upload.pending, setPending);
      builder.addCase(upload.fulfilled, onGpxUploaded);
      builder.addCase(upload.rejected, onRejected);

      builder.addCase(markRecordingAsSeen.pending, setPending);
      builder.addCase(markRecordingAsSeen.fulfilled, onRecordingFulfilled);
      builder.addCase(markRecordingAsSeen.rejected, onRejected);

      builder.addCase(fetchRecording.pending, setPending);
      builder.addCase(fetchRecording.fulfilled, onRecordingFulfilled);
      builder.addCase(fetchRecording.rejected, onRejected);

      builder.addCase(deleteRecordingById.pending, setPending);
      builder.addCase(deleteRecordingById.fulfilled, onRecordingDeleted);
      builder.addCase(deleteRecordingById.rejected, onRejected);
    },
  });

  const recordingSelector = adapter.getSelectors(sliceSelector);
  const recordings = recordingSelector.selectAll;
  const getId = (state: R, id: number) => id;

  const recordingsOfPatient = createSelector([recordings, getId], (recordings, id) => {
    return recordings.filter((r) => r.patient_id === id);
  });
  const recordingIdsOfPatient = createSelector(recordingsOfPatient, (recordings) => recordings.map((r) => r.id));
  const createRecordingsForDeviceFactory = (deviceId: string | undefined) =>
    createSelector(recordings, (recordings: IRecording[]) =>
      recordings.filter((r) => r.device_mac && r.device_mac === deviceId),
    );

  const currentRecordingSelectorFactory = (deviceId: string | undefined, fileName: string | null | undefined) => {
    const recordingsForDevice = createRecordingsForDeviceFactory(deviceId);
    return createSelector(recordingsForDevice, (recordings: IRecording[]) =>
      recordings.find((r) => fileName === r.obu_filename),
    );
  };

  return {
    ...slice,
    selectors: {
      ...recordingSelector,
      recordings,
      recordingById: recordingSelector.selectById,
      recordingsOfPatient,
      recordingIdsOfPatient,
      createRecordingsForDeviceFactory,
      currentRecordingSelectorFactory,
    },
  } as const;
};
