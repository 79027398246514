import { IComment } from '@24sens/ecg01-comments';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import React from 'react';

export interface VisibilityIconProps {
  comment: IComment;
}
const iconstyle = { verticalAlign: 'middle', marginLeft: '12px' };
export const VisibilityIcon: React.FC<VisibilityIconProps> = ({ comment, ...props }) => {
  switch (comment.visibility) {
    case 'patient':
      return <PeopleIcon style={iconstyle} />;
    case 'private':
      return <PersonIcon style={iconstyle} />;
    case 'public':
      return <GroupsIcon style={iconstyle} />;
    default:
      return null;
  }
};
