import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import i18n from './i18next';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

(() => {
  root.render(<App />);
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

declare global {
  interface Window {
    throwOnMissingTranslation?: boolean;
    store: unknown;
    i18next: typeof i18n;
  }
}
