import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { EntityId } from '@reduxjs/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import * as selectors from '../store/selectors';
import { useAppSelector } from '../store/store';

export interface PatientListItemProps {
  patientId: EntityId;
  to: string;
}

export const PatientListItem: React.FC<PatientListItemProps> = ({ patientId, to }) => {
  const patient = useAppSelector((state) => selectors.patient.selectById(state, patientId));
  const locationPatientId = useLocation().pathname.match('patients\\/(\\d+)');
  const isSelected = locationPatientId ? locationPatientId[1] === patientId.toString() : false;
  return (
    <ListItemButton
      component={RouterLink}
      to={to}
      style={{ background: isSelected ? 'var(--twentyfoursens-blue-shade)' : undefined }}
    >
      <Avatar sx={{ bgcolor: 'var(--twentyfoursens-blue)' }}>{patient?.name?.charAt(0)}</Avatar>
      <ListItemText
        data-testid={'patientlist-item-text_' + patient?.id}
        sx={{ marginLeft: '12px' }}
        primary={patient?.name}
        secondary={patient?.profile?.social_security_number || patient?.email}
      />
    </ListItemButton>
  );
};

export interface InviteListItemProps {
  inviteUuid: EntityId;
}

export const InviteListItem: React.FC<InviteListItemProps> = ({ inviteUuid }) => {
  const { t } = useTranslation();
  const invite = useAppSelector((state) => selectors.invite.selectById(state, inviteUuid));
  return (
    <ListItemButton>
      <ListItemAvatar>
        {<Avatar alt={invite?.recipient_email}>{invite?.recipient_email.slice(0, 1)}</Avatar>}
      </ListItemAvatar>
      <ListItemText
        data-testid={'patientlist-item-text_' + invite?.uuid}
        primary={invite?.recipient_email}
        secondary={t(`invite.${invite?.status}`)}
      />
    </ListItemButton>
  );
};
