/* tslint:disable */
/* eslint-disable */
/**
 * ECG Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0-unknown
 * Contact:
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForbiddenError } from '../models';
// @ts-ignore
import { NotFoundError } from '../models';
// @ts-ignore
import { Notification } from '../models';
// @ts-ignore
import { PaginatedNotificationList } from '../models';
// @ts-ignore
import { UnauthenticatedError } from '../models';
// @ts-ignore
import { ValidationError } from '../models';
/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [cursor] The pagination cursor value.
     * @param {'error' | 'info' | 'success' | 'warning'} [level]
     * @param {string} [newer]
     * @param {string} [timestamp]
     * @param {boolean} [unread]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list: async (
      cursor?: string,
      level?: 'error' | 'info' | 'success' | 'warning',
      newer?: string,
      timestamp?: string,
      unread?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/notification/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor;
      }

      if (level !== undefined) {
        localVarQueryParameter['level'] = level;
      }

      if (newer !== undefined) {
        localVarQueryParameter['newer'] = (newer as any) instanceof Date ? (newer as any).toISOString() : newer;
      }

      if (timestamp !== undefined) {
        localVarQueryParameter['timestamp'] =
          (timestamp as any) instanceof Date ? (timestamp as any).toISOString() : timestamp;
      }

      if (unread !== undefined) {
        localVarQueryParameter['unread'] = unread;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markAllAsReadCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/notification/mark_all_as_read/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id A unique integer value identifying this notification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markAsReadCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('markAsReadCreate', 'id', id);
      const localVarPath = `/notification/{id}/mark_as_read/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id A unique integer value identifying this notification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('retrieve', 'id', id);
      const localVarPath = `/notification/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [cursor] The pagination cursor value.
     * @param {'error' | 'info' | 'success' | 'warning'} [level]
     * @param {string} [newer]
     * @param {string} [timestamp]
     * @param {boolean} [unread]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async list(
      cursor?: string,
      level?: 'error' | 'info' | 'success' | 'warning',
      newer?: string,
      timestamp?: string,
      unread?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedNotificationList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.list(cursor, level, newer, timestamp, unread, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markAllAsReadCreate(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markAllAsReadCreate(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id A unique integer value identifying this notification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markAsReadCreate(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markAsReadCreate(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id A unique integer value identifying this notification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.retrieve(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NotificationApiFp(configuration);
  return {
    /**
     *
     * @param {string} [cursor] The pagination cursor value.
     * @param {'error' | 'info' | 'success' | 'warning'} [level]
     * @param {string} [newer]
     * @param {string} [timestamp]
     * @param {boolean} [unread]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(
      cursor?: string,
      level?: 'error' | 'info' | 'success' | 'warning',
      newer?: string,
      timestamp?: string,
      unread?: boolean,
      options?: any,
    ): AxiosPromise<PaginatedNotificationList> {
      return localVarFp
        .list(cursor, level, newer, timestamp, unread, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markAllAsReadCreate(options?: any): AxiosPromise<void> {
      return localVarFp.markAllAsReadCreate(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id A unique integer value identifying this notification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markAsReadCreate(id: number, options?: any): AxiosPromise<Notification> {
      return localVarFp.markAsReadCreate(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id A unique integer value identifying this notification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieve(id: number, options?: any): AxiosPromise<Notification> {
      return localVarFp.retrieve(id, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * NotificationApi - interface
 * @export
 * @interface NotificationApi
 */
export interface NotificationApiInterface {
  /**
   *
   * @param {string} [cursor] The pagination cursor value.
   * @param {'error' | 'info' | 'success' | 'warning'} [level]
   * @param {string} [newer]
   * @param {string} [timestamp]
   * @param {boolean} [unread]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApiInterface
   */
  list(
    cursor?: string,
    level?: 'error' | 'info' | 'success' | 'warning',
    newer?: string,
    timestamp?: string,
    unread?: boolean,
    options?: AxiosRequestConfig,
  ): AxiosPromise<PaginatedNotificationList>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApiInterface
   */
  markAllAsReadCreate(options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {number} id A unique integer value identifying this notification.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApiInterface
   */
  markAsReadCreate(id: number, options?: AxiosRequestConfig): AxiosPromise<Notification>;

  /**
   *
   * @param {number} id A unique integer value identifying this notification.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApiInterface
   */
  retrieve(id: number, options?: AxiosRequestConfig): AxiosPromise<Notification>;
}

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI implements NotificationApiInterface {
  /**
   *
   * @param {string} [cursor] The pagination cursor value.
   * @param {'error' | 'info' | 'success' | 'warning'} [level]
   * @param {string} [newer]
   * @param {string} [timestamp]
   * @param {boolean} [unread]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public list(
    cursor?: string,
    level?: 'error' | 'info' | 'success' | 'warning',
    newer?: string,
    timestamp?: string,
    unread?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return NotificationApiFp(this.configuration)
      .list(cursor, level, newer, timestamp, unread, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public markAllAsReadCreate(options?: AxiosRequestConfig) {
    return NotificationApiFp(this.configuration)
      .markAllAsReadCreate(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id A unique integer value identifying this notification.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public markAsReadCreate(id: number, options?: AxiosRequestConfig) {
    return NotificationApiFp(this.configuration)
      .markAsReadCreate(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id A unique integer value identifying this notification.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public retrieve(id: number, options?: AxiosRequestConfig) {
    return NotificationApiFp(this.configuration)
      .retrieve(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
