import { resetPassword } from '@24sens/ecg01-auth';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../store/store';

export const PasswordResetDialog: React.FC<{ open: boolean; handleClose(): void }> = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle> {t('passwordResetDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('passwordResetDialog.text')}</DialogContentText>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('buttons.cancel')}</Button>
        <Button
          variant={'contained'}
          onClick={() => {
            dispatch(resetPassword({ email })).then((e) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (e?.error) {
                enqueueSnackbar(t('passwordResetDialog.snackbarError'), { variant: 'error' });
              } else {
                enqueueSnackbar(t('passwordResetDialog.snackbar'), { variant: 'success' });
                handleClose();
              }
            });
          }}
        >
          {t('buttons.send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
