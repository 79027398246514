import { IRecording } from '@24sens/ecg01-recordings';
import { TileComponent } from '@24sens/ui';
import BarChartIcon from '@mui/icons-material/BarChart';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RecordingSummary } from '../recording/RecordingSummary';
import { CardAvatar } from './CardAvatar';
import { DetailsButton } from './PlotCardActions';

export interface PlotCardProps {
  entry: IRecording;
}

export const PlotCard: React.FC<PlotCardProps> = ({ entry, ...props }) => {
  const { t } = useTranslation();

  return (
    <TileComponent
      testId={'PlotCardHeader_' + entry.id}
      image={<CardAvatar icon={<BarChartIcon />} />}
      primaryText={t('timeline.plotCard.title', entry)}
      secondaryText={t('timeline.plotCard.subtitle', {
        start_time: new Date(entry.start_time),
        end_time: entry.end_time ? new Date(entry.end_time) : '',
      })}
      headerAction={<DetailsButton entry={entry} />}
    >
      {entry.outputfile ? (
        <RecordingSummary recordingId={entry.id} />
      ) : (
        <Typography variant={'h5'}>{t('timeline.plotCard.dataMissing')}</Typography>
      )}
    </TileComponent>
  );
};
