import { all, call, spawn } from 'typed-redux-saga';

import { Services } from './store';

export function* rootSaga(services: Services) {
  const sagas = [] as const;

  yield* all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield* call(saga, services);
            break;
          } catch (e) {
            console.error('Saga failed: ', e);
          }
        }
      }),
    ),
  );
}
