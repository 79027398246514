import {
  ApiError,
  Notification,
  NotificationApi,
  RejectedPayloadAction,
  serializeError,
} from '@24sens/ecg01-rest-client';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Draft,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

export interface Services {
  notification: NotificationApi;
}

export interface NotificationState extends EntityState<Notification> {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: ApiError | null;
}

export const adapter = createEntityAdapter<Notification>({
  selectId: (notification) => notification.id,
  sortComparer: (a, b) => a.timestamp?.localeCompare(b.timestamp ?? '') ?? -1,
});

export const initialNotificationState: NotificationState = adapter.getInitialState({
  loading: 'idle',
  error: null,
});

export const fetchNotifications = createAsyncThunk<
  Notification[],
  undefined,
  { rejectValue: ApiError; extra: Services }
>(
  'notification/fetchNotifications',
  async (_, thunkApi) => {
    const response = await thunkApi.extra.notification.list();
    return response.data.results ?? [];
  },
  { serializeError },
);

export const markNotificationAsRead = createAsyncThunk<
  Notification,
  number,
  { rejectValue: ApiError; extra: Services }
>(
  'notification/markNotification',
  async (notificationId, thunkApi) => {
    const response = await thunkApi.extra.notification.markAsReadCreate(notificationId);
    return response.data;
  },
  { serializeError },
);

export const buildSlice = <R>(
  sliceSelector: (state: R) => NotificationState,
  initialState: NotificationState = initialNotificationState,
) => {
  const setPending = (state: Draft<NotificationState>) => {
    state.loading = 'pending';
  };

  const onRejected = (state: NotificationState, action: RejectedPayloadAction<ApiError, ApiError>) => {
    state.loading = 'failed';
    const error = action.payload ?? action.error;
    state.error = {
      ...error,
      code: 'UNKNOWN',
      message: action.error.message ?? action.error.name ?? 'Unknown Error',
      options: { text: error.options?.text ?? '', callback: slice.actions.resetError },
    };
  };

  const onFulfilled = (state: NotificationState, action: PayloadAction<Notification | Notification[]>) => {
    state.loading = 'succeeded';
    if (Array.isArray(action.payload)) {
      adapter.upsertMany(state, action.payload);
    } else if (action.payload) {
      adapter.upsertOne(state, action.payload);
    }
  };

  const onFulfilledDelete = (state: NotificationState, action: PayloadAction<number>) => {
    state.loading = 'succeeded';
    adapter.removeOne(state, action.payload);
  };

  const slice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
      resetError: (state) => {
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchNotifications.pending, setPending);
      builder.addCase(fetchNotifications.fulfilled, onFulfilled);
      builder.addCase(fetchNotifications.rejected, onRejected);
      builder.addCase(markNotificationAsRead.pending, setPending);
      builder.addCase(markNotificationAsRead.fulfilled, onFulfilled);
      builder.addCase(markNotificationAsRead.rejected, onRejected);
    },
  });

  const selectors = adapter.getSelectors(sliceSelector);
  const notifications = selectors.selectAll;

  const unread = createSelector([notifications], (notifications) => notifications.filter((n) => n.unread));

  return {
    ...slice,
    selectors: {
      ...selectors,
      unread,
    },
  } as const;
};
