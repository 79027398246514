import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { UploadScreen } from './UploadScreen';

export const PatientRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Redirect to={`${path}/upload`} />
      </Route>
      <Route exact path={`${path}/upload`}>
        <UploadScreen />
      </Route>
    </Switch>
  );
};
