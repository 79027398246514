/* tslint:disable */
/* eslint-disable */
/**
 * ECG Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0-unknown
 * Contact:
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const VerbEnum = {
  Created: 'CREATED',
  Updated: 'UPDATED',
  Deleted: 'DELETED',
  Invited: 'INVITED',
  Accepted: 'ACCEPTED',
  Rejected: 'REJECTED',
  Started: 'STARTED',
  Stopped: 'STOPPED',
  Analyzed: 'ANALYZED',
  Detected: 'DETECTED',
  Uploaded: 'UPLOADED',
  Viewed: 'VIEWED',
} as const;

export type VerbEnum = typeof VerbEnum[keyof typeof VerbEnum];
