import { register, RegistrationForm } from '@24sens/ecg01-auth';
import {AccountTypeEnum, RegisterRequest} from '@24sens/ecg01-rest-client';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

import { useLayout } from '../layout/useLayout';
import * as selectors from '../store/selectors';
import { useAppDispatch, useAppSelector } from '../store/store';

interface RegistrationScreenProps {
  accountType: AccountTypeEnum;
}
export const RegistrationScreen: React.FC<RegistrationScreenProps> = ({ accountType }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const { setTitle } = useLayout();

  const validationErrors = useAppSelector(selectors.auth.validationErrors);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const isPhysicianRegistration = location.pathname.includes('physician');

  useEffect(() => {
    setTitle(t('onboarding.signUpHeading'));
  }, []);

  const handleRegisterClicked = (data: RegisterRequest) => {
    dispatch(register({ ...data, type: accountType }))
      .then(unwrapResult)
      .then(() => setDialogOpen(true));
  };

  const continueToLogin = () => {
    setDialogOpen(false);
    history.push('/auth/login');
  };

  return (
    <>
      <Card style={{ display: 'flex', flexDirection: 'column', padding: '12px' }}>
        <Stack direction={'row'} justifyContent="space-between">
          <Button
            variant={isPhysicianRegistration ? 'contained' : 'outlined'}
            component={Link}
            to={'/auth/signup/physician'}
            style={{ marginBottom: '32px', marginRight: '10px', width: '50%' }}
          >
            {t('onboarding.signUpiAmPhysician')}
          </Button>
          <Button
            variant={!isPhysicianRegistration ? 'contained' : 'outlined'}
            component={Link}
            to={'/auth/signup/patient'}
            style={{ marginBottom: '32px', marginLeft: '10px', width: '50%' }}
          >
            {t('onboarding.signUpiAmPatient')}
          </Button>
        </Stack>
        <RegistrationForm
          onSubmit={handleRegisterClicked}
          fieldErrors={validationErrors}
          variant={'outlined'}
          style={{ marginBottom: '12px' }}
        />
        <Button variant={'text'} component={Link} to={'/auth/login'} style={{ margin: '10px' }}>
          {t('onboarding.signUpAlreadyAccount')}
        </Button>
      </Card>

      <Dialog open={isDialogOpen} onClose={continueToLogin}>
        <DialogTitle data-testid={'signup-success'} id="alert-dialog-title">
          {t('onboarding.signupSuccessTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t('onboarding.signupSuccessText')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={continueToLogin}>Weiter</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
