import { Redirect, Route, RouteProps } from 'react-router-dom';

/**
 * Explaination of the component typing trick:
 * https://newbedev.com/typescript-3-jsx-element-type-component-does-not-have-any-construct-or-call-signatures-2604
 */
interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ElementType;
  isAuthenticated: boolean;
  redirectUrl: string;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  isAuthenticated,
  redirectUrl,
  component: Component,
  ...rest
}) => {
  return (
    <Route {...rest} render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to={redirectUrl} />)} />
  );
};
