import { SvgIcon } from '@mui/material';
import React, { ReactElement } from 'react';

import { ReactComponent as ChestPressure } from './resources/notes/icons-druck.svg';
import { ReactComponent as ChestPressureFilled } from './resources/notes/icons-druck-filled.svg';
import { ReactComponent as Palpitations } from './resources/notes/icons-herzrasen.svg';
import { ReactComponent as PalpitationsFilled } from './resources/notes/icons-herzrasen-filled.svg';
import { ReactComponent as HeartSkip } from './resources/notes/icons-herzstolpern.svg';
import { ReactComponent as HeartSkipFilled } from './resources/notes/icons-herzstolpern-filled.svg';
import { ReactComponent as HotFlash } from './resources/notes/icons-hitzewallung.svg';
import { ReactComponent as HotFlashFilled } from './resources/notes/icons-hitzewallung-filled.svg';
import { ReactComponent as BreathS } from './resources/notes/icons-kurzatmigkeit.svg';
import { ReactComponent as BreathSFilled } from './resources/notes/icons-kurzatmigkeit-filled.svg';
import { ReactComponent as Lie } from './resources/notes/icons-lie.svg';
import { ReactComponent as LieFilled } from './resources/notes/icons-lie-filled.svg';
import { ReactComponent as Other } from './resources/notes/icons-other.svg';
import { ReactComponent as OtherFilled } from './resources/notes/icons-other-filled.svg';
import { ReactComponent as Dizziness } from './resources/notes/icons-schwindel.svg';
import { ReactComponent as DizzinessFilled } from './resources/notes/icons-schwindel-filled.svg';
import { ReactComponent as Sit } from './resources/notes/icons-sit.svg';
import { ReactComponent as SitFilled } from './resources/notes/icons-sit-filled.svg';
import { ReactComponent as Sleep } from './resources/notes/icons-sleep.svg';
import { ReactComponent as SleepFilled } from './resources/notes/icons-sleep-filled.svg';
import { ReactComponent as Sport } from './resources/notes/icons-sport.svg';
import { ReactComponent as SportFilled } from './resources/notes/icons-sport-filled.svg';
import { ReactComponent as Stairs } from './resources/notes/icons-stairs.svg';
import { ReactComponent as StairsFilled } from './resources/notes/icons-stairs-filled.svg';
import { ReactComponent as Flash } from './resources/notes/icons-symptom.svg';
import { ReactComponent as FlashFilled } from './resources/notes/icons-symptom-filled.svg';
import { ReactComponent as Nausea } from './resources/notes/icons-übelkeit.svg';
import { ReactComponent as NauseaFilled } from './resources/notes/icons-übelkeit-filled.svg';
import { ReactComponent as Walk } from './resources/notes/icons-walk.svg';
import { ReactComponent as WalkFilled } from './resources/notes/icons-walk-filled.svg';
import { ReactComponent as WellbeingHigh } from './resources/notes/icons-wohlbefinden-hoch.svg';
import { ReactComponent as WellbeingHighFilled } from './resources/notes/icons-wohlbefinden-hoch-filled.svg';
import { ReactComponent as WellbeingMedium } from './resources/notes/icons-wohlbefinden-mittel.svg';
import { ReactComponent as WellbeingMediumFilled } from './resources/notes/icons-wohlbefinden-mittel-filled.svg';
import { ReactComponent as WellbeingTired } from './resources/notes/icons-wohlbefinden-müde.svg';
import { ReactComponent as WellbeingTiredFilled } from './resources/notes/icons-wohlbefinden-müde-filled.svg';
import { ReactComponent as WellbeingLow } from './resources/notes/icons-wohlbefinden-niedrig.svg';
import { ReactComponent as WellbeingLowFilled } from './resources/notes/icons-wohlbefinden-niedrig-filled.svg';
import { ReactComponent as Yoga } from './resources/notes/icons-yoga.svg';
import { ReactComponent as YogaFilled } from './resources/notes/icons-yoga-filled.svg';

/**
 * TODO: Would be cool to import TagGroups from json to be able to use it in python backend too to the the same icons
 * Watch progress of this issue: https://github.com/microsoft/TypeScript/issues/32063
 */
export const TagGroups = [
  {
    name: 'activity',
    multiple: true,
    icon: 'flash',
    tags: [
      { name: 'sleep', icon: 'sleep' },
      { name: 'lie', icon: 'lie' },
      { name: 'sit', icon: 'sit' },
      { name: 'walk', icon: 'walk' },
      { name: 'stairs', icon: 'stairs' },
      { name: 'yoga', icon: 'yoga' },
      { name: 'sport', icon: 'sport' },
      { name: 'other', icon: 'other' },
    ] as const,
  },
  {
    name: 'symptom',
    multiple: true,
    icon: 'flash',
    tags: [
      { name: 'shortness_of_breath', icon: 'shortness_of_breath' },
      { name: 'dizziness', icon: 'dizziness' },
      { name: 'palpitations', icon: 'palpitations' },
      { name: 'heart_skip', icon: 'heart_skip' },
      { name: 'hot_flash', icon: 'hot_flash' },
      { name: 'chest_pressure', icon: 'chest_pressure' },
      { name: 'nausea', icon: 'nausea' },
      { name: 'other', icon: 'flash' },
    ] as const,
  },
  {
    name: 'wellbeing',
    multiple: false,
    icon: 'flash',
    tags: [
      { name: 'high', icon: 'high' },
      { name: 'medium', icon: 'medium' },
      { name: 'low', icon: 'low' },
      { name: 'tired', icon: 'tired' },
    ] as const,
  },
] as const;

const TagGroupSeperator = '::' as const;
type TAG_GROUP_SEPERATOR = typeof TagGroupSeperator;
export type TagGroup<Prefix extends string, Names extends string> = `${Prefix}${TAG_GROUP_SEPERATOR}${Names}`;
export type GroupName = typeof TagGroups[number]['name'];
export type TagIconType = typeof TagGroups[number]['tags'][number]['icon'];
export type TagName = typeof TagGroups[number]['tags'][number]['name'];
export type Tag = TagGroup<GroupName, TagName>;

export const fullName = (group: GroupName, tag: TagName): Tag => `${group}${TagGroupSeperator}${tag}`;

export interface UiTag {
  name: TagName;
  icon: TagIconType;
  checked?: boolean;
  fullName?: string;
}

export interface UiTagGroup {
  name: GroupName;
  multiple: boolean;
  icon: string;
  tags: readonly UiTag[];
}

export interface TagIcons {
  normal: { [key in TagIconType]: ReactElement };
  filled: { [key in TagIconType]: ReactElement };
}

export const icons: TagIcons = {
  normal: {
    // Default
    flash: <Flash />,
    // Activities
    sleep: <Sleep />,
    lie: <Lie />,
    sit: <Sit />,
    walk: <Walk />,
    stairs: <Stairs />,
    yoga: <Yoga />,
    sport: <Sport />,
    other: <Other />,
    // Symptoms
    palpitations: <Palpitations />,
    dizziness: <Dizziness />,
    heart_skip: <HeartSkip />,
    hot_flash: <HotFlash />,
    nausea: <Nausea />,
    shortness_of_breath: <BreathS />,
    chest_pressure: <ChestPressure />,
    // Only default icon (flash) used atm
    // Wellbeing
    high: <WellbeingHigh />,
    medium: <WellbeingMedium />,
    low: <WellbeingLow />,
    tired: <WellbeingTired />,
  },
  filled: {
    // Default
    flash: <FlashFilled />,
    // Activities
    sleep: <SleepFilled />,
    lie: <LieFilled />,
    sit: <SitFilled />,
    walk: <WalkFilled />,
    stairs: <StairsFilled />,
    yoga: <YogaFilled />,
    sport: <SportFilled />,
    other: <OtherFilled />,
    // Symptoms
    palpitations: <PalpitationsFilled />,
    dizziness: <DizzinessFilled />,
    heart_skip: <HeartSkipFilled />,
    hot_flash: <HotFlashFilled />,
    nausea: <NauseaFilled />,
    shortness_of_breath: <BreathSFilled />,
    chest_pressure: <ChestPressureFilled />,
    // Only default icon (flash) used atm
    // Wellbeing
    high: <WellbeingHighFilled />,
    medium: <WellbeingMediumFilled />,
    low: <WellbeingLowFilled />,
    tired: <WellbeingTiredFilled />,
  },
} as const;

interface TagIconProps {
  tag: Tag;
  filter?: string;
}
export const TagIcon: React.FC<TagIconProps> = (props) => {
  const tag = props.tag;
  const trimmedTag = tag.substr(tag.indexOf(':') + 2);
  const icon = icons.normal[trimmedTag as TagIconType] ?? <FlashFilled />;
  return (
    <SvgIcon
      data-testid={'recordingDetails-tag_' + tag}
      sx={{
        filter: props.filter,
      }}
      style={{
        width: '22px',
      }}
    >
      {icon}
    </SvgIcon>
  );
};
