import { AuthState, buildSlice, Services as AuthServices } from '@24sens/ecg01-auth';
import { History } from 'history';

export interface Services extends AuthServices {
  history: History;
}

const auth = buildSlice((state: { auth: AuthState }) => state.auth);
export default auth;
export const selectors = auth.selectors;
