import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import Plot from 'react-plotly.js';

import { useSignalChunkPlotData } from './useSignalChunkPlotData';

const config: Partial<Plotly.Config> = {
  displayModeBar: false,
};

export interface SignalChunkPlotProps {
  recordingId: number;
  chunkIdx: number;
}

export const SignalChunkPlot: React.FC<SignalChunkPlotProps> = ({ recordingId, chunkIdx, ...props }) => {
  const plotParams = useSignalChunkPlotData(recordingId, chunkIdx);
  const media = useMediaQuery('print');

  useEffect(() => {
    console.log(`Media changed to ${media}`);
  }, [media]);

  console.log(`Render chart from for chunk ${chunkIdx} recording ${recordingId}`);
  return (
    <Plot
      {...plotParams}
      config={config}
      className={'signal-chart'} // See index.css for a hack that hides rangeselector bar numbers
      style={{ width: '100%' }}
      useResizeHandler
    />
  );
};
