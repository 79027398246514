import { logout } from '@24sens/ecg01-auth';
import { Button, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Logo from '../resources/images/24sens-logo.svg';
import * as selectors from '../store/selectors';
import { useAppDispatch, useAppSelector } from '../store/store';

export const VerificationPendingDialog: React.FC<{ onEditProfile(): void }> = ({ onEditProfile }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isVerified = useAppSelector(selectors.auth.isVerified);
  const isAuthenticated = useAppSelector(selectors.auth.isAuthenticated);
  const isProfileValid = useAppSelector(selectors.auth.isProfileValid) ?? false;
  const isPhysician = useAppSelector(selectors.auth.isPhysician);

  return (
    <Dialog open={!isVerified && isAuthenticated && isPhysician && isProfileValid} maxWidth={'xs'}>
      <DialogTitle data-testid={'verificationDialogTitle'}>
        <CardMedia component="img" image={Logo} alt="24sens logo" sx={{ p: '26px' }} />
        {t('onboarding.verifyAccountText')}
      </DialogTitle>
      <DialogContent>
        <Typography data-testid={'verificationDialogContent'} variant={'body2'}>
          {t('onboarding.verifyAccountSubText')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(logout())}>{t('menu.logout')}</Button>
        <Button onClick={onEditProfile}>{t('profile.buttonEdit')}</Button>
      </DialogActions>
    </Dialog>
  );
};
