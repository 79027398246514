import { ApiError } from '@24sens/ecg01-rest-client';
import { StandardButton } from '@24sens/ui';
import { IonIcon, IonText } from '@ionic/react';
import { Dialog } from '@mui/material';
import { alertCircleOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export interface NfcDialogTexts {
  title: string;
  text: string;
  buttonText: string;
}

interface NfcDialogProps {
  error?: ApiError | null;
  children?: React.ReactNode;
}

export const ErrorDialog: React.FC<NfcDialogProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Dialog open={!!props.error} fullWidth={true} maxWidth={'xl'} style={{ textAlign: 'center', height: '90%' }}>
      <IonIcon
        icon={alertCircleOutline}
        style={{
          fontSize: '200px',
          marginTop: '24px',
          marginBottom: '24px',
          marginLeft: 'auto',
          marginRight: 'auto',
          color: 'var(--twentyfoursens-blue-light)',
        }}
      />
      <IonText
        style={{
          marginLeft: '24px',
          marginRight: '24px',
        }}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <h1 data-testid={'error-detail'}>{props.error?.detail}</h1>
        <p data-testid={'error-message'}>{props.error?.message}</p>
      </IonText>
      <p
        data-testid="error-text"
        style={{
          marginLeft: '24px',
          marginRight: '24px',
        }}
      >
        {props.error?.options?.text}
      </p>
      <StandardButton
        onClick={() => {
          if (props.error?.options?.callback) {
            dispatch(props.error?.options?.callback());
          }
        }}
        text={t('buttons.ok')}
      />
      {props.children}
    </Dialog>
  );
};
