import React from 'react';

import { isIRecording, TimelineEntry } from '../store/store';
import { CommentCard } from './CommentCard';
import { PlotCard } from './PlotCardOverview';

interface TimelineCardProps {
  entry: TimelineEntry;
  setOpenEnterComment?(value: string): void;
}

export const TimelineCard: React.FC<TimelineCardProps> = ({ entry, ...props }) => {
  if (isIRecording(entry)) {
    return <PlotCard entry={entry} />;
  }
  return <CommentCard setOpenEnterComment={props.setOpenEnterComment} entry={entry} />;
};
