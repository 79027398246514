/* tslint:disable */
/* eslint-disable */
/**
 * ECG Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0-unknown
 * Contact:
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForbiddenError } from '../models';
// @ts-ignore
import { NotFoundError } from '../models';
// @ts-ignore
import { PatchedProfileRequest } from '../models';
// @ts-ignore
import { Profile } from '../models';
// @ts-ignore
import { ProfileRequest } from '../models';
// @ts-ignore
import { UnauthenticatedError } from '../models';
// @ts-ignore
import { ValidationError } from '../models';
/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this profile.
     * @param {PatchedProfileRequest} [patchedProfileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdate: async (
      id: number,
      patchedProfileRequest?: PatchedProfileRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdate', 'id', id);
      const localVarPath = `/profile/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(patchedProfileRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this profile.
     * @param {ProfileRequest} [profileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      id: number,
      profileRequest?: ProfileRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('update', 'id', id);
      const localVarPath = `/profile/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(profileRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration);
  return {
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this profile.
     * @param {PatchedProfileRequest} [patchedProfileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdate(
      id: number,
      patchedProfileRequest?: PatchedProfileRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdate(id, patchedProfileRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this profile.
     * @param {ProfileRequest} [profileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      id: number,
      profileRequest?: ProfileRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, profileRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ProfileApiFp(configuration);
  return {
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this profile.
     * @param {PatchedProfileRequest} [patchedProfileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdate(id: number, patchedProfileRequest?: PatchedProfileRequest, options?: any): AxiosPromise<Profile> {
      return localVarFp.partialUpdate(id, patchedProfileRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
     * @param {number} id A unique integer value identifying this profile.
     * @param {ProfileRequest} [profileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: number, profileRequest?: ProfileRequest, options?: any): AxiosPromise<Profile> {
      return localVarFp.update(id, profileRequest, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * ProfileApi - interface
 * @export
 * @interface ProfileApi
 */
export interface ProfileApiInterface {
  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this profile.
   * @param {PatchedProfileRequest} [patchedProfileRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfileApiInterface
   */
  partialUpdate(
    id: number,
    patchedProfileRequest?: PatchedProfileRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Profile>;

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this profile.
   * @param {ProfileRequest} [profileRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfileApiInterface
   */
  update(id: number, profileRequest?: ProfileRequest, options?: AxiosRequestConfig): AxiosPromise<Profile>;
}

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI implements ProfileApiInterface {
  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this profile.
   * @param {PatchedProfileRequest} [patchedProfileRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfileApi
   */
  public partialUpdate(id: number, patchedProfileRequest?: PatchedProfileRequest, options?: AxiosRequestConfig) {
    return ProfileApiFp(this.configuration)
      .partialUpdate(id, patchedProfileRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed..  As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
   * @param {number} id A unique integer value identifying this profile.
   * @param {ProfileRequest} [profileRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfileApi
   */
  public update(id: number, profileRequest?: ProfileRequest, options?: AxiosRequestConfig) {
    return ProfileApiFp(this.configuration)
      .update(id, profileRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
