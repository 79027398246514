import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as selectors from '../store/selectors';
import { useAppSelector } from '../store/store';

export const HeartRateTable: FC<{ recordingId: number; chunkIdx: number }> = ({ recordingId, chunkIdx }) => {
  const chunkHrStats = useAppSelector((state) =>
    selectors.signal.heartrateStatsForChunk(state, { recordingId, chunkIdx }),
  );
  const signalHrStats = useAppSelector((state) => selectors.signal.heartrateStatsForSignal(state, recordingId));
  const { t } = useTranslation();

  return (
    <table>
      <thead>
        <tr>
          <th>{t('timeline.recordingDetails.heartRate')}</th>
          <th>{t('timeline.plotCard.hrMin')}</th>
          <th>{t('timeline.plotCard.hrMax')}</th>
          <th>{t('timeline.plotCard.hrMean')}</th>
        </tr>
      </thead>
      <tbody>
        {chunkHrStats ? (
          <tr>
            <th>{t('timeline.plotCard.sliceTable') + chunkIdx}</th>
            <td>{chunkHrStats.hr_min?.toFixed(2) ?? '?'}</td>
            <td>{chunkHrStats.hr_max?.toFixed(2) ?? '?'}</td>
            <td>{chunkHrStats.hr_mean?.toFixed(2) ?? '?'}</td>
          </tr>
        ) : null}
        {signalHrStats ? (
          <tr>
            <th>{t('timeline.plotCard.recordingTable')}</th>
            <td>{signalHrStats.hr_min?.toFixed(2) ?? '?'}</td>
            <td>{signalHrStats.hr_max?.toFixed(2) ?? '?'}</td>
            <td>{signalHrStats.hr_mean?.toFixed(2) ?? '?'}</td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );
};
