import { markNotificationAsRead } from '@24sens/ecg01-notifications';
import { dateStringToFormattedDateTimeString } from '@24sens/utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Avatar, ListItem, ListItemAvatar, ListItemText, MenuItem, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Menu, { MenuProps } from '@mui/material/Menu';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { useAppDispatch } from '../store/store';
import { TranslatedNotification, useNotifications } from './useNotifications';

export const NotificationMenu: React.FC<MenuProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const notifications = useNotifications();
  const history = useHistory();
  const dispatch = useAppDispatch();

  return (
    <Menu
      PaperProps={{
        elevation: 0,
        sx: {
          minWidth: '320px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 50,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      {...props}
    >
      <ListItem>
        <Typography variant={'h6'}>{t('notifications.title')}</Typography>
      </ListItem>
      <Divider />

      {notifications ? (
        notifications.map((n, index) => (
          <NotificationItem
            key={index}
            notification={n}
            onClick={(notificationId) => {
              if (n.link) {
                history.replace(n.link);
              }
              dispatch(markNotificationAsRead(notificationId));
            }}
            onClose={(ev: Event) => {
              if (props?.onClose) {
                props.onClose(ev, 'backdropClick');
              }
            }}
          />
        ))
      ) : (
        <div />
      )}
    </Menu>
  );
};

interface NotificationItemProps {
  notification: TranslatedNotification;
  onClick(id: number): void;
  onClose(ev: Event): void;
}
const NotificationItem: React.FC<NotificationItemProps> = (props) => {
  return (
    <MenuItem
      onClick={(ev) => {
        props.onClick(props.notification.id);
      }}
    >
      <ListItemAvatar>
        <Avatar></Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={props.notification?.text}
        secondary={dateStringToFormattedDateTimeString(props.notification.timestamp)}
        style={{ marginRight: '8px' }}
      />
      <VisibilityIcon />
    </MenuItem>
  );
};
