import * as Sentry from '@sentry/browser';
import { CaptureConsole, ExtraErrorData } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { Integration } from '@sentry/types';

let init = false;

export function initSentry() {
  initSentryWithAppInfo();
}

function normalizeUrl(url: string, pathStripeRe: RegExp): string {
  return url.replace(pathStripeRe, '');
}

function initSentryWithAppInfo() {
  if (init) {
    console.error('Attempted to double-initialize Sentry');
    return;
  }
  init = true;

  const integrations: Integration[] = [
    new ExtraErrorData({ depth: 1 }),
    new CaptureConsole({ levels: ['error'] }),
    new Integrations.BrowserTracing(),
  ];

  Sentry.init({
    dsn: '',
    release: process.env.REACT_APP_VERSION,
    integrations,
  });

  const PATH_STRIP_RE = /(http|capacitor):\/\/localhost/;

  //https://www.youtube.com/watch?v=iGuqUtHGTj8
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  Sentry.addGlobalEventProcessor((data: any) => {
    if (data.culprit) {
      data.culprit = normalizeUrl(data.culprit, PATH_STRIP_RE);
    }
    const stacktrace =
      data.stacktrace || (data.exception && data.exception.values && data.exception.values[0].stacktrace);
    if (stacktrace) {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      stacktrace.frames.forEach((frame: any) => {
        if (frame.filename !== '[native code]' && frame.filename !== '<anonymous>') {
          frame.filename = normalizeUrl(frame.filename, PATH_STRIP_RE);
        }
      });
    }
    return data;
  });

  console.log('Initialized Sentry');
}
