import { IComment } from '@24sens/ecg01-comments';
import { Services as ApiServices } from '@24sens/ecg01-mobile-app/src/data/api';
import { IRecording } from '@24sens/ecg01-recordings';
import { getToken } from '@24sens/ecg01-rest-client';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import { createApi } from './api';
import { rootSaga } from './sagas';
import * as selectors from './selectors';
import { initSentry } from './sentry';
import { rootReducer } from './state';

export type { RejectedPayloadAction } from '@24sens/ecg01-rest-client';

const history = createBrowserHistory();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function isIRecording(object: TimelineEntry): object is IRecording {
  return 'inputfile' in object;
}

export type TimelineEntry = IComment | IRecording;

export interface Services extends ApiServices {
  history: ReturnType<typeof createBrowserHistory>;
}

initSentry();

const apiServices = createApi(() => {
  const t = selectors.auth.backendToken(store.getState());
  if (!t) {
    return '';
  }
  return `Token ${t}`;
});

export const services: Services = {
  history: history,
  ...apiServices,
};

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  preloadedState: {
    auth: {
      token: getToken(),
      user: null,
      error: null,
      loading: 'idle',
    },
  },
  middleware: (getDefault) =>
    getDefault({ thunk: { extraArgument: services }, serializableCheck: false }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga, services);

window.store = store;
