import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#7c8ce1',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#F9A342',
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: 'var(--font-size-heading)',
          lineHeight: '1',
        },
        h5: {
          fontSize: 'var(--font-size-heading)',
        },
        subtitle1: {
          fontSize: 'var(--font-size-heading)',
          color: 'var(--ion-color-light-gray)',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          height: '80px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          background: 'white',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: { fontSize: 'var(--font-size-heading)' },
        subheader: { fontSize: 'var(--font-size-subheading)' },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: '100%',
          flexBasis: '100%',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          maxWidth: '100%',
          flexBasis: '100%',
        },
      },
    },
  },
});
