import { fetchCommentsForPatient } from '@24sens/ecg01-comments';
import { fetchRecordingsOfUser } from '@24sens/ecg01-recordings';
import { TargetTypeEnum } from '@24sens/ecg01-rest-client';
import AddCommentIcon from '@mui/icons-material/AddComment';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { useMediaQuery } from '@mui/material';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PatientDetailsCard } from '../Cards/PatientDetailsCard';
import { TimelineCard } from '../Cards/TimelineCardOverview';
import { useLayout } from '../layout/useLayout';
import * as selectors from '../store/selectors';
import { useAppDispatch, useAppSelector } from '../store/store';
import { EnterCommentModal } from './EnterCommentModal';

export const PatientTimeline: React.FC = () => {
  const { t } = useTranslation();
  const isPrint = useMediaQuery('print');
  const { patientId } = useParams<{ patientId: string }>();
  const patient = useAppSelector((state) => selectors.patient.selectById(state, patientId));
  const [commentId, setCommentId] = useState<string | null>(null);
  const timelineEntries = useAppSelector((state) => selectors.timeline.forPatient(state, Number.parseInt(patientId)));
  const dispatch = useAppDispatch();
  const { setTitle } = useLayout();

  const fabStyle: SxProps = {
    position: 'fixed',
    bottom: 16,
    right: 16,
    visibility: isPrint ? 'hidden' : 'visible',
  };

  useEffect(() => {
    if (patientId) {
      dispatch(fetchRecordingsOfUser(Number.parseInt(patientId)));
      dispatch(fetchCommentsForPatient(Number.parseInt(patientId)));
    }
  }, [patientId]);

  useEffect(() => {
    setTitle(patient?.name + ' ' + t('dashboard.patientHeading'));
  }, [patient]);

  return (
    <>
      <Stack spacing={1} sx={{ display: isPrint ? 'block' : 'flex' }}>
        <PatientDetailsCard />
        {timelineEntries.map((entry) => (
          <TimelineCard key={entry.id} entry={entry} setOpenEnterComment={setCommentId} />
        ))}
      </Stack>
      <Fab
        className={'No-Print'}
        color="secondary"
        aria-label="add"
        sx={{ ...fabStyle, bottom: '76px' }}
        onClick={doPrint}
      >
        <DownloadRoundedIcon />
      </Fab>
      <Fab className={'No-Print'} color="secondary" aria-label="add" sx={fabStyle} onClick={() => setCommentId('')}>
        <AddCommentIcon />
      </Fab>
      <EnterCommentModal
        open={commentId != null}
        handleClose={() => setCommentId(null)}
        patientOrRecordingId={Number.parseInt(patientId)}
        commentId={commentId ? Number.parseInt(commentId): undefined}
        target_type={TargetTypeEnum.User}
      />
    </>
  );
};

export function doPrint() {
  window.print();
}
