import Chip, { ChipProps } from '@mui/material/Chip';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tag, TagIcon } from './Tag';

export interface TagChipProps extends ChipProps {
  tag: Tag;
}
export const TagChip: React.FC<TagChipProps> = ({ tag, ...props }) => {
  const { t } = useTranslation();

  return (
    <Chip
      color={'secondary'}
      icon={
        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '2px' }}>
          <TagIcon
            tag={tag}
            filter={
              'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)'
            }
          />
        </div>
      }
      label={t('notes.tags.' + tag.substr(tag.indexOf(':') + 2))}
      {...props}
    />
  );
};
