import {
  ApiError,
  PatchedPatientCommentRequest,
  PatchedRecordingCommentRequest,
  PatientApi,
  PatientComment,
  PatientCommentRequest,
  RecordingApi,
  RecordingComment,
  RecordingCommentRequest,
  RejectedPayloadAction,
  serializeError,
  TargetTypeEnum,
} from '@24sens/ecg01-rest-client';
import { HasId } from '@24sens/utils';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Draft,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

export interface Services {
  recording: RecordingApi;
  patient: PatientApi;
}

export type Comment = PatientComment | RecordingComment;

export type IComment = HasId<Comment> & { target_id: number };

export interface CommentState extends EntityState<Comment> {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: ApiError | null;
}

export const adapter = createEntityAdapter<IComment>({
  selectId: (comment) => comment.id,
  sortComparer: (a, b) => a.created?.localeCompare(b.created ?? '') ?? -1,
});

export const initialCommentState: CommentState = adapter.getInitialState({
  loading: 'idle',
  error: null,
});

export const createCommentForRecording = createAsyncThunk<
  IComment,
  { recordingId: number; comment: RecordingCommentRequest },
  { rejectValue: ApiError }
>(
  'comment/createForRecording',
  async ({ recordingId, comment }, thunkApi) => {
    const response = await (thunkApi.extra as Services).recording.commentCreate(recordingId, comment);
    return response.data as IComment;
  },
  { serializeError },
);

export const updateCommentForRecording = createAsyncThunk<
  IComment,
  { commentId: number; recordingId: number; comment: PatchedRecordingCommentRequest },
  { rejectValue: ApiError; extra: Services }
>(
  'comment/update',
  async ({ commentId, recordingId, comment }, thunkApi) => {
    const response = await thunkApi.extra.recording.commentUpdate(commentId, recordingId, comment);
    return response.data as IComment;
  },
  { serializeError },
);

export const deleteCommentForRecording = createAsyncThunk<
  number,
  { recordingId: number; commentId: number },
  { rejectValue: ApiError; extra: Services }
>(
  'comment/delete',
  async ({ recordingId, commentId }, thunkApi) => {
    await (thunkApi.extra as Services).recording.commentDestroy(commentId, recordingId);
    return commentId;
  },
  { serializeError },
);

export const fetchCommentsForRecording = createAsyncThunk<IComment[], number, { rejectValue: ApiError }>(
  'comment/listForRecording',
  async (recordingId, thunkApi) => {
    const response = await (thunkApi.extra as Services).recording.commentList(recordingId);
    return response.data.results as IComment[];
  },
  { serializeError },
);

export const fetchCommentsForPatient = createAsyncThunk<IComment[], number, { rejectValue: ApiError; extra: Services }>(
  'comment/listForPatient',
  async (patientId, thunkApi) => {
    const response = await thunkApi.extra.patient.commentList(patientId);
    return response.data.results as IComment[];
  },
  { serializeError },
);

export const createCommentForPatient = createAsyncThunk<
  IComment,
  { patientId: number; comment: PatientCommentRequest },
  { rejectValue: ApiError; extra: Services }
>(
  'comment/createForPatient',
  async ({ patientId, comment }, thunkApi) => {
    const response = await thunkApi.extra.patient.commentCreate(patientId, comment);
    return response.data as IComment;
  },
  { serializeError },
);

export const updateCommentForPatient = createAsyncThunk<
  IComment,
  { commentId: number; patientId: number; comment: PatchedPatientCommentRequest },
  { rejectValue: ApiError; extra: Services }
>(
  'comment/updateForPatient',
  async ({ commentId, patientId, comment }, thunkApi) => {
    const response = await thunkApi.extra.patient.commentPartialUpdate(commentId, patientId, comment);
    return response.data as IComment;
  },
  { serializeError },
);

export const deleteCommentForPatient = createAsyncThunk<
  number,
  { patientId: number; commentId: number },
  { rejectValue: ApiError; extra: Services }
>(
  'comment/deleteForPatient',
  async ({ patientId, commentId }, thunkApi) => {
    await thunkApi.extra.patient.commentDestroy(commentId, patientId);
    return commentId;
  },
  { serializeError },
);

export const buildSlice = <R>(
  sliceSelector: (state: R) => CommentState,
  initialState: CommentState = initialCommentState,
) => {
  const setPending = (state: Draft<CommentState>) => {
    state.loading = 'pending';
  };

  const onRejected = (state: CommentState, action: RejectedPayloadAction<ApiError, ApiError>) => {
    state.loading = 'failed';
    const error = action.payload ?? action.error;
    state.error = {
      ...error,
      code: 'UNKNOWN',
      message: action.error.message ?? action.error.name ?? 'Unknown Error',
      options: { text: error.options?.text ?? '', callback: slice.actions.resetError },
    };
  };

  const onFulfilled = (state: CommentState, action: PayloadAction<IComment | IComment[]>) => {
    state.loading = 'succeeded';
    if (Array.isArray(action.payload)) {
      adapter.upsertMany(state, action.payload);
    } else {
      adapter.upsertOne(state, action.payload);
    }
  };

  const onFulfilledDelete = (state: CommentState, action: PayloadAction<number>) => {
    state.loading = 'succeeded';
    adapter.removeOne(state, action.payload);
  };

  const slice = createSlice({
    name: 'comment',
    initialState: initialState,
    reducers: {
      resetError: (state) => {
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(createCommentForRecording.pending, setPending);
      builder.addCase(createCommentForRecording.fulfilled, onFulfilled);
      builder.addCase(createCommentForRecording.rejected, onRejected);

      builder.addCase(updateCommentForRecording.pending, setPending);
      builder.addCase(updateCommentForRecording.fulfilled, onFulfilled);
      builder.addCase(updateCommentForRecording.rejected, onRejected);

      builder.addCase(fetchCommentsForRecording.pending, setPending);
      builder.addCase(fetchCommentsForRecording.fulfilled, onFulfilled);
      builder.addCase(fetchCommentsForRecording.rejected, onRejected);

      builder.addCase(deleteCommentForRecording.pending, setPending);
      builder.addCase(deleteCommentForRecording.fulfilled, onFulfilledDelete);
      builder.addCase(deleteCommentForRecording.rejected, onRejected);

      builder.addCase(fetchCommentsForPatient.pending, setPending);
      builder.addCase(fetchCommentsForPatient.fulfilled, onFulfilled);
      builder.addCase(fetchCommentsForPatient.rejected, onRejected);

      builder.addCase(createCommentForPatient.pending, setPending);
      builder.addCase(createCommentForPatient.fulfilled, onFulfilled);
      builder.addCase(createCommentForPatient.rejected, onRejected);

      builder.addCase(updateCommentForPatient.pending, setPending);
      builder.addCase(updateCommentForPatient.fulfilled, onFulfilled);
      builder.addCase(updateCommentForPatient.rejected, onRejected);

      builder.addCase(deleteCommentForPatient.pending, setPending);
      builder.addCase(deleteCommentForPatient.fulfilled, onFulfilledDelete);
      builder.addCase(deleteCommentForPatient.rejected, onRejected);
    },
  });

  const commentSelector = adapter.getSelectors(sliceSelector);
  const getId = (state: R, id: number) => id;
  const comments = commentSelector.selectAll;

  const commentsForRecordings = createSelector([comments], (comments) =>
    comments.filter((c) => c.target_type === TargetTypeEnum.Recording),
  );
  const commentsForRecording = createSelector([commentsForRecordings, getId], (comments, recordingId: number) => {
    return comments
      .sort((a, b) => {
        if (a?.timestamp && b?.timestamp) {
          return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
        } else if (a?.start_time && b?.start_time) {
          return new Date(b.start_time).getTime() - new Date(a.start_time).getTime();
        } else if (a?.timestamp && b?.start_time) {
          return new Date(b.start_time).getTime() - new Date(a.timestamp).getTime();
        } else if (a?.start_time && b?.timestamp) {
          return new Date(b.timestamp).getTime() - new Date(a.start_time).getTime();
        } else {
          return 0;
        }
      })
      .filter((c) => c.target_id === recordingId);
  });

  const commentsForPatients = createSelector([comments], (comments) =>
    comments.filter((c) => c.target_type === TargetTypeEnum.User),
  );
  const commentsForPatient = createSelector([commentsForPatients, getId], (comments, patientId: number) =>
    comments.filter((c) => c.target_id === patientId),
  );

  const commentsForPatientByPhysicianId = createSelector([commentsForPatients, getId], (comments, physicianId) => {
    return comments.filter((c) => c.creator_id === physicianId);
  });

  return {
    ...slice,
    selectors: {
      ...commentSelector,
      getId,
      comments,
      commentsForRecording,
      commentsForRecordings,
      commentsForPatient,
      commentsForPatients,
      commentsForPatientByPhysicianId,
      commentById: commentSelector.selectById,
    } as const,
  } as const;
};
