import { IonIcon } from '@ionic/react';
import {
  BaseTextFieldProps,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  InputProps,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import React, { CSSProperties, forwardRef, useState } from 'react';

interface BirthdayInputFieldProps extends BaseTextFieldProps {
  label: string;
  onChange?(value: Date): void;
  style?: CSSProperties;
}

export const BirthdateInputField = forwardRef<HTMLInputElement, BirthdayInputFieldProps>(
  ({ value = '1970-01-01', size = 'small', label, ...props }, ref) => {
    return (
      <Grid item xs={10} md={6}>
        <DatePicker
          ref={ref}
          label={label}
          value={new Date(value as string)}
          onChange={(v) => props.onChange && v && props.onChange(v)}
          maxDate={new Date()}
        />
      </Grid>
    );
  },
);

BirthdateInputField.displayName = 'BirthDayInputField';

interface TextInputFieldProps extends BaseTextFieldProps {
  label: string;
  errorMessage?: string;
  style?: CSSProperties;
  onChange?: InputProps['onChange'];
}
export const GridTextInputField = forwardRef<HTMLInputElement, TextInputFieldProps>(
  ({ errorMessage, variant = 'standard', children, ...props }, ref) => {
    return (
      <Grid item xs={10} md={6}>
        <TextField
          ref={ref}
          error={!!errorMessage}
          helperText={errorMessage}
          style={props.style}
          variant={variant}
          {...props}
        >
          {children}
        </TextField>
      </Grid>
    );
  },
);
GridTextInputField.displayName = 'TextInputField';
export const TextInputField = forwardRef<HTMLInputElement, TextInputFieldProps>(
  ({ errorMessage, variant = 'standard', children, ...props }, ref) => {
    return (
      <TextField
        ref={ref}
        error={!!errorMessage}
        helperText={errorMessage}
        style={props.style}
        variant={variant}
        {...props}
      >
        {children}
      </TextField>
    );
  },
);
TextInputField.displayName = 'TextInputField';

interface PasswordFieldProps extends BaseTextFieldProps {
  label: string;
  errorMessage?: string;
  style?: CSSProperties;
  onChange?: InputProps['onChange'];
  testId?: string;
}
export const PasswordField: React.FC<PasswordFieldProps> = ({
  errorMessage,
  label,
  variant = 'standard',
  testId = 'text-enterPw',
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const Component = variant === 'outlined' ? OutlinedInput : Input;
  const style =
    variant === 'outlined'
      ? { background: 'white', marginLeft: '5px', marginRight: '5px', paddingLeft: '5px', paddingRight: '5px' }
      : undefined;

  return (
    <FormControl data-testid={testId} variant={variant} error={!!errorMessage}>
      <InputLabel
        style={{
          ...props.style,
          ...style,
        }}
      >
        {label}
      </InputLabel>
      <Component
        onChange={props.onChange}
        style={props.style}
        type={showPassword ? 'text' : 'password'}
        autoComplete={'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(event) => event.preventDefault()}
              tabIndex={-1}
            >
              <IonIcon icon={showPassword ? eyeOutline : eyeOffOutline} />
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export type LabelVarient = 'standard' | 'outlined' | 'filled';
