// TODO: Hacky type to mimic react-i18next translate function
import { CountryEnum } from '@24sens/ecg01-rest-client';

type TFunction = (key: string) => string;

function emptyOrNull(str: string | null): boolean {
  if (!str) {
    return true;
  }
  if (!str.trim()) {
    return true;
  }
  return false;
}

export function validateNumericInput(args: { input: string | null; t: TFunction; min: number; max: number }) {
  if (!args.input || emptyOrNull(args.input)) {
    return args.t('onboarding.errorRequiredInput');
  }
  try {
    const inputNumber = parseInt(args.input.trim());
    if (isNaN(inputNumber)) {
      return args.t('onboarding.errorNotANumber');
    }
    if (inputNumber < args.min) {
      return args.t('onboarding.errorInputTooLow');
    }
    if (inputNumber > args.max) {
      return args.t('onboarding.errorInputTooHigh');
    }
    return null;
  } catch (e) {
    console.error(e);
    return args.t('onboarding.errorInvalidInput');
  }
}

export function validateBirthDay(birthDay: string | null, t: TFunction): string | null {
  if (!birthDay) {
    return t('onboarding.errorRequiredInput');
  }
  const birthDayDate = new Date(birthDay);
  if (isNaN(birthDayDate.getTime())) {
    console.error('Invalid date');
    return t('onboarding.errorRequiredInput');
  }
  return null;
}

export function validatePassword(input: string, t: TFunction, minLen: number): string | null {
  if (!input || input.length < minLen) {
    return t('onboarding.errorPasswordTooShort');
  }
  return null;
}

export function validatePasswordEquality(input: string, input2: string, t: TFunction): string | null {
  if (input !== input2) {
    return t('onboarding.hintPasswordSecond');
  }
  return null;
}

export function validateMail(input: string, t: TFunction): string | null {
  const mail = input.trim();
  if (!mail.trim()) {
    return t('onboarding.errorRequiredInput');
  }
  const re =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  if (!re.test(mail)) {
    return t('onboarding.errorMailInput');
  }
  return null;
}

export function validateUserName(input: string, t: TFunction): string | null {
  const name = input.trim();
  if (!name.trim()) {
    return t('onboarding.errorRequiredInput');
  }
  return null;
}

export function validateRequiredInput(input: string, t: TFunction): string | null {
  const name = input.trim();
  if (!name.trim()) {
    return t('onboarding.errorRequiredInput');
  }
  return null;
}

export function validateRequiredInputCheck(input: boolean, t: TFunction): string | null {
  if (!input) {
    return t('onboarding.errorRequiredInput');
  }
  return null;
}

export function validateCountryInput(input: string, t: TFunction): string | null {
  const i: CountryEnum = input.toLocaleUpperCase() as CountryEnum;
  if (!Object.values(CountryEnum).includes(i)) {
    return t('profile.errorCountry');
  } else if (input === '') {
    return t('onboarding.errorRequiredInput');
  }
  return null;
}
