import { TileComponent } from '@24sens/ui';
import CakeIcon from '@mui/icons-material/Cake';
import EmailIcon from '@mui/icons-material/Email';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Avatar, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import * as selectors from '../store/selectors';
import { useAppSelector } from '../store/store';

export const PatientDetailsCard: React.FC = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const [extended, setExtended] = useState(false);
  const patient = useAppSelector((state) => selectors.patient.selectById(state, patientId));

  return (
    <>
      <TileComponent
        image={<Avatar sx={{ bgcolor: 'var(--twentyfoursens-blue)' }}>{patient?.name?.charAt(0)}</Avatar>}
        primaryText={patient?.profile?.first_name + ' ' + patient?.profile?.last_name}
        secondaryText={patient?.profile?.social_security_number}
        headerAction={
          <IconButton aria-label="extend" onClick={() => setExtended(!extended)}>
            {extended ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        }
      >
        {extended ? (
          <List>
            <ListItem>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText>
                {patient?.email}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CakeIcon />
              </ListItemIcon>
              <ListItemText>
                {patient?.profile?.date_of_birth
                  ? new Date(patient?.profile?.date_of_birth).toLocaleDateString()
                  : undefined}
              </ListItemText>
            </ListItem>
          </List>
        ) : undefined}
      </TileComponent>
      <div style={{ height: '12px' }} />
    </>
  );
};
