import { fetchUser, PrivateRoute } from '@24sens/ecg01-auth';
import { fetchNotifications } from '@24sens/ecg01-notifications';
import { AccountTypeEnum, User } from '@24sens/ecg01-rest-client';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import { SnackbarProvider } from 'notistack';
import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { AuthLayout } from './auth/AuthLayout';
import { ErrorComponent } from './Error/ErrorComponent';
import { I18nextProvider } from './i18next';
import { LayoutProvider } from './layout/useLayout';
import { PatientLayout } from './patient/PatientLayout';
import { PhysicianLayout } from './physician/PhysicianLayout';
import * as selectors from './store/selectors';
import { services, store, useAppDispatch, useAppSelector } from './store/store';
import theme from './theme';

export const MuiApp: React.FC = () => {
  const isAuthenticated = useAppSelector(selectors.auth.isAuthenticated);
  const isPatient = useAppSelector(selectors.auth.isPatient);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      console.debug('App.tsx: User loged in, fetching Account infos. . .');
      if (!isPatient) {
        dispatch(fetchNotifications());
      }
      dispatch(fetchUser())
        .then(unwrapResult)
        .then((userDetails: User) => {
          switch (userDetails?.type) {
            case AccountTypeEnum.Physician:
              if (!userDetails.profile.is_valid && userDetails.verified) {
                services.history.push('/physician');
              } else {
                services.history.push('/physician/onboarding');
              }
              break;
            case AccountTypeEnum.Patient:
              console.debug('App.tsx: User is patient, routing to app');
              services.history.push('/patient');
              break;

            default:
              break;
          }
        });
    }
  }, [isAuthenticated]);

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', background: 'var(--background-gray)' }}>
      <Router history={services.history}>
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <PrivateRoute
            path="/physician"
            isAuthenticated={isAuthenticated}
            redirectUrl={'/auth/login'}
            component={PhysicianLayout}
          />
          <PrivateRoute
            path="/patient"
            isAuthenticated={isAuthenticated}
            redirectUrl={'/auth/login'}
            component={PatientLayout}
          />

          <Route
            path={`/`}
            render={() => (
              <Redirect to={isAuthenticated ? (isPatient ? 'patient' : 'physician/patients') : '/auth/login'} />
            )}
          />
        </Switch>
      </Router>
      <ErrorComponent />
    </Box>
  );
};

export const App: React.FC = () => {
  return (
    <Suspense fallback={<div>loading...</div>}>
      <StyledEngineProvider injectFirst>
        <I18nextProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Provider store={store}>
              <LayoutProvider>
                <SnackbarProvider>
                  <MuiApp />
                </SnackbarProvider>
              </LayoutProvider>
            </Provider>
          </ThemeProvider>
        </I18nextProvider>
      </StyledEngineProvider>
    </Suspense>
  );
};
