import { buildSlice, fetchRecordingsOfUser, RecordingState } from '@24sens/ecg01-recordings';
import { selectorChannel } from '@24sens/utils';
import { call, fork, put, takeEvery } from 'typed-redux-saga';

import * as globalSelectors from '../store/selectors';

const slice = buildSlice((state: { recording: RecordingState }) => state.recording);
export default slice;
export const selectors = slice.selectors;

function* fetchRecordings(userId: number) {
  if (userId) {
    yield* put(yield* call(fetchRecordingsOfUser, userId));
  }
}

function* watchAuthState() {
  const chan = yield* selectorChannel(globalSelectors.auth.userId);
  while (true) {
    try {
      yield* takeEvery(chan, fetchRecordings);
    } catch (e) {
      console.error('Failed to fetch recordings for user', e);
    }
  }
}

export function* saga() {
  console.info('Saga: recording saga');
  yield* fork(watchAuthState);
}
