import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format as formatDate, formatDistance, formatRelative, isDate, Locale } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';
import React from 'react';
import { I18nextProvider as OriginalI18nextProvider, initReactI18next } from 'react-i18next';

const defaultLanguage = 'en';
const dateLocales: Record<string, Locale> = { de: de, en: enUS };

window.i18next = i18n;

// eslint-disable-next-line import/no-named-as-default-member
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(XHR)
  // Use custom language detector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next);

// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
// eslint-disable-next-line import/no-named-as-default-member
i18n.init({
  fallbackLng: defaultLanguage,
  load: 'languageOnly',
  debug: process.env.NODE_ENV === 'development',
  //whitelist: Languages,
  ns: ['translation'],
  interpolation: {
    // Use date-fns to render dates, see: https://dev.to/ekeijl/react-automatic-date-formatting-in-translations-i18next-date-fns-8df
    // https://res.cloudinary.com/practicaldev/image/fetch/s--o0V1pRSC--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/i/kpqvh7bwej3vtjb1z2ju.png
    format: (value, format = '', lng = defaultLanguage) => {
      if (!isDate(value)) {
        return value;
      }
      const locale = dateLocales[lng];
      switch (format) {
        case 'short':
          return formatDate(value, 'P', { locale });
        case 'long':
          return formatDate(value, 'PPpp', { locale });
        case 'relative':
          return formatRelative(value, new Date(), { locale });
        case 'ago':
          return formatDistance(value, new Date(), { locale, addSuffix: true });
        default:
          return formatDate(value, format, { locale });
      }
    },
    escapeValue: false, // not needed for react as it escapes by default
  },
  saveMissing: true,
  missingKeyHandler: (lngs: readonly string[], ns: string, key: string, ...rest) => {
    if (process.env.NODE_ENV === 'development') {
      throw Error(`Missing translation ${ns}:${key}`);
    }
    console.error(`Missing translation ${ns}:${key}`);
  },
});

export default i18n;

export const I18nextProvider: React.FC<{ children: React.ReactElement }> = (props) => {
  return (
    <OriginalI18nextProvider i18n={i18n}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocales[i18n.language]}>
        {props.children}
      </LocalizationProvider>
    </OriginalI18nextProvider>
  );
};
