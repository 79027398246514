import { changePassword } from '@24sens/ecg01-auth';
import { PasswordField } from '@24sens/ui';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../store/store';

interface PWChangeFormState {
  password: string;
  passwordError?: string;
  password2: string;
  password2Error?: string;
}

export const PasswordChangeDialog: React.FC<{ open: boolean; handleClose(): void }> = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [formState, setFormState] = React.useState<PWChangeFormState>({
    password: '',
    password2: '',
  });

  function updateFormState(update: Partial<typeof formState>) {
    setFormState({ ...formState, ...update });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle> {t('passwordChangeDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('passwordChangeDialog.text')}</DialogContentText>
        <Stack>
          <PasswordField
            testId={'text-enterPw1'}
            value={formState.password}
            onChange={(e) =>
              updateFormState({
                password: e.target.value,
                passwordError: undefined,
              })
            }
            errorMessage={formState?.passwordError}
            label={t('onboarding.hintPasswordFirst')}
          />
          <PasswordField
            testId={'text-enterPw2'}
            value={formState.password2}
            onChange={(e) =>
              updateFormState({
                password2: e.target.value,
                password2Error: undefined,
              })
            }
            errorMessage={formState?.password2Error}
            label={t('onboarding.hintPasswordSecond')}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('buttons.cancel')}</Button>
        <Button
          data-testid={'pwChange-button'}
          variant={'contained'}
          onClick={() => {
            if (formState.password === formState.password2) {
              dispatch(changePassword({ new_password: formState.password })).then((e) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (e?.error) {
                  enqueueSnackbar(t('passwordChangeDialog.snackbarError'), { variant: 'error' });
                } else {
                  enqueueSnackbar(t('passwordChangeDialog.snackbar'), { variant: 'success' });
                  handleClose();
                }
              });
            } else {
              updateFormState({
                password2Error: t('passwordChangeDialog.noMatchError'),
              });
            }
          }}
        >
          {t('buttons.send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
