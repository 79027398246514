import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import React from 'react';

interface TileComponentProps {
  testId?: string;
  image?: JSX.Element;
  primaryText?: string | JSX.Element;
  secondaryText?: string | JSX.Element;
  headerAction?: JSX.Element;
  children?: React.ReactElement;
}

export const TileComponent: React.FC<TileComponentProps> = (props) => {
  return (
    <Card className={'no-break-inside'} sx={{ breakInside: 'avoid', pageBreakInside: 'avoid' }}>
      <CardHeader
        data-testid={props.testId}
        avatar={props.image}
        title={props.primaryText}
        subheader={props.secondaryText}
        action={props.headerAction}
      />
      {props.children ? <CardContent sx={{ pt: 0 }}>{props.children}</CardContent> : undefined}
    </Card>
  );
};
