import { CSSObject, Drawer as MuiDrawer, styled, Theme } from '@mui/material';

import { useLayout } from './useLayout';

const drawerWidth = 350;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  hidden: false,
  '&&': {
    visibility: 'visible',
  },
  overflowX: 'hidden',
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0,
  [theme.breakpoints.up('sm')]: {
    width: 0,
  },
});

export const Drawer = styled(MuiDrawer)(({ theme }) => {
  const { isDrawerOpen } = useLayout();
  return {
    width: isDrawerOpen ? drawerWidth : 0,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(isDrawerOpen && {
      ...openedMixin(theme),
      '> .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!isDrawerOpen && {
      ...closedMixin(theme),
      '> .MuiDrawer-paper': closedMixin(theme),
    }),
  };
});
