import React from 'react';

import { AppBar } from '../layout/AppBar';
import { Content } from '../layout/Content';
import { Toolbar } from '../layout/Toolbar';
import { AuthRouter } from './AuthRouter';

export const AuthLayout: React.FC = () => {
  return (
    <>
      <AppBar>
        <Toolbar />
      </AppBar>
      <Content>
        <AuthRouter />
      </Content>
    </>
  );
};
