import inviteSlice from '@24sens/ecg01-invite';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'usehooks-ts';

import patientSlice from '../patient';
import { useAppDispatch } from '../store/store';

export const PatientSearchInput: React.FC = (props) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<string>('');
  const debouncedText = useDebounce<string>(value);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(patientSlice.actions.filter(debouncedText));
    dispatch(inviteSlice.actions.filter(debouncedText));
  }, [debouncedText]);

  return (
    <FormControl variant="outlined" sx={{ width: '100%' }}>
      <InputLabel
        sx={{
          background: 'white',
          paddingLeft: '5px',
          paddingRight: '5px',
        }}
      >
        {t('dashboard.patientHint')}
      </InputLabel>
      <OutlinedInput
        data-testid={'drawer-searchInput'}
        onChange={(event) => setValue(event.target.value)}
        size={'small'}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
