import { logout } from '@24sens/ecg01-auth';
import { AccountTypeEnum } from '@24sens/ecg01-rest-client';
import Feedback from '@mui/icons-material/Feedback';
import Info from '@mui/icons-material/Info';
import Logout from '@mui/icons-material/Logout';
import MenuBook from '@mui/icons-material/MenuBook';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import * as selectors from '../store/selectors';
import { useAppDispatch, useAppSelector } from '../store/store';

interface AccountMenuProps extends MenuProps {
  onAccountClick: () => void;
  onAboutClick: () => void;
}

export const AccountMenu: React.FC<AccountMenuProps> = ({ onAccountClick, onAboutClick, ...props }) => {
  const { t } = useTranslation();
  const profile = useAppSelector(selectors.auth.userDetails);
  const isAuthenticated = useAppSelector(selectors.auth.isAuthenticated);
  const dispatch = useAppDispatch();

  return (
    <Menu
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 10,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      {...props}
    >
      {!location.pathname.includes('auth') ? (
        <MenuItem data-testid={'settings-profile'} id="lock-button" onClick={onAccountClick} hidden={!isAuthenticated}>
          <Avatar />
          <ListItemText primary={profile?.profile.title + ' ' + profile?.name} secondary={t('menu.account')} />
        </MenuItem>
      ) : null}
      <Divider hidden={!isAuthenticated} />
      <MenuItem data-testid={'settings-feedback'} component="a" href={'mailto:24.support@24sens.at'}>
        <ListItemIcon>
          <Feedback fontSize="small" />
        </ListItemIcon>
        {t('menu.feedback')}
      </MenuItem>
      <MenuItem
        data-testid={'settings-ifu'}
        component={RouterLink}
        to={profile?.type === AccountTypeEnum.Patient ? `/${t('menu.ifu_pdf')}` : `/${t('menu.ifu_pdf_doctor')}`}
        target={'_blank'}
        style={{ display: location.pathname.includes('auth') ? 'none' : 'flex' }}
      >
        <ListItemIcon>
          <MenuBook fontSize="small" />
        </ListItemIcon>
        {t('menu.ifu')}
      </MenuItem>
      <MenuItem
        data-testid={'settings-ifu'}
        component={RouterLink}
        to={`/${t('menu.ifu_pdf')}`}
        target={'_blank'}
        style={{ display: !location.pathname.includes('auth') ? 'none' : 'flex' }}
      >
        <ListItemIcon>
          <MenuBook fontSize="small" />
        </ListItemIcon>
        {t('menu.ifuPatient')}
      </MenuItem>
      <MenuItem
        data-testid={'settings-ifu'}
        component={RouterLink}
        to={`/${t('menu.ifu_pdf_doctor')}`}
        target={'_blank'}
        style={{ display: !location.pathname.includes('auth') ? 'none' : 'flex' }}
      >
        <ListItemIcon>
          <MenuBook fontSize="small" />
        </ListItemIcon>
        {t('menu.ifuDoctor')}
      </MenuItem>

      <MenuItem data-testid={'settings-about'} onClick={onAboutClick}>
        <ListItemIcon>
          <Info fontSize="small" />
        </ListItemIcon>
        {t('menu.about')}
      </MenuItem>
      <Divider hidden={!isAuthenticated} />
      {!location.pathname.includes('auth') ? (
        <MenuItem data-testid={'settings-logout'} onClick={() => dispatch(logout())} hidden={!isAuthenticated}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('menu.logout')}
        </MenuItem>
      ) : null}
    </Menu>
  );
};
