import { createInvite } from '@24sens/ecg01-invite';
import AddIcon from '@mui/icons-material/Add';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  DrawerProps as MuiDrawerProps,
  Fab,
  IconButton,
  ListItem,
  ListItemText,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Drawer } from '../layout/Drawer';
import * as selectors from '../store/selectors';
import { useAppDispatch, useAppSelector } from '../store/store';
import { InvitePatientModal } from './InvitePatientModal';
import { PatientList } from './PatientList';
import { PatientSearchInput } from './PatientSearchInput';

export interface PatientListDrawerProps extends MuiDrawerProps {}

const fabStyle: SxProps = {
  position: 'fixed',
  bottom: 16,
  left: 274,
};

export const PatientListDrawer: React.FC<PatientListDrawerProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const [inviteFormOpen, setInviteFormOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const physician = useAppSelector(selectors.auth.userDetails);
  const searchedList = useAppSelector(selectors.patient.filteredPatients);
  const searchString = useAppSelector(selectors.patient.filterText);
  const inviteList = useAppSelector(selectors.invite.filteredInvites);

  return (
    <Drawer {...props} className={'No-Print'}>
      <Card sx={{ minHeight: '9rem' }}>
        <CardHeader
          data-testid={'patienlist-header'}
          avatar={<Avatar />}
          title={physician?.name}
          subheader={<div>{physician?.profile.title}</div>}
        />
        <CardContent>
          <PatientSearchInput />
        </CardContent>
      </Card>
      <PatientList
        patientIds={[...searchedList, ...inviteList]}
        emptyPlaceholder={
          <ListItem
            secondaryAction={
              <IconButton onClick={() => setInviteFormOpen(true)} edge="end" aria-label="delete">
                <AddIcon />
              </IconButton>
            }
          >
            <ListItemText primary={searchString} secondary={t('inviteModal.notFound')} />
          </ListItem>
        }
      />
      <InvitePatientModal
        open={inviteFormOpen}
        email={searchString}
        handleClose={() => setInviteFormOpen(false)}
        onSubmit={(invite) => {
          console.log('Invite', invite);
          dispatch(createInvite(invite)).then(() => {
            enqueueSnackbar(t('inviteModal.sentNotice'), { variant: 'success' });
            setInviteFormOpen(false);
          });
        }}
      />
      <Fab
        data-testid={'patientList-inviteButton'}
        className={'No-Print'}
        color="secondary"
        aria-label="add"
        sx={fabStyle}
        onClick={() => setInviteFormOpen(true)}
      >
        <PersonAddIcon titleAccess={'Add new patient'} />
      </Fab>
    </Drawer>
  );
};
