import React from 'react';
import { useSelector } from 'react-redux';

import * as selectors from '../store/selectors';
import { ErrorDialog } from './ErrorDialog';

export const ErrorComponent: React.FC = (props) => {
  const errorState = useSelector(selectors.apiError);

  return <ErrorDialog error={errorState} />;
};
