import { isPatient, ProfileForm, updateProfile } from '@24sens/ecg01-auth';
import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import * as selectors from '../store/selectors';
import { useAppDispatch, useAppSelector } from '../store/store';

export const Onboarding: React.FC = () => {
  const profileFormRef = useRef<HTMLFormElement | null>(null);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const user = useAppSelector(selectors.auth.userDetails);
  const isProfileValid = useAppSelector(selectors.auth.isProfileValid);
  const isVerified = useAppSelector(selectors.auth.isVerified);
  const [verifyColor, setVerifyColor] = useState('black');

  useEffect(() => {
    if (isProfileValid) {
      history.push('/physician');
    }
  }, [isProfileValid, isVerified]);

  if (!user) {
    return <div>Error, no user set</div>;
  }
  return (
    <Card>
      <CardHeader title={t('profile.enterProfileTitle')} />
      <CardContent>
        <Typography variant="body2">{t('profile.note')}</Typography>
        {!isVerified ? <p style={{ color: verifyColor }}>{t('onboarding.verifyNeeded')}</p> : ''}
        <ProfileForm
          ref={profileFormRef}
          profile={user.profile}
          onSubmit={(data) => {
            dispatch(updateProfile(data))
              .then(unwrapResult)
              .then(() => {
                enqueueSnackbar(t('profile.snackbar'), { variant: 'success' });
                setVerifyColor('red');
              });
          }}
          variant={'outlined'}
          skipAddress={isPatient(user)}
        />
      </CardContent>
      <CardActions>
        <Button onClick={() => profileFormRef.current?.submit()}>{t('buttons.save')}</Button>
      </CardActions>
    </Card>
  );
};
