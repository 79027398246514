import {
  AccountTypeEnum,
  ApiError,
  AuthApi,
  deleteToken,
  LoginRequest,
  PasswordChangeRequest,
  PasswordResetRequest,
  Patient,
  PatientProfile,
  Physician,
  PhysicianProfile,
  Profile,
  ProfileApi,
  RegisterRequest,
  RejectedPayloadAction,
  RestAuthDetail,
  serializeError,
  setToken,
  User,
} from '@24sens/ecg01-rest-client';
import { wrap } from '@24sens/utils';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const isPhysician = (obj: User | null): obj is Physician =>
  obj?.type === AccountTypeEnum.Physician && isPhysicianProfile(obj.profile);
export const isPatient = (obj: User | null): obj is Patient =>
  obj?.type === AccountTypeEnum.Patient && isPatientProfile(obj.profile);

export const isPhysicianProfile = (obj: object): obj is PhysicianProfile => 'registration_number' in obj;
export const isPatientProfile = (obj: object): obj is PatientProfile => 'social_security_number' in obj;

export interface Services {
  auth: AuthApi;
  profile: ProfileApi;
}

export interface AuthState {
  token: string | null;
  user: User | null;
  loading: 'idle' | 'pending' | 'failed';
  error: ApiError | null;
}

export const updateProfile = createAsyncThunk<Profile, Profile, { rejectValue: ApiError; extra: Services }>(
  'ecg01-auth/updateProfile',
  async (profile, thunkApi) => {
    if (!profile.id) {
      thunkApi.rejectWithValue({
        name: 'Error',
        message: "Can't update profile. No id set.",
      });
    }
    const response = await thunkApi.extra.profile.partialUpdate(profile.id, profile);

    return response.data;
  },
  { serializeError },
);

export const login = createAsyncThunk<string, LoginRequest, { rejectValue: ApiError; extra: Services }>(
  'ecg01-auth/login',
  async (loginData, thunkApi) => {
    const response = await thunkApi.extra.auth.loginCreate(loginData);
    // eslint-disable-next-line
    return (response.data as any).key as string;
  },
  { serializeError },
);

export const logout = createAsyncThunk<boolean, undefined, { rejectValue: ApiError; extra: Services }>(
  'ecg01-auth/logout',
  async (u = undefined, thunkApi) => {
    deleteToken();
    await thunkApi.extra.auth.logoutCreate();
    return true;
  },
  { serializeError },
);

export const register = createAsyncThunk<string, RegisterRequest, { rejectValue: ApiError; extra: Services }>(
  'ecg01-auth/register',
  async (registrationData, thunkApi) => {
    const api = thunkApi.extra.auth;
    const response = await api.registrationCreate(registrationData);
    // eslint-disable-next-line
    return (response.data as any).key;
  },
  { serializeError },
);

export const changePassword = createAsyncThunk<
  RestAuthDetail,
  PasswordChangeRequest,
  { rejectValue: ApiError; extra: Services }
>('ecg01-auth/changePassword', async (passwordChange, thunkApi) => {
  const response = await thunkApi.extra.auth.passwordChangeCreate(passwordChange);
  return response.data;
});

export const resetPassword = createAsyncThunk<
  RestAuthDetail,
  PasswordResetRequest,
  { rejectValue: ApiError; extra: Services }
>('ecg01-auth/resetPassword', async (passwordReset, thunkApi) => {
  const response = await thunkApi.extra.auth.passwordResetCreate(passwordReset);
  return response.data;
});

export const fetchUser = createAsyncThunk<User, undefined, { rejectValue: ApiError; extra: Services }>(
  'ecg01-auth/user',
  async (t, thunkApi) => {
    const userDetails = (await thunkApi.extra.auth.userRetrieve()).data;
    if (isPhysician(userDetails as User)) {
      return userDetails as Physician;
    }
    if (isPatient(userDetails as User)) {
      return userDetails as Patient;
    }
    throw Error('Unknown user type');
  },
  { serializeError },
);

export const initialAuthState: AuthState = {
  token: null,
  user: null,
  loading: 'idle',
  error: null,
};

export const buildSlice = <R>(sliceSelector: (state: R) => AuthState, initialState: AuthState = initialAuthState) => {
  const setPending = (state: AuthState) => {
    state.loading = 'pending';
  };

  const onFulfilledSetToken = (state: AuthState, action: PayloadAction<string>) => {
    state.loading = 'idle';
    setToken(action.payload);
    state.token = action.payload;
  };

  const onFulfilledSetUser = (state: AuthState, action: PayloadAction<User>) => {
    state.loading = 'idle';
    state.user = action.payload;
  };

  const onFulfilledSetProfile = (state: AuthState, action: PayloadAction<Profile>) => {
    state.loading = 'idle';
    if (isPatient(state.user) && isPatientProfile(action.payload)) {
      state.user = { ...state.user, profile: action.payload };
    } else if (isPhysician(state.user) && isPhysicianProfile(action.payload)) {
      state.user = { ...state.user, profile: action.payload };
    } else {
      throw new Error('');
    }
  };

  const onFulfilledLogout = (state: AuthState) => {
    state.loading = 'idle';
    state.token = null;
    deleteToken();
    state.user = null;
  };

  const onRejected = (state: AuthState, action: RejectedPayloadAction<ApiError, ApiError>) => {
    state.loading = 'failed';
    const error = action.payload ?? action.error;
    if (error.code === '401') {
      state.token = null;
      state.user = null;
      deleteToken();
    }
    if (action.type === 'ecg01-auth/login/rejected' && `${error.detail}`.includes('verif')) {
      state.error = {
        ...error,
        message: `${error.message}`,
        detail: error.detail,
        options: { text: error.options?.text ?? '', callback: slice.actions.resetError },
      };
      return;
    }
    state.error = {
      ...error,
      options: { text: error.options?.text ?? '', callback: slice.actions.resetError },
    };
  };

  const slice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
      resetError: (state) => {
        state.error = null;
      },
      tokenRejected: (state) => {
        state.token = null;
        state.user = null;
        deleteToken();
      },
    },
    extraReducers: (builder) => {
      builder.addCase(updateProfile.pending, setPending);
      builder.addCase(updateProfile.fulfilled, onFulfilledSetProfile);
      builder.addCase(updateProfile.rejected, onRejected);

      builder.addCase(login.pending, setPending);
      builder.addCase(login.fulfilled, onFulfilledSetToken);
      builder.addCase(login.rejected, onRejected);

      builder.addCase(logout.pending, setPending);
      builder.addCase(logout.fulfilled, onFulfilledLogout);
      builder.addCase(logout.rejected, onRejected);

      builder.addCase(register.pending, setPending);
      builder.addCase(register.fulfilled, onFulfilledSetToken);
      builder.addCase(register.rejected, onRejected);

      builder.addCase(fetchUser.pending, setPending);
      builder.addCase(fetchUser.fulfilled, onFulfilledSetUser);
      builder.addCase(fetchUser.rejected, onRejected);
    },
  });

  const localSelectors = {
    isProfileValid: (state: AuthState) => state.user?.profile?.is_valid,
    isRequestPending: (state: AuthState) => state.loading === 'pending',
    backendToken: (state: AuthState) => state.token,
    isAuthenticated: (state: AuthState) => !!state.token,
    userId: (state: AuthState) => state.user?.id,
    userEmail: (state: AuthState) => state.user?.id,
    userDetails: (state: AuthState) => state.user,
    userProfile: (state: AuthState) => state.user?.profile,
    error: (state: AuthState) => state.error,
    validationErrors: (state: AuthState) =>
      Object.fromEntries(Object.entries(state.error?.detail ?? {}).map(([k, v]) => [k, v.join ? v.join('\n') : v])),
    isPatient: (state: AuthState) => (state.user ? isPatient(state.user) : false),
    isPhysician: (state: AuthState) => (state.user ? isPhysician(state.user) : false),
    isVerified: (state: AuthState) => state.user?.verified ?? false,
  } as const;

  const globalSelectors = {
    isProfileValid: wrap(sliceSelector, localSelectors.isProfileValid),
    isRequestPending: wrap(sliceSelector, localSelectors.isRequestPending),
    backendToken: wrap(sliceSelector, localSelectors.backendToken),
    isAuthenticated: wrap(sliceSelector, localSelectors.isAuthenticated),
    userId: wrap(sliceSelector, localSelectors.userId),
    userEmail: wrap(sliceSelector, localSelectors.userEmail),
    userDetails: wrap(sliceSelector, localSelectors.userDetails),
    userProfile: wrap(sliceSelector, localSelectors.userProfile),
    error: wrap(sliceSelector, localSelectors.error),
    validationErrors: wrap(sliceSelector, localSelectors.validationErrors),
    isPatient: wrap(sliceSelector, localSelectors.isPatient),
    isPhysician: wrap(sliceSelector, localSelectors.isPhysician),
    isVerified: wrap(sliceSelector, localSelectors.isVerified),
  } as const;

  return {
    ...slice,
    selectors: globalSelectors,
  };
};
