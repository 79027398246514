import { acceptInvite, AcceptInviteDialog, fetchInvite, rejectInvite } from '@24sens/ecg01-invite';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as selectors from '../store/selectors';
import { useAppDispatch, useAppSelector } from '../store/store';

export const InviteDialog: React.FC = ({ ...props }) => {
  const { t } = useTranslation();
  const user = useAppSelector(selectors.auth.userDetails);
  const inviteUuid = useAppSelector(selectors.invite.uuid);
  const invite = useAppSelector(selectors.invite.invite);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (inviteUuid) {
      console.debug('PatientLayout: Invite UUID was set, fetching invite. . .');
      dispatch(fetchInvite(inviteUuid));
    }
  }, [inviteUuid]);

  return (
    <>
      <AcceptInviteDialog
        open={!!inviteUuid}
        invite={invite}
        onAccept={() =>
          inviteUuid &&
          user &&
          user.email &&
          dispatch(acceptInvite(inviteUuid))
            .then(unwrapResult)
            .then(() => enqueueSnackbar(t('inviteModal.acceptedDialog.title'), { variant: 'success' }))
        }
        onReject={() =>
          inviteUuid &&
          user &&
          user.email &&
          dispatch(rejectInvite(inviteUuid))
            .then(unwrapResult)
            .then(() => enqueueSnackbar(t('inviteModal.rejectedDialog.title'), { variant: 'error' }))
        }
      />
    </>
  );
};
