// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Tail<T extends unknown[]> = T extends [infer _, ...infer R] ? R : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericSelector<LocalState> = (state: LocalState, ...args: any[]) => any;

export type SliceSelector<R, L> = (state: R) => L;

export type SelectorMap<State, K extends string = string> = {
  [key in K]: GenericSelector<State>;
};

export const wrap = <F extends GenericSelector<L>, L, R>(
  sliceSelector: (state: R) => L,
  func: F,
): ((state: R, ...args: Tail<Parameters<F>>) => ReturnType<F>) => {
  return (state, ...args) => func(sliceSelector(state), ...args);
};

/**

 Different experiments to make conversion of local to global selectors typesafe
 with as least boilerplate code as possible. . .

 type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T];
 function objectToEntries<T extends object>(t: T): Entries<T>[] {
  return Object.entries(t) as any;
}
 type Obj<T> = {[key in Entries<T>[][number][0]]: Entries<T>[][number][1] }
 const entriesToObject = <T>(entries: readonly Entries<T>[]): Obj<T>  => {
  return Object.fromEntries(entries) as any;
}

 export function mapValues<T, V>(obj: T, mapFn: (v: T[keyof T]) => V): {[k in keyof T]: V} {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, mapFn(v)])) as any;
}
 export const wrapSelectors = <
 R,
 L,
 LM extends SelectorMap<L>,
 >(
 selectors: LM,
 sliceSelector: (state: R) => L,
 ) => {

  const x =  objectToEntries(selectors);
  const y= x.map(([key, value]) => [key, wrap(sliceSelector, value)] as const);
  //const z = entriesToObject(y);
  const z = Object.fromEntries(y);

  console.log(z)
  return z
}
 */
