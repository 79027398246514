import { fetchCommentsForRecording } from '@24sens/ecg01-comments';
import { fetchRecordingsOfUser } from '@24sens/ecg01-recordings';
import { TargetTypeEnum } from '@24sens/ecg01-rest-client';
import AddCommentIcon from '@mui/icons-material/AddComment';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PatientDetailsCard } from '../Cards/PatientDetailsCard';
import { TimelineCard } from '../Cards/TimelineCard';
import { useLayout } from '../layout/useLayout';
import * as selectors from '../store/selectors';
import { useAppDispatch, useAppSelector } from '../store/store';
import { EnterCommentModal } from './EnterCommentModal';
import { doPrint } from './PatientTimeline';

export interface RecordingDetailsProps {}

const fabStyle: SxProps = {
  position: 'fixed',
  bottom: 16,
  right: 16,
};

export const RecordingDetails: React.FC<RecordingDetailsProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { patientId, recordingId } = useParams<{ patientId: string; recordingId: string }>();
  const patient = useAppSelector((state) => selectors.patient.selectById(state, patientId));
  const recording = useAppSelector((state) => selectors.recording.selectById(state, recordingId));
  const [commentId, setCommentId] = useState<string | null>(null);
  const timelineEntries = useAppSelector((state) => {
    return selectors.comment.commentsForRecording(state, Number.parseInt(recordingId));
  });

  const { setTitle } = useLayout();

  useEffect(() => {
    if (patientId) {
      dispatch(fetchRecordingsOfUser(Number.parseInt(patientId)));
      dispatch(fetchCommentsForRecording(Number.parseInt(recordingId)));
    }
  }, [recordingId]);

  useEffect(() => {
    if (patient) {
      setTitle(t('dashboard.ecgHeading', { patientName: patient.name }));
    }
  }, [patient]);

  if (!recording) {
    return <div>Error Recording not found </div>;
  }
  return (
    <>
      <Stack className={'Display-block-Print'} spacing={1}>
        <PatientDetailsCard />
        <TimelineCard entry={recording} />
        {timelineEntries
          .sort((a, b) =>
            a.timestamp && b.timestamp ? new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime() : 0,
          )
          .map((entry, index) => (
            <TimelineCard key={entry.id} entry={entry} setOpenEnterComment={setCommentId} />
          ))}
      </Stack>
      <Fab
        className={'No-Print'}
        color="secondary"
        aria-label="add"
        sx={{ ...fabStyle, bottom: '76px' }}
        onClick={doPrint}
      >
        <DownloadRoundedIcon />
      </Fab>
      <Fab className={'No-Print'} color="secondary" aria-label="add" sx={fabStyle} onClick={() => setCommentId('')}>
        <AddCommentIcon />
      </Fab>
      <EnterCommentModal
        open={commentId != null}
        handleClose={() => setCommentId(null)}
        patientOrRecordingId={Number.parseInt(recordingId)}
        commentId={commentId ? Number.parseInt(commentId): undefined}
        target_type={TargetTypeEnum.Recording}
      />
    </>
  );
};
