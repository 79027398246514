import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface RecordingOverviewChartProps {
  recordingId: number;
}

export const RecordingSummary: React.FC<RecordingOverviewChartProps> = ({ recordingId, ...props }) => {
  const { t } = useTranslation();
  return <Typography>{t('timeline.plotCard.info')}</Typography>;
};
