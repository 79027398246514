import {LoginRequest} from "@24sens/ecg01-rest-client";
import { LabelVarient, PasswordField, TextInputField, validateMail } from '@24sens/ui';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

interface LoginFormState {
  email: string;
  emailError?: string;
  password: string;
}

interface LoginFormProps {
  onSubmit: (data: LoginRequest) => void;
  fieldErrors?: { [field: string]: unknown };
  variant?: LabelVarient;
  style?: CSSProperties;
}

export const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, fieldErrors, variant, ...props }) => {
  const { t } = useTranslation();
  const [formState, setFormState] = React.useState<LoginFormState>({
    email: '',
    password: '',
  });
  function updateFormState(update: Partial<typeof formState>) {
    setFormState({ ...formState, ...update });
  }
  function validateAndSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const emailError = validateMail(formState.email, t) ?? undefined;
    updateFormState({ emailError });
    if (!emailError) {
      onSubmit({
        email: formState.email.trim(),
        password: formState.password,
      });
    }
  }

  return (
    <form onSubmit={validateAndSubmit}>
      <Stack>
        <TextInputField
          data-testid="login-mail"
          value={formState.email}
          onChange={(e) => updateFormState({ email: e.target.value, emailError: undefined })}
          errorMessage={formState?.emailError}
          label={t('onboarding.hintMail')}
          type={'email'}
          autoComplete={'email'}
          style={props.style}
          variant={variant}
        />
        <PasswordField
          value={formState.password}
          onChange={(e) =>
            updateFormState({
              password: e.target.value,
            })
          }
          label={t('onboarding.hintPasswordFirst')}
          style={props.style}
          variant={variant}
        />
        <div style={{ height: '12px' }} />
        <Button data-testid="login-button" color={'primary'} type={'submit'} variant={'contained'} style={props.style}>
          {t('buttons.login')}
        </Button>
      </Stack>
    </form>
  );
};
