import { upload } from '@24sens/ecg01-recordings';
import { TileComponent } from '@24sens/ui';
import BarChartIcon from '@mui/icons-material/BarChart';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Avatar, Box, Container, Typography } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as selectors from '../store/selectors';
import { useAppDispatch } from '../store/store';

export const UploadField: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const userId = useSelector(selectors.auth.userId);

  const [progress, setProgress] = useState(0);

  const { getRootProps, getInputProps } = useDropzone({
    accept: [
      '',
      'text/xml',
      'text/gpx',
      'text/gpx+xml',
      'application/gpx+xml',
      'application/xml',
      'application/gpx',
      '.QUS',
      '.qus',
    ],
    maxFiles: 1,
    onDrop: (acceptedFiles, fileRejections, event) => {
      console.log('Event', event);
      console.log('Accepted files', acceptedFiles);
      console.log('Rejected files', fileRejections);
      if (!userId) {
        return;
      }
      fileRejections.forEach((file) => {
        console.log('File: ', file.file.name, file.file.type);
      });
      acceptedFiles.forEach((file) => {
        console.log('Uploading', file);
        dispatch(upload({ userId: userId, file, progressCallback: setProgress }))
          .then(unwrapResult)
          .then((r) => {
            enqueueSnackbar(t('uploadPatient.success'), { variant: 'success' });
          });
      });
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ref, ...rootProps } = getRootProps();

  return (
    <TileComponent
      image={
        <Avatar sx={{ bgcolor: 'var(--twentyfoursens-blue)' }}>
          <BarChartIcon />
        </Avatar>
      }
      primaryText={t('uploadPatient.text')}
    >
      <Container
        {...rootProps}
        sx={{
          mx: 'auto',
          textAlign: 'center',
          borderRadius: '4px',
          padding: '20px',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: 'gray',
        }}
      >
        <input {...getInputProps()} />
        <FileUploadIcon style={{ color: 'var(--ion-color-light-gray)' }} />
        <h3 style={{ fontSize: '1rem' }}>{t('uploadPatient.boxText')}</h3>
        <p>{t('uploadPatient.boxHint')}</p>
        <LinearProgressWithLabel value={progress} />
      </Container>
    </TileComponent>
  );
};

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
