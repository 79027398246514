import { Invite } from '@24sens/ecg01-rest-client';
import { TextInputField } from '@24sens/ui';
import { Box, Modal, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface InvitePatientFormProps {
  onSubmit: (data: Invite) => void;
  email?: string;
}

interface InvitePatientModalProps extends InvitePatientFormProps {
  open: boolean;
  handleClose(): void;
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export const InvitePatientModal: React.FC<InvitePatientModalProps> = ({
  open,
  handleClose,
  onSubmit,
  email = '',
  ...props
}) => {
  const { t } = useTranslation();
  const { handleSubmit, control, reset } = useForm<Invite>();

  const resetAndClose = () => {
    reset();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={resetAndClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={style}>
          <Stack spacing={2}>
            <Typography gutterBottom id="modal-modal-title" variant="h6" component="h2">
              {t('inviteModal.title')}
            </Typography>
            <Typography id="modal-modal-description" paragraph>
              {t('inviteModal.description')}
            </Typography>

            <Controller
              name={'recipient_email'}
              control={control}
              defaultValue={email}
              render={({ field: { ref, ...rest } }) => (
                <TextInputField
                  data-testid={'invite-mail'}
                  label={t('onboarding.hintMail')}
                  type={'email'}
                  autoComplete={'email'}
                  {...rest}
                />
              )}
            />
            <Stack direction={'row'} spacing={2} justifyContent="flex-end">
              <Button onClick={resetAndClose}>{t('buttons.cancel')}</Button>
              <Button data-testid={'invite-submit'} type={'submit'} variant={'contained'}>
                {t('buttons.send')}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
};
