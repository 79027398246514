import inviteSlice from '@24sens/ecg01-invite';
import { combineReducers } from '@reduxjs/toolkit';

import authSlice from '../auth';
import commentSlice from '../comment';
import notificationSlice from '../notifications';
import patientSlice from '../patient';
import physicianSlice from '../physician';
import recordingSlice from '../recording';
import signalSlice from '../signal';

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  recording: recordingSlice.reducer,
  comment: commentSlice.reducer,
  invite: inviteSlice.reducer,
  patient: patientSlice.reducer,
  notifications: notificationSlice.reducer,
  physician: physicianSlice.reducer,
  signal: signalSlice.reducer,
});
