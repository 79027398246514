import {VisibilityEnum} from "@24sens/ecg01-rest-client";
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export interface VisibilitySelectProps extends SelectProps<"patient" | "private" | "public" | undefined> {

}

export const VisibilitySelect: React.FC<VisibilitySelectProps> = ({value, onChange, defaultValue}) => {
  const {t} = useTranslation();

  console.log("Select: ", value, defaultValue)

  return (
    <FormControl fullWidth>
    <InputLabel id="demo-simple-select-label">{t("notes.visibility.label")}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        defaultValue={defaultValue}
        value={value}
        label={t("notes.notesDoctor.visibility")}
        onChange={onChange}
        >
      <MenuItem value={VisibilityEnum.Private}>{t("notes.visibility.private")}</MenuItem>
      <MenuItem value={VisibilityEnum.Patient}>{t("notes.visibility.patient")}</MenuItem>
      <MenuItem value={VisibilityEnum.Public}>{t( "notes.visibility.public")}</MenuItem>
    </Select>
  </FormControl>
)
}
