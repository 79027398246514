import { ApiError, Physician, PhysicianApi, RejectedPayloadAction, serializeError } from '@24sens/ecg01-rest-client';
import { HasId } from '@24sens/utils';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Draft,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

export interface Services {
  physician: PhysicianApi;
}

export interface PhysicianState extends EntityState<IPhysician> {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: ApiError | null;
}

export type IPhysician = HasId<Physician>;

export const adapter = createEntityAdapter<IPhysician>({
  selectId: (physician) => physician.id,
  sortComparer: (a, b) => (b.name && a.name ? b.name.localeCompare(a.name) : 0),
});

export const initialPhysicianState: PhysicianState = adapter.getInitialState({
  loading: 'idle',
  error: null,
});

export const fetchPhysician = createAsyncThunk<IPhysician, { id: number }, { rejectValue: ApiError; extra: Services }>(
  'physician',
  async ({ id }, thunkApi) => {
    const response = await thunkApi.extra.physician.retrieve(id);
    return response.data as IPhysician;
  },
  { serializeError },
);

export const buildSlice = <R>(
  sliceSelector: (state: R) => PhysicianState,
  initialState: PhysicianState = initialPhysicianState,
) => {
  const setPending = (state: Draft<PhysicianState>) => {
    state.loading = 'pending';
  };

  const onPhysicianFulfilled = (state: PhysicianState, action: PayloadAction<IPhysician>) => {
    state.loading = 'succeeded';
    adapter.upsertOne(state, action.payload);
  };

  const onRejected = (state: PhysicianState, action: RejectedPayloadAction<ApiError, ApiError>) => {
    state.loading = 'failed';
    const error = action.payload ?? action.error;
    if (action.payload) {
      state.error = action.payload;
    } else {
      state.error = {
        ...error,
        code: error.code ?? 'UNKNOWN',
        message: action.error.message ?? action.error.name ?? 'Unknown Error',
        options: { text: error.options?.text ?? '', callback: slice.actions.resetError },
      };
    }
  };

  const slice = createSlice({
    name: 'physician',
    initialState: initialState,
    reducers: {
      resetError: (state) => {
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchPhysician.pending, setPending);
      builder.addCase(fetchPhysician.fulfilled, onPhysicianFulfilled);
      builder.addCase(fetchPhysician.rejected, onRejected);
    },
  });

  const physicianSelector = adapter.getSelectors(sliceSelector);
  const physicians = physicianSelector.selectAll;

  const physicianIdsOfPatient = createSelector(physicians, (physicians) => physicians.map((p) => p.id));

  return {
    ...slice,
    selectors: {
      ...physicianSelector,
      physicians,
      physicianById: physicianSelector.selectById,
      physicianIdsOfPatient,
    },
  } as const;
};

const physcianSlice = buildSlice((state: { physician: PhysicianState }) => state.physician);
export default physcianSlice;
export const selectors = physcianSlice.selectors;
