import { AccountTypeEnum } from '@24sens/ecg01-rest-client';
import {
  LabelVarient,
  PasswordField,
  TextInputField,
  validateMail,
  validatePassword,
  validatePasswordEquality,
  validateRequiredInputCheck,
  validateUserName,
} from '@24sens/ui';
import { useIonAlert } from '@ionic/react';
import { Button, Divider, FormHelperText, Switch } from '@mui/material';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

interface RegistrationFormState {
  name: string;
  nameError?: string;
  email: string;
  emailError?: string;
  password: string;
  passwordError?: string;
  password2: string;
  password2Error?: string;
  dataDisclosureChecked: boolean;
  dataDisclosureCheckedError: string | null;
}

interface RegistrationFormProps {
  onSubmit: (data: { password: string; name: string; type: 'patient'; email: string }) => void;
  fieldErrors: { [field: string]: string };
  variant?: LabelVarient;
  style?: CSSProperties;
}

export const RegistrationForm: React.FC<RegistrationFormProps> = ({
  onSubmit,
  fieldErrors,
  variant = 'standard',
  ...props
}) => {
  const { t } = useTranslation();
  const [formState, setFormState] = React.useState<RegistrationFormState>({
    name: '',
    email: '',
    password: '',
    password2: '',
    dataDisclosureChecked: false,
    dataDisclosureCheckedError: null,
  });

  function updateFormState(update: Partial<typeof formState>) {
    setFormState({ ...formState, ...update });
  }

  function validateAndSubmit() {
    const nameError = validateUserName(formState.name, t) ?? undefined;
    const emailError = validateMail(formState.email, t) ?? undefined;
    const passwordError = validatePassword(formState.password, t, 8) ?? undefined;
    const password2Error = validatePasswordEquality(formState.password, formState.password2, t) ?? undefined;
    const dataDisclosureCheckedError = validateRequiredInputCheck(formState.dataDisclosureChecked, t);
    updateFormState({ nameError, emailError, passwordError, password2Error, dataDisclosureCheckedError });
    if (!nameError && !emailError && !passwordError && !password2Error && !dataDisclosureCheckedError) {
      onSubmit({
        name: formState.name.trim(),
        email: formState.email.trim(),
        type: AccountTypeEnum.Patient,
        password: formState.password,
      });
    }
  }

  return (
    <>
      <TextInputField
        data-testid="signup-name"
        value={formState.name}
        onChange={(e) => updateFormState({ name: e.target.value, nameError: undefined })}
        errorMessage={formState.nameError}
        label={t('onboarding.hintName')}
        type={'text'}
        variant={variant}
        style={props.style}
      />
      <TextInputField
        data-testid="signup-mail"
        value={formState.email}
        onChange={(e) => updateFormState({ email: e.target.value, emailError: undefined })}
        errorMessage={formState?.emailError}
        label={t('onboarding.hintMail')}
        type={'email'}
        variant={variant}
        style={props.style}
      />
      <PasswordField
        testId={'text-enterPw1'}
        value={formState.password}
        onChange={(e) =>
          updateFormState({
            password: e.target.value,
            passwordError: undefined,
          })
        }
        errorMessage={formState?.passwordError}
        label={t('onboarding.hintPasswordFirst')}
        style={props.style}
        variant={variant}
      />
      <PasswordField
        testId={'text-enterPw2'}
        value={formState.password2}
        onChange={(e) =>
          updateFormState({
            password2: e.target.value,
            password2Error: undefined,
          })
        }
        errorMessage={formState?.password2Error}
        label={t('onboarding.hintPasswordSecond')}
        style={props.style}
        variant={variant}
      />
      <div style={{ height: '12px' }} />
      <Divider style={{ width: '100%' }} />
      <DataDisclosureSwitch
        checked={formState.dataDisclosureChecked}
        setChecked={(checked) =>
          updateFormState({
            dataDisclosureChecked: checked,
            dataDisclosureCheckedError: validateRequiredInputCheck(checked, t),
          })
        }
        error={formState.dataDisclosureCheckedError}
      />
      <Divider style={{ width: '100%' }} />
      <AdditionalInfo />
      <Divider style={{ width: '100%' }} />
      <div style={{ height: '12px' }} />
      <Button
        data-testid="signup-button"
        variant={'contained'}
        onClick={validateAndSubmit}
        style={{ backgroundColor: 'var(--twentyfoursens-blue)', ...props.style }}
      >
        {t('buttons.forward')}
      </Button>
    </>
  );
};

const DataDisclosureSwitch: React.FC<{
  checked: boolean;
  setChecked: (checked: boolean) => void;
  error: string | null;
}> = (props) => {
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();

  function handleChange() {
    props.setChecked(!props.checked);
  }

  return (
    <div style={acceptSwitchOuterDivCSS}>
      <div style={acceptSwitchMiddleDivCSS} onClick={handleChange}>
        <div
          style={{
            ...acceptSwitchInnerDivCSS,
            color: getErrorColor(props.error),
          }}
        >
          <div style={{ paddingRight: '6px' }}>
            {t('onboarding.subtextIfuAccept') + ' '}
            <button
              className={'link-button'}
              onClick={() => {
                presentAlert({
                  header: t('dataCollectionDisclosure.title'),
                  message: t('dataCollectionDisclosure.text') + '<br/><br/>' + t('dataCollectionDisclosure.bullet1'),
                  buttons: [
                    {
                      text: t('generic.ok'),
                      role: 'confirm',
                      handler: () => {
                        props.setChecked(true);
                      },
                    },
                  ],
                });
              }}
            >
              {t('dataCollectionDisclosure.title')}
            </button>
          </div>
        </div>
        <Switch
          data-testid={'signup-switch'}
          disabled={false}
          checked={props.checked}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>
      <FormHelperText style={{ color: getErrorColor(props.error) }}>{props.error}</FormHelperText>
    </div>
  );
};

const AdditionalInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div style={acceptSwitchOuterDivCSS}>
      <div style={acceptSwitchMiddleDivCSS}>
        <div
          style={{
            ...acceptSwitchInnerDivCSS,
          }}
        >
          <div style={{ paddingRight: '6px' }}>
            {t('onboarding.subtextInfo')} <a href={t('links.ifu')}>{t('menu.ifu')}</a>
            {', '}
            <a href={t('links.privacyPolicy')}>{t('menu.dsgvo')}</a>
            {', '}
            <a href={t('links.terms')}>{t('menu.tac')}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

const acceptSwitchOuterDivCSS: CSSProperties = {
  width: '100%',
  paddingLeft: '1em',
  paddingRight: '1em',
  marginTop: '0.2em',
};
const acceptSwitchMiddleDivCSS: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const acceptSwitchInnerDivCSS: CSSProperties = {
  fontSize: '0.8em',
  paddingTop: '8px',
  paddingBottom: '8px',
};

export function getErrorColor(error: string | null): string | undefined {
  if (error) {
    return '#d32f2f';
  } else {
    return 'var(--ion-text-color)';
  }
}
