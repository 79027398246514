import { ProfileForm, updateProfile } from '@24sens/ecg01-auth';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import * as selectors from '../store/selectors';
import { useAppDispatch, useAppSelector } from '../store/store';

const ThemedDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface PhysicianProfileModalProps extends Partial<DialogProps> {
  open: boolean;
  openPWChange(): void;
  onClose(): void;
}

export const PhysicianProfileDialog: React.FC<PhysicianProfileModalProps> = ({ openPWChange, ...props }) => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const profile = useAppSelector(selectors.auth.userProfile);
  const isPatient = useAppSelector(selectors.auth.isPatient);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <>
      <ThemedDialog aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" {...props} fullWidth>
        <DialogTitle>{t('profile.profile')}</DialogTitle>
        <DialogContent>
          {profile ? (
            <ProfileForm
              ref={formRef}
              profile={profile}
              variant={'outlined'}
              onSubmit={(data) => {
                dispatch(updateProfile(data))
                  .then(unwrapResult)
                  .then(() => {
                    enqueueSnackbar(t('profile.snackbar'), { variant: 'success' });
                    props.onClose();
                  });
              }}
              style={{ marginTop: '6px' }}
              skipAddress={isPatient}
            />
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            data-testid={'profile-pwChangeButton'}
            onClick={() => {
              openPWChange();
              props.onClose();
            }}
            sx={{ marginRight: 'auto' }}
          >
            {t('passwordChangeDialog.button')}
          </Button>
          <Button onClick={() => props.onClose()}>{t('buttons.cancel')}</Button>
          <Button onClick={() => formRef.current?.submit()}>{t('buttons.save')}</Button>
        </DialogActions>
      </ThemedDialog>
    </>
  );
};
