import { Box, BoxProps, Container, ContainerProps, styled } from '@mui/material';
import React from 'react';

interface ContentProps extends BoxProps {}

export const BaseContent = styled(Box)<ContentProps>(({ theme }) => {
  return {
    paddingTop: theme.spacing(3),
    marginTop: 65,
    width: '100%',
  };
});

export const Content: React.FC<ContainerProps> = (props = { maxWidth: 'md', sx: { py: 5 } }) => {
  return (
    <BaseContent>
      <Container {...props}>
        {React.Children.map(props.children, (c) => (
          <>{c}</>
        ))}
      </Container>
    </BaseContent>
  );
};
