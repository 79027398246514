import { IComment } from '@24sens/ecg01-comments';
import { Tag, TagChip } from '@24sens/ecg01-tags';
import { TileComponent } from '@24sens/ui';
import BarChartIcon from '@mui/icons-material/BarChart';
import CreateIcon from '@mui/icons-material/Create';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { red } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import { differenceInHours } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as selectors from '../store/selectors';
import { CardAvatar } from './CardAvatar';
import { VisibilityIcon } from './VisibilityIcon';

const CommentCardAction: React.FC<CommentCardProps> = ({ entry, setOpenEnterComment }) => {
  const { t } = useTranslation();
  const loggedInPhysician = useSelector(selectors.auth.userDetails);
  if (loggedInPhysician?.id === entry?.creator_id) {
    return (
      <Button onClick={() => (setOpenEnterComment ? setOpenEnterComment(entry.id.toString()) : undefined)}>
        {t('buttons.edit')}
      </Button>
    );
  }
  return null;
};

interface CommentCardProps {
  entry: IComment;
  setOpenEnterComment?(value: string): void;
}
export const CommentCard: React.FC<CommentCardProps> = ({ entry, setOpenEnterComment }) => {
  const { t } = useTranslation();
  const userId = useSelector(selectors.auth.userId);

  let avatar;
  let primaryText;
  let secondaryText;
  let content;

  if (entry.tags && !entry.body) {
    avatar = <Avatar sx={{ bgcolor: 'var(--twentyfoursens-blue)' }}>{entry.creator_name.charAt(0)}</Avatar>;
    primaryText = entry.creator_name + ' ' + t('dashboard.ecgNotes');
    secondaryText = t('timeline.commentTimeStamp.subtitle', {
      start_time: entry.timestamp ? new Date(entry.timestamp) : '',
    });
    content = (
      <Stack direction={'row'} spacing={2} overflow={'auto'}>
        {entry.tags?.map((tag, index) => (
          <TagChip data-testid={'comment-tag_' + entry.id + '_' + index} key={index} tag={tag as Tag} />
        ))}
      </Stack>
    );
  } else if (entry.body) {
    avatar = <CardAvatar icon={<CreateIcon />} />;
    primaryText = t('notes.notesDoctor.personalNote', { creator_name: entry.creator_name });

    secondaryText =
      entry.start_time && entry.end_time
        ? t('timeline.plotCard.subtitle', {
            start_time: new Date(entry.start_time),
            end_time: new Date(entry.end_time),
          })
        : t('timeline.commentTimeStamp.subtitle', {
            start_time: entry.start_time ? new Date(entry.start_time) : '',
          });
    content = (
      <>
        <Typography data-testid={'comment-header_' + entry.id} variant={'h5'} style={{ marginTop: 0 }}>
          {entry.header}
        </Typography>
        <Typography data-testid={'comment-body_' + entry.id} variant={'subtitle1'}>
          {entry.body}
        </Typography>
      </>
    );
  } else {
    avatar = <CardAvatar color={red[500]} icon={<BarChartIcon />} />;
    primaryText = t('dashboard.ecgAnomaly');
    secondaryText =
      (!entry.start_time || !entry.end_time) ?? differenceInHours(new Date(entry.start_time), new Date(entry.end_time));
    content = undefined;
  }

  return (
    <TileComponent
      testId={'comment_' + entry.id}
      image={avatar}
      primaryText={primaryText}
      secondaryText={
        <>
          {secondaryText}
          {entry.creator_id === userId ? <VisibilityIcon comment={entry} /> : null}
        </>
      }
      headerAction={<CommentCardAction entry={entry} setOpenEnterComment={setOpenEnterComment} />}
    >
      {content}
    </TileComponent>
  );
};
