import { IRecording, markRecordingAsSeen } from '@24sens/ecg01-recordings';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import * as selectors from '../store/selectors';
import { useAppDispatch } from '../store/store';

export interface PlotActionProps {
  entry: IRecording;
}
export const DetailsButton: React.FC<PlotActionProps> = ({ entry, ...props }) => {
  const { t } = useTranslation();

  return (
    <Button
      component={RouterLink}
      to={generatePath(':patientId/recordings/:recordingId', {
        patientId: entry.patient_id,
        recordingId: entry.id,
      })}
    >
      {t('buttons.detail')}
    </Button>
  );
};

export const SeenButton: React.FC<PlotActionProps> = ({ entry, ...props }) => {
  const { t } = useTranslation();
  const userId = useSelector(selectors.auth.userId);
  const seen = !!userId && entry.seen_by?.includes(userId);
  const dispatch = useAppDispatch();

  return (
    <Button
      data-testid={'plot-seenButton_' + entry.id}
      onClick={() => dispatch(markRecordingAsSeen(entry))}
      style={{ visibility: seen || !entry.outputfile ? 'hidden' : 'visible' }}
    >
      {t('timeline.plotCard.markSeen')}
    </Button>
  );
};
