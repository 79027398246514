import Avatar from '@mui/material/Avatar';
import React from 'react';

export interface CardAvatarProps {
  icon?: React.ReactElement;
  color?: string;
}

export const CardAvatar: React.FC<CardAvatarProps> = (props) => {
  return <Avatar sx={{ bgcolor: props.color ?? 'var(--twentyfoursens-blue)' }}>{props.icon}</Avatar>;
};
