import { useEffect } from 'react';

import * as selectors from '../store/selectors';
import { useAppDispatch, useAppSelector } from '../store/store';
import { ChartSignal, fetchSignal, fetchSignalChunk } from './slice';

export interface SignalChartData {
  mv: {
    x: Date[];
    y: number[];
  };
  rpeaks: {
    x: Date[];
    y: number[];
  };
  heartrate: {
    x: Date[];
    y: number[];
    text: string[];
  };
}

export const useSignalChunk = (recordingId: number, chunkIdx: number): SignalChartData => {
  const dispatch = useAppDispatch();
  const chunk = useAppSelector((state) => selectors.signal.chunk(state, { recordingId, chunkIdx }));
  const busy = useAppSelector(selectors.signal.isBusy);

  useEffect(() => {
    if (!busy && !chunk) {
      dispatch(fetchSignalChunk({ recordingId: recordingId, chunkIdx: chunkIdx }));
    }
  }, [chunk, busy]);

  return {
    mv: {
      x: chunk?.mv.time ?? [],
      y: chunk?.mv.value ?? [],
    },
    rpeaks: {
      x: chunk?.r_peaks.time ?? [],
      y: chunk?.r_peaks.value ?? [],
    },
    heartrate: {
      x: chunk?.heartrate.time ?? [],
      y: chunk?.heartrate.value ?? [],
      text: chunk?.heartrate.value.map((v) => v.toFixed(0)) ?? [],
    },
  };
};

export const useSignal = (recordingId: number): ChartSignal | null => {
  const dispatch = useAppDispatch();
  const signal = useAppSelector((state) => selectors.signal.selectById(state, recordingId));
  const busy = useAppSelector(selectors.signal.isBusy);

  useEffect(() => {
    console.log('useSignal useEffect triggered', recordingId, busy, signal);
    if (!busy && !signal) {
      dispatch(fetchSignal(recordingId));
    }
  }, [signal, busy, recordingId]);

  return signal ?? null;
};
