import { login, LoginForm } from '@24sens/ecg01-auth';
import { Button, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLayout } from '../layout/useLayout';
import * as selectors from '../store/selectors';
import { useAppDispatch, useAppSelector } from '../store/store';
import { PasswordResetDialog } from './PasswordResetDialog';

export const LoginScreen: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [openPWR, setOpenPWR] = useState(false);

  const validationErrors = useAppSelector(selectors.auth.validationErrors);
  const { setTitle } = useLayout();

  useEffect(() => {
    setTitle(t('onboarding.loginHeading'));
  }, []);

  return (
    <>
      <Card style={{ display: 'flex', flexDirection: 'column', padding: '12px' }}>
        <LoginForm
          onSubmit={(data) => dispatch(login(data))}
          fieldErrors={validationErrors}
          style={{ marginBottom: '12px' }}
          variant={'outlined'}
        />
        <Typography align={'center'}>
          <Button variant={'text'} component={Link} to={'/auth/signup'} style={{ color: 'var(--twentyfoursens-blue)' }}>
            {t('onboarding.signUpNoAccount')}
          </Button>
        </Typography>
      </Card>
      <Typography align={'center'} sx={{ paddingTop: '24px' }}>
        <Button variant={'text'} onClick={() => setOpenPWR(true)} style={{ color: 'var(--twentyfoursens-blue)' }}>
          {t('passwordResetDialog.button')}
        </Button>
      </Typography>
      <PasswordResetDialog open={openPWR} handleClose={() => setOpenPWR(false)} />
    </>
  );
};
