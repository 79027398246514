import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useLayout } from '../layout/useLayout';
import { ReactComponent as Logo } from '../resources/images/24sens-logo.svg';

export const Overview: React.FC = () => {
  const { t } = useTranslation();
  const { setTitle } = useLayout();

  useEffect(() => {
    setTitle(t('dashboard.patientHeading'));
  }, []);

  return (
    <Stack justifyContent="center" alignItems="center" spacing={1} style={{ width: '100%', marginTop: '10vh' }}>
      <Logo style={{ width: '50%', height: 'auto' }} />
      <Box sx={{ width: '50%',textAlign: 'center'}}>
        <Typography variant="h3" sx= {{marginTop: '6vh'}} >
          {t('profile.overviewInfoHeader')}
        </Typography>
        <Typography variant="body1" sx={{whiteSpace: 'pre-line'}}>
        {t('profile.overviewInfoText')}
        </Typography>
      </Box>
    </Stack>
  );
};
