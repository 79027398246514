import React from 'react';

import { InviteDialog } from '../invite/InviteDialog';
import { AppBar } from '../layout/AppBar';
import { Content } from '../layout/Content';
import { Toolbar } from '../layout/Toolbar';
import { PatientRouter } from './PatientRouter';

export const PatientLayout: React.FC = () => {
  return (
    <>
      <AppBar>
        <Toolbar />
      </AppBar>
      <Content>
        <PatientRouter />
        <InviteDialog />
      </Content>
    </>
  );
};
