import { Invite } from '@24sens/ecg01-rest-client';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AcceptInviteDialogProps {
  open: boolean;
  onAccept: () => void;
  onReject: () => void;
  invite: Invite | null;
}

export const AcceptInviteDialog: React.FC<AcceptInviteDialogProps> = ({ open, onAccept, onReject, invite }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Typography variant={'h6'}>{t('inviteModal.acceptDialog.title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('inviteModal.acceptDialog.text', { physician: invite?.sender_infos })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAccept}>{t('buttons.accept')}</Button>
        <Button onClick={onReject} autoFocus>
          {t('buttons.reject')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
