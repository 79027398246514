import { IonButton, IonButtons, IonIcon } from '@ionic/react';
import { Button, ButtonBaseProps, ButtonBaseTypeMap, Typography } from '@mui/material';
import { chevronBackOutline } from 'ionicons/icons';
import React, { CSSProperties } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export interface StandardButtonProps extends ButtonBaseProps {
  cssprops?: CSSProperties;
  text: string;
  //onClick(): void;
  startIcon?: JSX.Element;
  disabled?: boolean;
}

export const StandardButton: React.FC<StandardButtonProps> = ({ cssprops, text, startIcon, disabled, ...props }) => {
  return (
    <Typography align="center">
      <Button
        disabled={disabled}
        startIcon={startIcon}
        style={{
          ...cssprops,
          height: '40px',
          background: disabled ? 'var(--ion-color-medium)' : 'black',
          color: '#ffffff',
          textTransform: 'none',
          marginTop: '12px',
          marginBottom: '12px',
          paddingLeft: 'var(--margin-text-side)',
          paddingRight: 'var(--margin-text-side)',
          borderRadius: '999999px',
        }}
        {...(props as ButtonBaseTypeMap)}
      >
        <div
          style={{
            marginLeft: 'var(--margin-text-side)',
            marginRight: 'var(--margin-text-side)',
          }}
        >
          {text}
        </div>
      </Button>
    </Typography>
  );
};

export const StandardBackButton: React.FC = (props) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <IonButtons slot="start">
      <IonButton disabled={!location} onClick={() => history.goBack()}>
        <IonIcon slot={'icon-only'} icon={chevronBackOutline} size={'large'} color={'dark'} />
      </IonButton>
    </IonButtons>
  );
};
