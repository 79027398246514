import {
  createCommentForPatient,
  createCommentForRecording,
  deleteCommentForPatient,
  deleteCommentForRecording,
  updateCommentForPatient,
  updateCommentForRecording,
} from '@24sens/ecg01-comments';
import { TargetTypeEnum } from '@24sens/ecg01-rest-client';
import { formatDateForBackend } from '@24sens/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, FormControl, Modal, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { DateTimePicker } from '@mui/x-date-pickers';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as selectors from '../store/selectors';
import { useAppDispatch, useAppSelector } from '../store/store';
import { VisibilitySelect } from './VisibilitySelect';

interface EnterCommentModalProps {
  patientOrRecordingId?: number;
  commentId?: number;
  target_type: TargetTypeEnum;
  open: boolean;
  handleClose(): void;
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export const EnterCommentModal: React.FC<EnterCommentModalProps> = ({
  open,
  handleClose,
  commentId,
  target_type,
  patientOrRecordingId,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const comment = useAppSelector((state) => selectors.comment.commentById(state, commentId ?? ''));

  useEffect(() => {
    setTitleValue(comment?.header ?? '');
    setTextValue(comment?.body ?? '');
    setStartDate(comment?.start_time ? new Date(comment?.start_time) : null);
    setEndDate(comment?.end_time ? new Date(comment?.end_time) : null);
    setVisibility(comment?.visibility);
  }, [comment]);

  const [titleValue, setTitleValue] = React.useState('');
  const [textValue, setTextValue] = React.useState('');
  const [startDate, setStartDate] = React.useState<Date | undefined | null>(null);
  const [endDate, setEndDate] = React.useState<Date | undefined | null>(null);
  const [visibility, setVisibility] = React.useState<'private' | 'patient' | 'public' | undefined>(
    comment?.visibility ?? 'patient',
  );

  const titleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitleValue(event.target.value);
  };
  const textChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextValue(event.target.value);
  };

  const handleSave = () => {
    if (patientOrRecordingId) {
      const comment = {
        id: commentId,
        header: titleValue,
        body: textValue,
        start_time: startDate
          ? formatDateForBackend(startDate)
          : endDate
          ? undefined
          : formatDateForBackend(new Date()),
        end_time: endDate ? formatDateForBackend(endDate) : undefined,
        target_id: Number(patientOrRecordingId),
        visibility,
      };

      if (target_type === TargetTypeEnum.User && commentId) {
        dispatch(updateCommentForPatient({ commentId, patientId: comment.target_id, comment }));
      } else if (target_type === TargetTypeEnum.User && !comment.id && comment.target_id) {
        dispatch(createCommentForPatient({ patientId: comment.target_id, comment }));
      }
      if (target_type === TargetTypeEnum.Recording && commentId) {
        dispatch(updateCommentForRecording({ commentId, recordingId: comment.target_id, comment }));
      } else if (target_type === TargetTypeEnum.Recording && !comment.id && comment.target_id) {
        dispatch(createCommentForRecording({ recordingId: comment.target_id, comment }));
      }

      handleClose();
    }
  };

  const handleDelete = () => {
    if (comment && patientOrRecordingId) {
      if (target_type === TargetTypeEnum.User && comment.id) {
        dispatch(deleteCommentForPatient({ patientId: patientOrRecordingId, commentId: comment.id }));
        handleClose();
      } else if (target_type === TargetTypeEnum.Recording && comment.id) {
        dispatch(deleteCommentForRecording({ recordingId: patientOrRecordingId, commentId: comment.id }));
        handleClose();
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form>
        <Box sx={style}>
          <Stack spacing={2}>
            <Typography gutterBottom id="modal-modal-title" variant="h5" component="h2">
              {comment ? t('notes.notesDoctor.titleEdit') : t('notes.notesDoctor.title')}
            </Typography>
            <TextField
              value={titleValue}
              onChange={titleChange}
              size={'small'}
              label={t('notes.notesDoctor.titleText')}
            />
            <TextField
              value={textValue}
              onChange={textChange}
              size={'small'}
              label={t('notes.notesDoctor.textText')}
              multiline
              rows={4}
            />
            <Stack direction={'row'} spacing={2}>
              <FormControl sx={{ width: '100%' }}>
                <DateTimePicker
                  ampm={false}
                  format="dd/MM/yyyy HH:mm"
                  label={t('notes.notesDoctor.startText')}
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                />
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                <DateTimePicker
                  ampm={false}
                  format="dd/MM/yyyy HH:mm"
                  label={t('notes.notesDoctor.endText')}
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                />
              </FormControl>

              <VisibilitySelect
                value={visibility}
                defaultValue={visibility}
                onChange={(event: SelectChangeEvent<unknown>) =>
                  setVisibility(event.target.value as 'private' | 'public' | 'patient')
                }
              />
            </Stack>
            {comment ? (
              <Button
                variant={'text'}
                startIcon={<DeleteIcon />}
                style={{ justifyContent: 'flex-start' }}
                onClick={() => handleDelete()}
              >
                {t('notes.deleteButton')}
              </Button>
            ) : null}
            <Stack direction={'row'} spacing={2} justifyContent="flex-end">
              <Button onClick={handleClose}>{t('buttons.cancel')}</Button>
              <Button variant={'contained'} onClick={handleSave}>
                {t('buttons.save')}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
};
