import { useTranslation } from 'react-i18next';
import { PlotParams } from 'react-plotly.js';

import { useSignalChunk } from './useSignalChunk';

export const useSignalChunkPlotData = (recordingId: number, chunkIdx: number): PlotParams => {
  const { t } = useTranslation();
  const ecgSignal = useSignalChunk(recordingId, chunkIdx);

  return {
    data: [
      {
        name: t('timeline.recordingDetails.signalName'),
        type: 'scattergl',
        yaxis: 'y',
        xaxis: 'x',
        mode: 'lines',
        hoverinfo: 'none',
        legendgroup: 'group1',
        line: { color: '#7f8bdb', width: 1 },
        ...ecgSignal.mv,
      },
      {
        name: t('timeline.recordingDetails.heartRate'),
        type: 'bar',
        yaxis: 'y2',
        xaxis: 'x',
        textposition: 'outside',
        //mode: 'bar',
        legendgroup: 'group2',
        hoverinfo: 'none',
        marker: {
          color: 'rgba(255,178,102,1)',
          opacity: 0.3,
          width: 2,
        },
        ...ecgSignal.heartrate,
      },
      {
        name: t('timeline.recordingDetails.rPeaks'),
        type: 'scattergl',
        yaxis: 'y',
        xaxis: 'x',
        mode: 'markers',
        legendgroup: 'group3',
        hoverinfo: 'none',
        marker: {
          color: '#DB002A',
          size: 5,
          opacity: 0.4,
        },
        ...ecgSignal.rpeaks,
      },
    ],
    layout: {
      margin: {
        t: 0,
        b: 0,
      },
      autosize: true,
      xaxis: {
        title: t('timeline.recordingDetails.xAxis'),
        showgrid:true, 
        rangeslider: { bordercolor: 'grey', borderwidth: 1, thickness: 0.1 },
      },
      yaxis: {
        title: t('timeline.recordingDetails.yAxis'),
        showgrid: true,
        zeroline: true,
        showline: false,
        showticklabels: true,
        overlaying: "y2"
      },
      yaxis2: {
        title: t('timeline.recordingDetails.yAxis2'),
        showgrid: false,
        zeroline: false,
        showline: false,
        showticklabels: true,
        side: 'right',
      },
      showlegend: true,
      sliders: [],
      legend: {
        y: -0.34,
        //yanchor: 'middle',
        //xanchor: 'left',
        bgcolor: 'transparent',
        orientation: 'h',
      },
    },
    config: {
      displayModeBar: false,
    },
  };
};
