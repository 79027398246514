import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Onboarding } from './Onboarding';
import { Overview } from './Overview';
import { PatientTimeline } from './PatientTimeline';
import { RecordingDetails } from './RecordingDetails';

export const PhysicianRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/onboarding`}>
        <Onboarding />
      </Route>
      <Route exact path={`${path}/patients/:patientId/recordings/:recordingId`}>
        <RecordingDetails />
      </Route>
      <Route exact path={`${path}/patients/:patientId`}>
        <PatientTimeline />
      </Route>
      <Route exact path={`${path}`}>
        <Overview />
      </Route>
    </Switch>
  );
};
