import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import { Badge, styled } from '@mui/material';
import MuiIconButton from '@mui/material/IconButton';
import MuiToolbar, { ToolbarProps as MuiToolbarProps } from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { PasswordChangeDialog } from '../auth/PasswordChangeDialog';
import { AboutDialog } from '../menu/AboutDialog';
import { AccountMenu } from '../menu/AccountMenu';
import { PhysicianProfileDialog } from '../menu/PhysicianProfileDialog';
import { NotificationMenu } from '../notifications/NotificationMenu';
import { VerificationPendingDialog } from '../physician/VerificationPendingDialog';
import * as selectors from '../store/selectors';
import { useAppSelector } from '../store/store';
import { useLayout } from './useLayout';

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.common.white,
}));

interface ToolbarProps extends MuiToolbarProps {}

export const Toolbar: React.FC<ToolbarProps> = (props) => {
  const { title } = useLayout();
  const location = useLocation();
  const history = useHistory();

  const notifications = useSelector(selectors.notificationsFiltered);
  const isProfileValid = useAppSelector(selectors.auth.isProfileValid) ?? false;
  const isPatient = useAppSelector(selectors.auth.isPatient);

  const refNotification = useRef<HTMLButtonElement | null>(null);
  const refMenu = useRef<HTMLButtonElement | null>(null);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [notificationsMenuOpen, setNotificationsMenuOpen] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [openPWChange, setOpenPWChange] = useState(false);

  return (
    <>
      <VerificationPendingDialog onEditProfile={() => setShowProfileModal(true)} />
      <MuiToolbar {...props}>
        {location.pathname !== '/physician' &&
        !location.pathname.includes('auth') &&
        location.pathname !== '/patient/upload' &&
        !location.pathname.includes('onboarding') ? (
          <IconButton size="large" edge="start" aria-label="menu" sx={{ mr: 2 }} onClick={() => history.goBack()}>
            <KeyboardArrowLeft />
          </IconButton>
        ) : null}
        <Typography data-testid={'header-title'} variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {location.pathname === '/patient/upload' || location.pathname.includes('auth') ? (
          <div />
        ) : (
          <IconButton
            edge={'start'}
            color={'info'}
            ref={refNotification}
            onClick={() => {
              setNotificationsMenuOpen(!accountMenuOpen);
            }}
          >
            <Badge color={'warning'} badgeContent={notifications.length}>
              <NotificationsIcon titleAccess={'Notifications Bell'} />
            </Badge>
          </IconButton>
        )}
        <IconButton
          data-testid={'toolbar-settingsButton'}
          edge={'end'}
          color={'info'}
          ref={refMenu}
          onClick={() => setAccountMenuOpen(!accountMenuOpen)}
        >
          <SettingsIcon titleAccess={'Settings cogwheel'} />
        </IconButton>
        <AccountMenu
          open={Boolean(refMenu.current) && accountMenuOpen}
          anchorEl={refMenu.current ?? null}
          onClose={() => setAccountMenuOpen(false)}
          onAccountClick={() => {
            setAccountMenuOpen(false);
            setShowProfileModal(true);
          }}
          onAboutClick={() => {
            setAccountMenuOpen(false);
            setShowAboutModal(true);
          }}
        />
        <NotificationMenu
          open={Boolean(refNotification.current) && notificationsMenuOpen}
          anchorEl={refNotification.current ?? null}
          onClose={() => {
            setNotificationsMenuOpen(false);
          }}
        />
        {props.children}
        <PasswordChangeDialog open={openPWChange} handleClose={() => setOpenPWChange(false)} />
        <PhysicianProfileDialog
          open={showProfileModal || (isPatient && !isProfileValid)}
          onClose={() => setShowProfileModal(false)}
          openPWChange={() => setOpenPWChange(true)}
        />
        {showAboutModal ? (
          <AboutDialog
            handleClose={() => setShowAboutModal(false)}
            open={showAboutModal}
            onClose={() => setShowAboutModal(false)}
          />
        ) : null}
      </MuiToolbar>
    </>
  );
};
