import {AccountTypeEnum} from "@24sens/ecg01-rest-client";
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import * as selectors from '../store/selectors';
import { LoginScreen } from './LoginScreen';
import { RegistrationScreen } from './RegistrationScreen';

export const AuthRouter: React.FC = () => {
  const { path } = useRouteMatch();
  const inviteUuid = useSelector(selectors.invite.uuid);

  return (
    <Switch>
      <Route exact path={`${path}/login`} component={LoginScreen} />
      <Route
        path={`${path}/signup/physician`}
        render={() => <RegistrationScreen accountType={AccountTypeEnum.Physician} />}
      />
      <Route
        path={`${path}/signup/patient`}
        render={() => <RegistrationScreen accountType={AccountTypeEnum.Patient} />}
      />
      <Route
        path={`${path}/signup`}
        render={() => <Redirect to={`${path}/signup/${inviteUuid ? 'patient' : 'physician'}`} />}
      />
    </Switch>
  );
};
