/* tslint:disable */
/* eslint-disable */
/**
 * ECG Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0-unknown
 * Contact:
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForbiddenError } from '../models';
// @ts-ignore
import { LoginRequest } from '../models';
// @ts-ignore
import { PasswordChangeRequest } from '../models';
// @ts-ignore
import { PasswordResetConfirmRequest } from '../models';
// @ts-ignore
import { PasswordResetRequest } from '../models';
// @ts-ignore
import { PatchedUserRequest } from '../models';
// @ts-ignore
import { RegisterRequest } from '../models';
// @ts-ignore
import { ResendEmailVerificationRequest } from '../models';
// @ts-ignore
import { RestAuthDetail } from '../models';
// @ts-ignore
import { Token } from '../models';
// @ts-ignore
import { UnauthenticatedError } from '../models';
// @ts-ignore
import { User } from '../models';
// @ts-ignore
import { UserRequest } from '../models';
// @ts-ignore
import { ValidationError } from '../models';
// @ts-ignore
import { VerifyEmailRequest } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginCreate: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'loginRequest' is not null or undefined
      assertParamExists('loginCreate', 'loginRequest', loginRequest);
      const localVarPath = `/auth/login/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logoutCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/logout/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordChangeRequest} passwordChangeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    passwordChangeCreate: async (
      passwordChangeRequest: PasswordChangeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'passwordChangeRequest' is not null or undefined
      assertParamExists('passwordChangeCreate', 'passwordChangeRequest', passwordChangeRequest);
      const localVarPath = `/auth/password/change/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordResetConfirmRequest} passwordResetConfirmRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    passwordResetConfirmCreate: async (
      passwordResetConfirmRequest: PasswordResetConfirmRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'passwordResetConfirmRequest' is not null or undefined
      assertParamExists('passwordResetConfirmCreate', 'passwordResetConfirmRequest', passwordResetConfirmRequest);
      const localVarPath = `/auth/password/reset/confirm/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        passwordResetConfirmRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     * @param {PasswordResetRequest} passwordResetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    passwordResetCreate: async (
      passwordResetRequest: PasswordResetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'passwordResetRequest' is not null or undefined
      assertParamExists('passwordResetCreate', 'passwordResetRequest', passwordResetRequest);
      const localVarPath = `/auth/password/reset/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RegisterRequest} registerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationCreate: async (
      registerRequest: RegisterRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'registerRequest' is not null or undefined
      assertParamExists('registrationCreate', 'registerRequest', registerRequest);
      const localVarPath = `/auth/registration/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(registerRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ResendEmailVerificationRequest} resendEmailVerificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationResendEmailCreate: async (
      resendEmailVerificationRequest: ResendEmailVerificationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'resendEmailVerificationRequest' is not null or undefined
      assertParamExists(
        'registrationResendEmailCreate',
        'resendEmailVerificationRequest',
        resendEmailVerificationRequest,
      );
      const localVarPath = `/auth/registration/resend-email/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resendEmailVerificationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {VerifyEmailRequest} verifyEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationVerifyEmailCreate: async (
      verifyEmailRequest: VerifyEmailRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyEmailRequest' is not null or undefined
      assertParamExists('registrationVerifyEmailCreate', 'verifyEmailRequest', verifyEmailRequest);
      const localVarPath = `/auth/registration/verify-email/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {PatchedUserRequest} [patchedUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPartialUpdate: async (
      patchedUserRequest?: PatchedUserRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth/user/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(patchedUserRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userRetrieve: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/user/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {UserRequest} [userRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUpdate: async (userRequest?: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/user/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication tokenAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loginCreate(
      loginRequest: LoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.loginCreate(loginRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logoutCreate(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logoutCreate(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordChangeRequest} passwordChangeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async passwordChangeCreate(
      passwordChangeRequest: PasswordChangeRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.passwordChangeCreate(passwordChangeRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordResetConfirmRequest} passwordResetConfirmRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async passwordResetConfirmCreate(
      passwordResetConfirmRequest: PasswordResetConfirmRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.passwordResetConfirmCreate(
        passwordResetConfirmRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     * @param {PasswordResetRequest} passwordResetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async passwordResetCreate(
      passwordResetRequest: PasswordResetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.passwordResetCreate(passwordResetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {RegisterRequest} registerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registrationCreate(
      registerRequest: RegisterRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registrationCreate(registerRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ResendEmailVerificationRequest} resendEmailVerificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registrationResendEmailCreate(
      resendEmailVerificationRequest: ResendEmailVerificationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registrationResendEmailCreate(
        resendEmailVerificationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {VerifyEmailRequest} verifyEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registrationVerifyEmailCreate(
      verifyEmailRequest: VerifyEmailRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registrationVerifyEmailCreate(
        verifyEmailRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {PatchedUserRequest} [patchedUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userPartialUpdate(
      patchedUserRequest?: PatchedUserRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userPartialUpdate(patchedUserRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userRetrieve(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userRetrieve(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {UserRequest} [userRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userUpdate(
      userRequest?: UserRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdate(userRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginCreate(loginRequest: LoginRequest, options?: any): AxiosPromise<Token> {
      return localVarFp.loginCreate(loginRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logoutCreate(options?: any): AxiosPromise<RestAuthDetail> {
      return localVarFp.logoutCreate(options).then((request) => request(axios, basePath));
    },
    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordChangeRequest} passwordChangeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    passwordChangeCreate(passwordChangeRequest: PasswordChangeRequest, options?: any): AxiosPromise<RestAuthDetail> {
      return localVarFp
        .passwordChangeCreate(passwordChangeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordResetConfirmRequest} passwordResetConfirmRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    passwordResetConfirmCreate(
      passwordResetConfirmRequest: PasswordResetConfirmRequest,
      options?: any,
    ): AxiosPromise<RestAuthDetail> {
      return localVarFp
        .passwordResetConfirmCreate(passwordResetConfirmRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     * @param {PasswordResetRequest} passwordResetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    passwordResetCreate(passwordResetRequest: PasswordResetRequest, options?: any): AxiosPromise<RestAuthDetail> {
      return localVarFp.passwordResetCreate(passwordResetRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {RegisterRequest} registerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationCreate(registerRequest: RegisterRequest, options?: any): AxiosPromise<RestAuthDetail> {
      return localVarFp.registrationCreate(registerRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ResendEmailVerificationRequest} resendEmailVerificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationResendEmailCreate(
      resendEmailVerificationRequest: ResendEmailVerificationRequest,
      options?: any,
    ): AxiosPromise<RestAuthDetail> {
      return localVarFp
        .registrationResendEmailCreate(resendEmailVerificationRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {VerifyEmailRequest} verifyEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationVerifyEmailCreate(verifyEmailRequest: VerifyEmailRequest, options?: any): AxiosPromise<RestAuthDetail> {
      return localVarFp
        .registrationVerifyEmailCreate(verifyEmailRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {PatchedUserRequest} [patchedUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPartialUpdate(patchedUserRequest?: PatchedUserRequest, options?: any): AxiosPromise<User> {
      return localVarFp.userPartialUpdate(patchedUserRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userRetrieve(options?: any): AxiosPromise<User> {
      return localVarFp.userRetrieve(options).then((request) => request(axios, basePath));
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {UserRequest} [userRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUpdate(userRequest?: UserRequest, options?: any): AxiosPromise<User> {
      return localVarFp.userUpdate(userRequest, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * AuthApi - interface
 * @export
 * @interface AuthApi
 */
export interface AuthApiInterface {
  /**
   * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
   * @param {LoginRequest} loginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  loginCreate(loginRequest: LoginRequest, options?: AxiosRequestConfig): AxiosPromise<Token>;

  /**
   * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  logoutCreate(options?: AxiosRequestConfig): AxiosPromise<RestAuthDetail>;

  /**
   * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
   * @param {PasswordChangeRequest} passwordChangeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  passwordChangeCreate(
    passwordChangeRequest: PasswordChangeRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<RestAuthDetail>;

  /**
   * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
   * @param {PasswordResetConfirmRequest} passwordResetConfirmRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  passwordResetConfirmCreate(
    passwordResetConfirmRequest: PasswordResetConfirmRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<RestAuthDetail>;

  /**
   * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
   * @param {PasswordResetRequest} passwordResetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  passwordResetCreate(
    passwordResetRequest: PasswordResetRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<RestAuthDetail>;

  /**
   *
   * @param {RegisterRequest} registerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  registrationCreate(registerRequest: RegisterRequest, options?: AxiosRequestConfig): AxiosPromise<RestAuthDetail>;

  /**
   *
   * @param {ResendEmailVerificationRequest} resendEmailVerificationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  registrationResendEmailCreate(
    resendEmailVerificationRequest: ResendEmailVerificationRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<RestAuthDetail>;

  /**
   *
   * @param {VerifyEmailRequest} verifyEmailRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  registrationVerifyEmailCreate(
    verifyEmailRequest: VerifyEmailRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<RestAuthDetail>;

  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
   * @param {PatchedUserRequest} [patchedUserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  userPartialUpdate(patchedUserRequest?: PatchedUserRequest, options?: AxiosRequestConfig): AxiosPromise<User>;

  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  userRetrieve(options?: AxiosRequestConfig): AxiosPromise<User>;

  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
   * @param {UserRequest} [userRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  userUpdate(userRequest?: UserRequest, options?: AxiosRequestConfig): AxiosPromise<User>;
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI implements AuthApiInterface {
  /**
   * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
   * @param {LoginRequest} loginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public loginCreate(loginRequest: LoginRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .loginCreate(loginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public logoutCreate(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .logoutCreate(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
   * @param {PasswordChangeRequest} passwordChangeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public passwordChangeCreate(passwordChangeRequest: PasswordChangeRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .passwordChangeCreate(passwordChangeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
   * @param {PasswordResetConfirmRequest} passwordResetConfirmRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public passwordResetConfirmCreate(
    passwordResetConfirmRequest: PasswordResetConfirmRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .passwordResetConfirmCreate(passwordResetConfirmRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
   * @param {PasswordResetRequest} passwordResetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public passwordResetCreate(passwordResetRequest: PasswordResetRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .passwordResetCreate(passwordResetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RegisterRequest} registerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public registrationCreate(registerRequest: RegisterRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .registrationCreate(registerRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ResendEmailVerificationRequest} resendEmailVerificationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public registrationResendEmailCreate(
    resendEmailVerificationRequest: ResendEmailVerificationRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .registrationResendEmailCreate(resendEmailVerificationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {VerifyEmailRequest} verifyEmailRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public registrationVerifyEmailCreate(verifyEmailRequest: VerifyEmailRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .registrationVerifyEmailCreate(verifyEmailRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
   * @param {PatchedUserRequest} [patchedUserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public userPartialUpdate(patchedUserRequest?: PatchedUserRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .userPartialUpdate(patchedUserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public userRetrieve(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .userRetrieve(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
   * @param {UserRequest} [userRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public userUpdate(userRequest?: UserRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .userUpdate(userRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
